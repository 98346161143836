
import React from "react";
import { H2 } from "../../reusable-components";
import { useTranslation } from "react-i18next";
import { Wrapper } from "./styles";
import SyndicateDiv from "../syndicatediv";

const SyndicateList = ({ syndicates, shallowRounds }) => {
  
  const { t } = useTranslation();

  return <div>
    <Wrapper>
      <div className="green-div">
        <H2 className="title-margin">{t('syndicates')}</H2>
        {syndicates && syndicates.map((syndicate, i) => {
          const round = shallowRounds.filter(r => r.id === syndicate.roundId)[0];
          return (
            <SyndicateDiv key={`${i} syndicatediv`} syndicate={syndicate} round={round} />
          )
        }
        )}
      </div>
    </Wrapper>
  </div>;

}

export default SyndicateList;