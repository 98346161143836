import React from 'react';
import { ProPickWrapper } from './styles';
import { BackIcon, Button } from '../../../../../reusable-components';

import { useTranslation } from "react-i18next";
import { Close } from '@material-ui/icons';
import fetchApi from '../../../../../backend/fetchApi';
import MediaQuery from "react-responsive/src";
import {operatorAdviceScrollToTop} from "../../../../../App";
import {formatStringCurrency} from "../../../../../common/CurrencyFormatter";


function getRemoteSuggestion(inputData) {
  const url = "https://onextwo-propick-mwkingfyqa-ew.a.run.app/suggestion";


  return fetchApi(url, {
    method: 'POST',
    body: inputData,
  }).then(response => response.json());
}

export function getProPickText(numRows, operatorStakePerRow)  {  
    return formatStringCurrency(Math.round(numRows * operatorStakePerRow.amount * 10) / 10,operatorStakePerRow.currency);
 }

function getProTypes(numGames, operatorStakePerRow)
{
  let proTypes =[];
  if(numGames > 3)
  {
    proTypes.push({ price: getProPickText(36, operatorStakePerRow), dif: 'Easy', numFull: 2, numHalf: 2 });
    proTypes.push({ price: getProPickText(36, operatorStakePerRow), dif: 'Hard', numFull: 2, numHalf: 2 });
  }
  if(numGames > 5)
  {
    proTypes.push({ price: getProPickText(108, operatorStakePerRow), dif: 'Easy', numFull: 3, numHalf: 2 });
    proTypes.push({ price: getProPickText(108, operatorStakePerRow), dif: 'Hard', numFull: 3, numHalf: 2 });
  }
  if(numGames > 7)
  {
    proTypes.push({ price: getProPickText(192, operatorStakePerRow), dif: 'Easy', numFull: 1, numHalf: 6 });
    proTypes.push({ price: getProPickText(192, operatorStakePerRow), dif: 'Hard', numFull: 1, numHalf: 6 });

    proTypes.push({ price: getProPickText(648, operatorStakePerRow), dif: 'Easy', numFull: 4, numHalf: 3 });
    proTypes.push({ price: getProPickText(648, operatorStakePerRow), dif: 'Hard', numFull: 4, numHalf: 3 });
  }

  if(numGames > 9)
  {
    proTypes.push({ price: getProPickText(972, operatorStakePerRow), dif: 'Easy', numFull: 5, numHalf: 2 });
    proTypes.push({ price: getProPickText(972, operatorStakePerRow), dif: 'Hard', numFull: 5, numHalf: 2 });

    proTypes.push({ price: getProPickText(1944, operatorStakePerRow), dif: 'Easy', numFull: 5, numHalf: 3 });
    proTypes.push({ price: getProPickText(1944, operatorStakePerRow), dif: 'Hard', numFull: 5, numHalf: 3 });
  }

  return proTypes;
}

export function getSuggestion(protype, roundFixtures, setProPickRows) {
  let floor = 0.5;
  let ceiling = 1.0;

  if (protype.dif === 'Hard') {
    floor = 0;
    ceiling = 0.5;
  }

  let round = {
    Fixtures: [],
    Settings: {
      NumHalfCovers: protype.numHalf,
      NumFullCovers: protype.numFull,
      ProbabilitySpanFloor: floor,
      ProbabilitySpanCeiling: ceiling
    }
  }

  let index = 0;
  for (var fixt in roundFixtures) {
    let fixture = roundFixtures[fixt];
    let one = 0.33;
    let two = 0.33;
    let x = 0.33;
    if (fixture.signs != null && fixture.signs.length > 0) {
      one = fixture.signs.ONE.rowsFraction;
      two = fixture.signs.TWO.rowsFraction;
      x = fixture.signs.X.rowsFraction;
    }
    round.Fixtures.push({ ID: index.toString(), Probabilities: { ONE: one, TWO: two, X: x } })
    index++;
  }

  let json = JSON.stringify(round);
  getRemoteSuggestion(json).then((resp) => {

    let signs = [];
    for (var rowIndex in resp.SuggestedBets) 
    {
      let suggestedBet = resp.SuggestedBets[rowIndex];
      let index = parseInt(suggestedBet.FixtureID); 
      signs[index] = suggestedBet.Signs;
    }
    setProPickRows(signs);
  }
  );
}

const ProPick = ({ onShowProPick, legs, fixtures, setProPickRows, operatorStakePerRow }) => {
  const { t } = useTranslation();
  const roundFixtures = fixtures;  

  setTimeout(() => operatorAdviceScrollToTop(), 400);

  return (

      <ProPickWrapper>
        <div className="shadow-content start dyn-height">

          <MediaQuery maxWidth={1000}>
            <div className={"centered-title-wrapper"}>

              <div style={{display: 'flex', width: '33%', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                <div id={'go-back-button'} onClick={() => onShowProPick(false)}>
                  <div>{BackIcon}</div>
                  <div className={'bold'}>{t('go_back')}</div>
                </div>
              </div>

              <div style={{display: 'flex', width: '33%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <span className={"bold"}>{t('pro_pick')}</span>
              </div>

              <div style={{display: 'flex', width: '33%', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-center'}}>
                {/* this element only exists to make flexbox behave*/}
              </div>

            </div>
          </MediaQuery>

          <MediaQuery minWidth={1001}>
            <div className="close-flex">
              <span />
              <span className="bold">{t('pro_pick')}</span>
              <Close onClick={() => onShowProPick(false)} />
            </div>
          </MediaQuery>

          <div className="grid-wrapper">
            <div className="grid">
              <div className="bold" style={{color: "var(--text-black)"}}>{t('often')}</div>
              <div className="bold" style={{color: "var(--text-black)"}}>{t('a_lot')}</div>

              {getProTypes(roundFixtures.length, operatorStakePerRow).map((protyp) =>
                  <Button onClick={() => {
                    getSuggestion(protyp, roundFixtures, setProPickRows);
                    onShowProPick(false);
                  }
                } className={`system cursor`}>{protyp.price}</Button>
              )}
            </div>
          </div>
        </div>
      </ProPickWrapper>

  );
}

export default ProPick;

