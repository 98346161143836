import React from 'react';
import { TeamWrapper } from './styles';
import {Link, useHistory} from 'react-router-dom';
import { ArrowForwardIos } from "@material-ui/icons";
import {formatStringCurrency} from "../../../common/CurrencyFormatter";

const TeamDiv = ({
  name,
  members,
  id,
  syndicates,
}) => {
  const [syndicate] = syndicates || [];

  const { nShares, stakePerShare } = syndicate || {};
  const { amount, currency } = stakePerShare || {};
  const total = nShares * amount;
  const { length } = members || {};

  const numMembersLabel = (num) => {
    return num > 1 ? 'medlemmar' : 'medlem';
  }

  return (
    <TeamWrapper>
      <div className="top-div">
        <span className="bold-span">{name}</span>
        <span className="numb-members">{length} {numMembersLabel(length)}</span>
        <Link className="bold-span link-div" to={`/team/${id}`}>Till laget <ArrowForwardIos className="icon" /></Link>
      </div>
      {total ? (
        <div className="bottom-div">
          <div className="column">
            <span className="bold-span">
              {name}
            </span>
            <span className="total-span">{formatStringCurrency(total, currency)} att spela för</span>

          </div>
          <span className="bold-span">
            {formatStringCurrency(amount, currency)}
              </span>
        </div>
      ) : (
        <div className="centered-bottom-div">
          <span>Inga förberedda spel</span>
        </div>
      )}

    </TeamWrapper>
  );
}

export default TeamDiv;