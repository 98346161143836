import fetchApi, { getCurrentToken } from "../backend/fetchApi";
import { usePlayer } from "../player/playerContext";
import { CreateCouponResponse, RoundCoupon, ValidateCouponResponse } from "./types";

var apiUrl = process.env["REACT_APP_API_URL"]

const usePurchaseWithBody = () => {
  const [player] = usePlayer();
  
  return (body: string | FormData) => {
    return fetchApi(player._links.coupons.href, {
      method: 'POST',
      body: body,
    }).then(response => response.json())
      .then((couponResult: CreateCouponResponse) => {  
      });
  }

}

export const useValidateCoupon = () => {
  const validateCouponWithBody = useValidateCouponWithBody();

  if (!validateCouponWithBody) return;

  return (coupon: RoundCoupon) => validateCouponWithBody(JSON.stringify(coupon));
}

const useValidateCouponWithBody = () => {
  const [player] = usePlayer();

  if (!player) return;

  return (body : string) => fetchApi(player._links.validateCoupon.href, {
    method: 'POST',
    body: body,
  }).then(response => response.json()) as Promise<ValidateCouponResponse>;
}

export const usePurchaseCoupon = () => {
  const purchaseWithBody = usePurchaseWithBody();

  return (coupon: RoundCoupon) => purchaseWithBody(JSON.stringify(coupon))
}

export const usePurchaseFromFile = () => {
  const purchaseWithBody = usePurchaseWithBody();
  return (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    return purchaseWithBody(formData);
  }
}

export const useSimplePurchase = () => {
  const [player] = usePlayer();
  
  const headers = new Headers({
    Accept: 'application/json',
  });

  const token = getCurrentToken();
  if (token) {
    headers.set('Authorization', "Bearer " + token);
  }

  return async (coupon: any) => {
    try {
      headers.set('Content-Type', 'application/json');
      const res = await fetch(new URL(player._links.coupons.href, apiUrl + "/").href.replace(/\/$/, ""), {
        headers,
        method: 'POST',
        body: JSON.stringify(coupon),
      });

      return res;
    }
    catch (err) {
      return console.error(err);
    }
  }
}
