import React from 'react';
import { WinInfoWrapper } from './styles';
import { useTranslation } from "react-i18next";
import {formatStringCurrency} from "../../../common/CurrencyFormatter";

function ResultRow({
  index,
  maxNum,
  result,
  payoutPerRow,
  rows,
}) {  
  const { t } = useTranslation();
  const numCorrect = maxNum - index;
  if(!result)
  {
    return <div></div>
  }
  const money = payoutPerRow.money || {};
  if(!money)
  {
    return <div></div>
  }
  const actualPayout = money.amount;
  const numOwn = result.rows;
  
  const totalPayoutCurrency = money.currency  ? money.currency : 'SEK';

  // We cannot use the amount in result.payout as one row is not always exactly equal to one stake unit, this depends on
  // the operator currency
  const ownPayout = numOwn * actualPayout;
  return (
    <div className={`result-row ${numCorrect % 2 !== 0 ? 'bg-green' : ''}`}>
      <span>{numCorrect}</span>
      <span>{rows ? Math.round(rows*100)/100 :  'NA'} {t('st')}</span>
      <span>{formatStringCurrency(actualPayout, totalPayoutCurrency)}</span>
      <span>{numOwn} {t('st')}</span>
      <span>{formatStringCurrency(ownPayout, totalPayoutCurrency)}</span>
    </div>
  );
}

export default function WinInfo({ round, couponShare }) {
  const { fixtures = [] } = round || {};  
  const { t } = useTranslation();

  if (!couponShare) {
    return <></>;
  }

  const { coupon } = couponShare || {};

  let win = { amount: 0, currency: 'SEK'};
  if (couponShare.operatorPreliminaryResult) {
    win = couponShare.operatorPreliminaryResult;
  }
  if (couponShare.operatorResult) {
    win = couponShare.operatorResult;
  }
  const { preliminaryResult } = coupon || {};
  const { groups: resultGroups = [] } = preliminaryResult || {};

  const { operatorPrizePlan } = round || {};
  let groups = {}

  if (operatorPrizePlan) {
    groups = operatorPrizePlan.payoutGroups
  }

  return groups ? (
    <WinInfoWrapper>
      <div className="black-bar">
        <span>{t('correct_rows')}</span>
        <span>{t('winners')}</span>
        <span>{t('prize')}</span>
        <span>{t('your_rows')}</span>
        <span>{t('profit')}</span>
      </div>

      {groups && groups.map((group, i) => (
        <ResultRow
          key={`${i} result row`}
          maxNum={fixtures.length}
          result={resultGroups[i]}
          index={i}
          {...group}
        />
      ))}
      <div className="result-row">
        <span>{t('total')}:</span>
        <span>{formatStringCurrency(win.amount, win.currency)}</span>
      </div>
    </WinInfoWrapper>
  ) : <></>;
}