import { React, useState } from 'react';
import { useParams } from "react-router-dom";
import NavBar from '../../navbar';
import image from '../../images/footballfield.jpg';
import { UpdateSyndicateFormWrapper } from './styles';
import { BackgroundImage, Button, H2 } from '../../reusable-components';
import { useTranslation } from "react-i18next";
import useAgent from '../useAgent';
import useAgentSyndicate from '../useAgentSyndicate';
import useRound from '../../rounds/useRound';
import { CouponPendingField } from "../../routedround/components/couponform";
import { betTotal } from '../../routedround/components/couponform/components/betfield';
import SyndicateCoupon from '../../syndicates/syndicatecoupon';
import {useMediaQuery} from "react-responsive/src";
import {usePlayer} from "../../player/playerContext";

export default function UpdateSyndicateForm() {
  const { t } = useTranslation();
  const { agentId, syndicateId } = useParams();
  const [agent] = useAgent(agentId);
  const [syndicate] = useAgentSyndicate(agent, syndicateId);

  const round = useRound((syndicate ? syndicate.roundId : undefined));
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });
  const initialFixtureIndexSelected = isSmallScreen ? -1 : 0;

  const [fixtureValue, onChangeFixture] = useState(initialFixtureIndexSelected);
  const [updateSyndicateMessage, onUpdateSyndicateMessage] = useState(undefined);
  const [submitSyndicateMessage, onSubmitSyndicateMessage] = useState(undefined);
  const [player, config] = usePlayer();

  let form = <div />;

  if (!config.agentSyndicateBetting) {
    return <div />;
  }

  if (!syndicate) {
    return <div />;
  }

  const onSubmit = async (coupon) => {
    syndicate.pendingRoundCoupon = coupon;
    var totalStake = betTotal([], coupon.bets[0]) * coupon.stakePerRow.amount;
    const stake = Math.round(totalStake*100 / syndicate.targetNShares)/100;
    syndicate.stakePerShare = { currency: "SEK", amount: '' + stake + '' };
    syndicate.update(syndicate).then(response => {
      onUpdateSyndicateMessage(response);
      syndicate.submit().then(submitResp => {
        onSubmitSyndicateMessage(submitResp);
      })
    }
    );
  }

  switch (syndicate.status) {
    case "DRAFT":
      form = <CouponPendingField className="form-margin" callback={onSubmit} syndicate={syndicate} round={round} fixtureValue={fixtureValue} onChangeFixture={onChangeFixture} />
      break;
    case "SUBMITTED":
      form = <SyndicateCoupon syndicate={syndicate} round={round}></SyndicateCoupon>
      break;
    default:
  }

  return (
    <>
      <NavBar whichToUnderline="5" />
      <UpdateSyndicateFormWrapper>
        <BackgroundImage height={250} image={image}>
          <H2 color="#fff">
            {t('bet_with_syndicates')}
          </H2>
        </BackgroundImage>
        <div className="green-div">
          <H2 className="title-margin">{syndicate.name}</H2>
          {form}
          {(updateSyndicateMessage && <span>{updateSyndicateMessage}</span>)}
          {(submitSyndicateMessage && <span>{submitSyndicateMessage}</span>)}
        </div>
      </UpdateSyndicateFormWrapper>
    </>
  );

}