import {useQuery, useQueryCache} from "react-query";
import fetchApi from "../backend/fetchApi";
import {createAgentSyndicateActions} from "./useAgentSyndicateActions";
import { useTranslation } from "react-i18next";

const useAgentSyndicates = (agent) => {
  const syndicatesUrl = agent && agent._links.syndicates.href
  const syndicates = useQuery(['syndicates', syndicatesUrl], () => {
    return fetchApi(syndicatesUrl).then(response => response.json()).then(r => r._embedded.syndicates)
  }, {
    enabled: !!agent,
  })

  const queryCache = useQueryCache();
  const { t } = useTranslation();

  return [syndicates.data && syndicates.data.map(syndicate => ({
    ...syndicate,
    ...createAgentSyndicateActions(syndicate, queryCache, t),
  })), {
    ...syndicates,
  }]
}

export default useAgentSyndicates;