import {React} from 'react';
import {useParams} from "react-router-dom";
import NavBar from '../../navbar';
import image from '../../images/footballfield.jpg';
import { AgentDetailWrapper } from './styles';
import { BackgroundImage, H2 } from '../../reusable-components';
import { useTranslation } from "react-i18next";
import useAgent from '../useAgent';
import useAgentSyndicates from '../useAgentSyndicates';
import AgentSyndicateList from '../agentsyndicatelist';
import useShallowRounds from '../../rounds/useShallowRounds';
import {usePlayer} from "../../player/playerContext";

export default function AgentDetail() {
  const { t } = useTranslation();
  const {agentId} = useParams();
  const [agent] = useAgent(agentId);
  const [syndicates, syndicatesStatus] = useAgentSyndicates(agent);
  const [rounds] = useShallowRounds();

  const [ player, config ] = usePlayer();
  if (!config.agentSyndicateBetting) {
    return <div/>;
  }

  return (
    <>
      <NavBar whichToUnderline="5"/>
      <AgentDetailWrapper>
        <BackgroundImage height={250} image={image}>
          <H2 color="#fff">
            {t('bet_with_syndicates')}
          </H2>
        </BackgroundImage>
        <div className="green-div">                 
          {agent && <H2>{agent.name}</H2> }
          {rounds && syndicates && <AgentSyndicateList agent={agent} syndicates={syndicates} shallowrounds={rounds}/>}
        </div>
      </AgentDetailWrapper>
    </>
  );
}