import React from 'react';
import { Link } from 'react-router-dom';
import { PinkWinWrapper } from './styles';
import { H1, H2, LargeArrowForward } from '../../../reusable-components';
import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive/src";
import {usePlayer} from "../../../player/playerContext";
import {formatStringCurrency} from "../../../common/CurrencyFormatter";

export default function PinkWin({ winAmount, nCorrectRows, couponShareId}) {
  const { t } = useTranslation();
  const [player, config] = usePlayer();
  const advancedCouponsActive = config.advancedCoupons;
  const winAmountStr = formatStringCurrency(winAmount.amount, winAmount.currency);
  return (
    <PinkWinWrapper>
      <div className="title-flex">
        <H1 size={150} className="h1">{nCorrectRows}</H1>
        <span>{t('correct_rows_now')}</span>
      </div>
      <div className="right-flex">
        <div className={'your-win-wrapper'}>
          <span className="your-win">{t('your_winnings_right_now')}</span>
          <H2 size={32}>{winAmountStr}</H2>
        </div>
        <MediaQuery minWidth={1000}>
            {advancedCouponsActive && (
                <Link to={"/advancedCouponView/" + couponShareId}>
                    <div className="margin-left">
                        {LargeArrowForward}
                    </div>
                </Link>
            )}

        </MediaQuery>
      </div>
    </PinkWinWrapper>
  );
}