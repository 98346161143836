import {usePlayer} from "../player/playerContext";
import {useQuery, useQueryCache} from "react-query";
import fetchApi from "../backend/fetchApi";


const useCouponShares = () => {
  const [player] = usePlayer();

  const queryCache = useQueryCache();
  const couponSharesUrl = player && player._links.couponShares.href;
  let result = useQuery(['couponShares', couponSharesUrl], () => {
    return fetchApi(couponSharesUrl).then(response => response.json()).then(r => r._embedded.couponShares)
  }, {
    enabled: !!player,
    initialData: player && player._embedded.couponShares,
    initialStale: true,
  })

  const reloadCouponShares = () => queryCache.invalidateQueries('couponShares', couponSharesUrl);
  return [
      result.data,
      {...result, reloadCouponShares}
  ];
}

export default useCouponShares;