import React from 'react';
import keyBy from "lodash/keyBy";
import useAgents from "./useAgents";
import useAgentActions from "./useAgentActions";


const useAgent = id => {
  const [agents] = useAgents();
  const agentsById = React.useMemo(() => keyBy(agents, 'id'), [agents])

  const agent = agentsById[id];

  const actions = useAgentActions(agent)

  return [agent && {
    ...actions,
    ...agent
  }];
}

export default useAgent;