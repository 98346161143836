import { React, useState } from 'react';
import NavBar from '../../navbar';
import image from '../../images/footballfield.jpg';
import { SyndicateWrapper } from './styles';
import { BackgroundImage, H2, Button } from '../../reusable-components';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import useSyndicates from '../useSyndicates';
import SyndicateCoupon from '../syndicatecoupon';
import useDetailRound from '../../rounds/useDetailRound';
import SyndicateConfirm from '../syndicateconfirm';
import Moment from "react-moment";
import {usePlayer} from "../../player/playerContext";

export default function Syndicate() {
  const { syndicateId } = useParams();
  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);
  const [player, config] = usePlayer();

  const [syndicates] = useSyndicates();
  const syndicate = syndicates.filter(({ id }) => id === syndicateId)[0];
  const { round } = useDetailRound(syndicate.roundId);

  if (!syndicates || !round) return null;
  if (!syndicate) return null;

  const openToDate = new Date(round.openTo);
  let locale = t('locale');

  if (!(config.syndicateBetting || config.agentSyndicateBetting)) {
    return <div/>;
  }

  return (
    <>
      <NavBar />
      <SyndicateWrapper>
        <BackgroundImage height={250} image={image}>
          <H2 color="#fff">
            {t('bet_with_syndicates')}
          </H2>
        </BackgroundImage>
        <div className="green-div-column">
          <H2 className="title-margin">{syndicate.name}</H2>
          <span className="title-under">{round.name} <Moment format={"yyyy-MM-DD"} locale={locale}>{openToDate}</Moment></span>
        </div>
        <div className="green-div-row">
          <span>{t('share_price')}:</span>
          <span className="bold">{syndicate.stakePerShare.amount} {syndicate.stakePerShare.currency} </span>
          <span>{t('shares')}:</span>
          <span className="bold">{syndicate.targetNShares} </span>
          <span>{t('sold_shares')}:</span>
          <span className="bold">{syndicate.nShares} </span>
          <span>{t('total_cost')}:</span>
          <span className="bold">{syndicate.targetNShares * syndicate.stakePerShare.amount} {syndicate.stakePerShare.currency} </span>
        </div>
        <div className="white-div">
          {showConfirm && (
            <SyndicateConfirm              
              toggle={setShowConfirm}
              syndicate={syndicate}              
            />
          )}
          {syndicate.nShares < syndicate.targetNShares && (<Button onClick={() => setShowConfirm(true)}>{t('pay')}</Button>)}
          <SyndicateCoupon syndicate={syndicate} round={round}></SyndicateCoupon>
        </div>
      </SyndicateWrapper>
    </>
  );
}