export const TRANSLATIONS_SV = {
    max: "Max",
    mix: "Mix",
    flex: "Flex",

    // These three are temporary as some text in the site depends on the path
    mixtipset: "Mixtipset",
    maxtipset: "Maxtipset",
    flextipset: "Flextipset",
    //

    my_bets: "Mina Spel",
    my_coupons: "Mina kuponger",
    rounds: "Omgångar",
    bet_with_friends: "Spela med Poolarna",
    bet_with_syndicates: "Spela med ombud",
    pick_your_bet: "VÄLJ HUR DU VILL SPELA",
    friends: "Poolarna",
    syndicates: "Ombudsspel",
    turnover: "Omsättning",
    starting_soon: "Startar snart",
    single_rows: "Enkelrader",
    pro_pick: "Poolaren",
    often: "Vinn ofta",
    a_lot: "Vinn mycket",
    system: "System",
    row_distribution: "Radfördelning",
    overview: "Summering",
    full_cover: "Helgarderingar",
    half_cover: "Halvgarderingar",
    row_price: "Radpris",
    pay: "Betala",
    more: "Läs mer",
    unsettled_coupons: "Pågående kuponger",
    settled_coupons: "Avgjorda kuponger",
    stake: "Insats",
    coupon_share_status_cancelled: "Makulerad",
    your_winnings_right_now: "Din vinst just nu",
    correct_rows_now: "Rätt just nu",
    starts: "Startar",
    correct_rows: "Antal rätt",
    prize: "Utdelning",
    your_rows: "Dina rader",
    profit: "Vinst",
    st: "st",
    total: "Resultat",
    rows: "rader",
    row: "rad",
    price_per_row: "Insats per rad",
    total_cost: "Total insats",
    paid: "Betalt",
    settled: "Avgjordes",
    channel_internet: "Spelat via: Internet",
    print: "Skriv ut",
    share: "Dela",
    your_teams: "DINA LAG",
    create_a_new_team: "Starta nytt lag",

    under_construction: "UNDER UPPBYGGNAD",
    news_or_image_placeholder: "YTA FÖR NYHET / BILD",
    reduce: "Reducera",
    no_reduced_systems_available: "No reduced systems available",
    round_history: "Resultat",
    maximum_profit: "Högsta vinst",
    no_profit: "Ingen vinst",
    no_coupons: "Inga Kuponger",
    started: "Startade",
    round: "Spelomgång",
    winners: "Vinnare",
    your_bet_is_paid: "Ditt spel är betalt!",
    do_you_want_to_place_bet: "Vill du betala ett spel för",
    new_round_will_open_soon: "Nytt {{roundType}} öppnar snart",
    highest_win: "Högsta vinst",
    insufficient_funds: "För lågt saldo",
    cancel: "Avbryt",
    unable_to_complete_purchase: 'Köpet kunde inte genomföras',
    error_code: 'Felkod',

    day_sun: "Söndag",
    day_mon: "Måndag",
    day_tue: "Tisdag",
    day_wed: "Onsdag",
    day_thu: "Torsdag",
    day_fri: "Fredag",
    day_sat: "Lördag",

    event_goal: 'Mål',
    event_penalty: 'Straff',
    event_missed_penalty: 'Missad Straff',
    event_own_goal: 'Självmål',
    event_var: 'VAR',
    event_yellowcard: 'Gult Kort',
    event_yellowred: 'Andra Gula Kortet',
    event_substitution: 'Byte',
    event_redcard: 'Rött Kort',
    event_pen_shootout_goal: 'Mål vid Straff',
    event_pen_shootout_miss: 'Missad Straff',
    event_full_time: 'Fulltid',
    event_half_time: 'Halvtid',
    event_kickoff: 'Avspark',
    event_finished_after_extra: 'Tilläggstid Slut',

    out: 'Ut',

    news: "Nyheter",
    table: "Tabell",
    game_info: "Matchinfo",
    statistics: "Statistik",
    lineup: "Laguppställning",
    football: "Fotboll",

    in_running: "Live",
    full_time: "Fulltid",
    round_status_finished : "Avslutad",
    half_time: 'Halvtid',
    kickoff: 'Avspark',

    position: "Pos",
    team_name: "Lag",
    table_abbreviation_num_games: "M",
    table_abbreviation_wins: "V",
    table_abbreviation_draws: "O",
    table_abbreviation_losses: "F",
    table_abbreviation_points: "P",

    current_rounds: "Aktuella Omgångar",
    settled_rounds: "Avslutade Omgångar",
    no_rounds_available: "Inga tillgängliga omgångar",

    row_statistics: 'Radstatistik',
    poolx_game: 'Poolx spel',
    showing: 'Visar',    
    settings: 'Inställningar',
    match: 'Match',
    result: 'Resultat',
    bet_type: "Systemtyp",    
    mathematical_system: "Matematiskt system",
    reduced_system: "Reducerat system",
    no_system: "Inget system",
    select: "Välj",
    is_draw: "Lottas med",
    is_drawn: "Lottad",
    draw_disclaimer: 'Om lottning tillämpas:',
    num_correct_win: 'Antal rätt/vinst',
    more_info: 'Mer info',
    clear_bets: 'Rensa',

    home: 'Hem',
    coupons: 'Kuponger',
    results: 'Resultat',
    go_back: 'Tillbaka',

    to_syndicate: "Till ombudsspelet",
    shares: "Andelar",
    share_price: "Andelspris",    
    sold_shares: "Sålda andelar",
    agent_admin: "Ombud admin",
    agents: "Ombud",
    to_agent: "Till ombudet",
    create_new_syndicate: "Skapa nytt ombudsspel",
    name: "Namn",
    target_number_of_shares: "Antal andelar",
    max_number_of_shares: "Max antal andelar",
    submit_syndicate: "Lämna in ombudsspelet",
    update_syndicate: "Uppdatera ombudsspelet",
    show_sv_percent: 'Visa SV. folket %',
    show_coupon_percent: 'Visa kupong %',
    number_of_rows: 'Antal rader',
    share_of_syndicate: 'Andel av ombudsspel',
    syndicate_created: 'Ombudsspel skapat',
    syndicate_submitted: 'Ombudsspel inlämnat',
    syndicate_updated: 'Ombudsspel uppdaterat',
    finished: 'Avslutade',
    started_games: 'Påbörjade',
    potential: 'Potential',
    sort_by_finished: 'Sortera efter avslutade',
    game_summary: 'Matchsummering',
    date: 'Datum',
    league: 'Liga',
    venue: 'Arena',
    latest_tweets: 'Senaste tweets från',
    weather: 'Väder',
    temperature: 'Temperatur',
    wind: 'Vind',
    moisture: 'Fuktighet',
    general_info: 'Allmän info',
    locale: 'sv-SV',
    show: 'Visa',
    show_round: 'Visa omgång',
    play_on: 'Spela på',
    today: 'Idag',
    latest_games: 'Senaste matcherna',
    head_to_head: 'Head to head',
    round_information_short: 'Info',
    round_information: 'Information',
    prize_groups: 'Vinstgrupper',
    bet: 'Spela',
    pro_pick_bets: 'Färdiga spel',
    help: 'Hjälp',
    round_status_open : "Öppen",
    round_status_voided : "Inställd",
    round_status_paused : "Pausad",
    round_active_after_deadline: 'Stängd',
    round_paused_explanation: 'Omgången är pausad och nya spel kan inte läggas',
    jackpot : "Uppskattad Jackpot",
    after_extra_time: "Efter Tilläggstid"
}