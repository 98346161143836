import React from 'react';
import { Wrapper, PreviousFixtureRow } from './styles';
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { H3 } from '../../../../../reusable-components';


const MatchStats = ({ fixtureDetails }) => {
  const { teamStats } = fixtureDetails;
  const { t } = useTranslation();
  if (!teamStats) {
    return <div></div>
  }
  const headToHeadStats = teamStats.head_to_head_stats;
  const teamStatsByTeamId = teamStats.team_stats_by_team_id;

  if (!teamStatsByTeamId) {
    return <div></div>
  }

  let headToHeadFixtures = undefined;
  if (headToHeadStats) {
    headToHeadFixtures = headToHeadStats.latest_head_to_head_fixtures;
  }


  // Extract the latest fixtures for the home team
  const homeTeamStats = teamStatsByTeamId[`${fixtureDetails.fixture.homeTeam.id}`];
  const homeTeamLatestFixtures = homeTeamStats.latest_fixtures;

  // Same for the away team
  const awayTeamStats = teamStatsByTeamId[`${fixtureDetails.fixture.awayTeam.id}`];
  const awayTeamLatestFixtures = awayTeamStats.latest_fixtures;

  return (
    <Wrapper>
      <div className="subheader">
        <H3>{t('latest_games')}</H3>
      </div>
      <div className={'stat-cols-wrapper'}>
        <div className={'latest-fixtures-col'}>
          {renderPreviousFixtureStats(homeTeamLatestFixtures,t)}
        </div>

        <div className={'latest-fixtures-col'}>
          {renderPreviousFixtureStats(awayTeamLatestFixtures,t)}
        </div>
      </div>
      <div className="subheader">
        <H3>{t('head_to_head')}</H3>
      </div>
      <div className={'head-to-head-stats-col'}>
        {renderPreviousFixtureStats(headToHeadFixtures,t)}
      </div>
    </Wrapper>
  );
}

const fixtureInformationPredicate = (fixture) => (fixture.home_team.team_name &&  
  fixture.away_team.team_name &&  
  fixture.starting_at);

const renderPreviousFixtureStats = (previousFixtures, t) => {

  if (!previousFixtures) {
    return <div />;
  }

  let filteredFixtures = previousFixtures.filter(fixture => fixtureInformationPredicate(fixture));
  if (filteredFixtures.length > 5) {
    filteredFixtures = filteredFixtures.slice(0, 5);
  }

  return filteredFixtures.map((fixture, index) => {

    if(!fixture.away_team.score){
      fixture.away_team.score=0;
    }

    if(!fixture.home_team.score){
      fixture.home_team.score=0;
    }

    const leagueName = fixture?.game_round?.league?.name;

    const even = (index % 2 === 0);
    let boldIndex = 0;
    if (fixture.home_team.score > fixture.away_team.score) {
      boldIndex = 1;
    }
    if (fixture.home_team.score < fixture.away_team.score) {
      boldIndex = 2;
    }

    let locale=t('locale');

    return (
      <PreviousFixtureRow even={even}>
        <div className={'season-title'}>
          <Moment className={'no-linebreak date'} format={'YYYY-MM-DD'} locale={locale}>{fixture.starting_at.seconds * 1000}</Moment><span className={'no-linebreak'}>{leagueName??leagueName}</span>
        </div>
        <div className="team-and-score-area">
          <div className={'team-and-score-row'}>
            <div className={boldIndex !== 1 ? "team-name-col" : "team-name-col team-name-bold"}>{fixture.home_team.team_name}</div>
            <div className={boldIndex !== 1 ? "score-col" : "score-col team-name-bold"}>{fixture.home_team.score}</div>
          </div>
          <div className={'team-and-score-row'}>
            <div className={boldIndex !== 2 ? "team-name-col" : "team-name-col team-name-bold"}>{fixture.away_team.team_name}</div>
            <div className={boldIndex !== 2 ? "score-col" : "score-col team-name-bold"}>{fixture.away_team.score}</div>
          </div>
        </div>
      </PreviousFixtureRow>
    );
  });
}

export default MatchStats;
