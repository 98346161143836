import React, {useState} from 'react';
import FixtureCard from '../fixturecard';
import BetField from '../betfield';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { Wrapper } from './styles';
import Progress from '../../../../../common/progress';
import FixtureDetailsMobile from "../../../../../fixturedetailsmobile";
import MediaQuery from "react-responsive/src";

const FormBody = ({
  round,
  name,
  fixtureValue,
  onChangeFixture}) => {
  const [showFixtureModal, setShowFixtureModal] = useState(false);
  const [selectedFixtureId, setSelectedFixtureId] = useState(null);
  const prefix = name ? `${name}.` : "";
  const { fixtures = [] } = round || {};

  const handleFixtureClick = (index, fixtureId) => {
    onChangeFixture(index);
    setSelectedFixtureId(fixtureId);
    setShowFixtureModal(true);
  }

  const closeFixtureModal = () => {
    setShowFixtureModal(false);
  }

  const noFixtureSelected = (fixtureValue === -1);

  return (
    <div>
      <MediaQuery maxWidth={1000}>
        {showFixtureModal && (<FixtureDetailsMobile roundFixture={fixtures[fixtureValue]} fixtureId={selectedFixtureId} closeF={closeFixtureModal}/>)}
      </MediaQuery>
      <Wrapper>
        {fixtures.length > 0 ? (
            <div className="form">
              <div className="top-border"></div>
              <div className="flex-columns">
                <div className="flex-columns-child">
                  {fixtures.map((fixture, index) => {
                        return <FixtureCard
                            key={fixture.id}
                            index={index}
                            selected={noFixtureSelected ? false : fixtureValue === index}
                            onClick={() => handleFixtureClick(index, fixture.id)}
                            fixture={fixture} />;
                      }
                  )}
                </div>
                <FieldArray name={prefix + 'bets'}>
                  {({ fields }) => (
                      <div className="right-form-div">
                        {fields.map(name => <Field
                                round={round}
                                reducedSystems={round.reducedSystems}
                                fixtures={round.fixtures}
                                key={name}
                                name={name}
                                fixtureValue={fixtureValue}
                                component={BetField}
                                onChangeFixture={onChangeFixture}
                            />
                        )}
                      </div>
                  )}
                </FieldArray>
              </div>
            </div>
        ) : (
            <div className="min-height form">
              <Progress />
            </div>
        )}

      </Wrapper>
    </div>
  );
}

export default FormBody;
