import React from 'react';
import { Wrapper } from './styles';
import NavBar from '../navbar';
import { H2, BackgroundImage, Button, UnderConstructionDiv } from '../reusable-components';
import image from '../images/footballfield.jpg';
import TeamDiv from './components/teamdiv';
import useTeams from './useTeams';
import {useHistory} from "react-router-dom";
import {usePlayer} from "../player/playerContext";
import { useTranslation } from 'react-i18next';

const PlayPoolarna = () => {
  const [myTeams] = useTeams();
  let history = useHistory();
  let [player, config] = usePlayer();
  const { t } = useTranslation();
  if (!config.syndicateBetting) {
    return <div/>;
  }

  const redirect = (to) => {
    history.push(to);
  }

  const handleCreateTeamClicked = () => {
    if (!player._embedded.nickname || player._embedded.nickname === '') {
      redirect('selectNickname')
      return
    }
    redirect('newSyndicateTeam')
  }

  return (
    <>
      <NavBar whichToUnderline="1" />
      <Wrapper>
        <BackgroundImage height={250} image={image}>
          <H2 color="#fff">
            {t('news_or_image_placeholder')}
          </H2>
        </BackgroundImage>
        <div className="green-div">
          <H2 className="title-margin">
            {t('your_teams')}
          </H2>
          <UnderConstructionDiv>
            <H2>
              {t('under_construction')}
            </H2>
          </UnderConstructionDiv>
          {myTeams && myTeams.map((team, i) => <TeamDiv key={`${i} teamdiv`} {...team} />)}
          <Button onClick={() => handleCreateTeamClicked()} className="button-margin">{t('create_a_new_team')}</Button>
        </div>
      </Wrapper>
    </>
  );
}

export default PlayPoolarna;
