import styled from 'styled-components';

export const RoundInformationWrapper = styled.div`
display: flex;    
flex-direction: column;
align-items: center;
bottom-margin:40px;

.flex-list{
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
    margin-bottom: 100px;
}

.go-back{
    display: flex;
    flex-direction: row;    
    margin: 10px 0px 10px 0px;
    align-items: flex-start;
    width:90%;
}

.go-back-button{
    display: flex;
    flex-direction: row;    
    margin: 10px 0px 10px 0px;
}

@media screen and (min-width: 601px) {
    .flex-list {
      width: 400px;
    }
    
    .subheader {
      width: 400px;
    }
}

@media screen and (max-width: 600px) {
    .flex-list {
      width: 90%;
    }
  
    .subheader {
      width:90%;
    }  
}

.subheader{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;    
}

.header{
    margin: 10px 0 0 0;
}

.flex-row-odd{
    display: flex;    
    flex-direction: row;
    background: #FFFFFF;
    justify-content: space-between;    
    align-items: center;    
    height: 30px;    
    padding: 0 5px; 0 5px;
}

.flex-row-even{
    display: flex;    
    flex-direction: row;    
    justify-content: space-between;    
    align-items: center;    
    height: 30px;
    padding: 0 5px; 0 5px;
}

.flex-row-header span {
    font-weight: bold;
}

    
    
`;