import React from 'react';
import { Wrapper } from './styles';
import { useTranslation } from "react-i18next";
import Moment from "react-moment";

const FixtureTimelineEvent = ({event, isHomeTeam, fixture}) => {
    let extraClassNames = '';

    if (isHomeTeam) {
        extraClassNames += ' home-team-event'
    }
    if (event.type==='HALF_TIME' ||
        event.type === 'FULL_TIME' ||
        event.type === 'KICKOFF' ||
        event.type === 'FINISHED_AFTER_EXTRA') {
        extraClassNames += ' centered-event'
    }

    return (
        <div className={'event-row' + extraClassNames}>
            {event.minute ? <MinuteIndicatorSpacing minute={event.minute} extraMinute={event.extra_minute} /> : <EventRowSpacing event={event} fixture={fixture}/>}
            <div className={'event-row-wrapper' + extraClassNames}>
                <div className={'event-bubble' + extraClassNames}>
                    <EventBubbleContent event={event} fixture={fixture}/>
                </div>
            </div>
            <EventRowSpacing />
        </div>
    );
}

const EventBubbleContent = ({event, fixture}) => {
    const { t } = useTranslation();
    const eventToken = getEventToken(event.type);
    const startingAt = new Date (fixture.startingAt);
    let locale = t('locale');
    switch (event.type) {
        case 'HALF_TIME':
            return (
                <div style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', color: 'var(--off-white)'}}>
                    <p style={{fontSize: '16px', margin: '4px', fontWeight: 'bold', color: 'var(--text-white)', textAlign: 'center'}}>
                        {t('half_time')}
                    </p>
                </div>
            );
        case 'FULL_TIME':
            return (
                <div style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', color: 'var(--off-white)'}}>
                    <p style={{fontSize: '16px', margin: '4px', fontWeight: 'bold', color: 'var(--text-white)', textAlign: 'center'}}>
                        {t(eventToken)}
                    </p>
                </div>
            );
        case 'KICKOFF':
            return (
                <div style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', color: 'var(--off-white)'}}>
                    <p style={{fontSize: '16px', margin: '4px', fontWeight: 'bold', color: 'var(--text-white)', textAlign: 'center'}}>
                        {t(eventToken)} <Moment format={'HH:mm'} locale={locale}>{startingAt}</Moment>
                    </p>
                </div>
            );
        case 'FINISHED_AFTER_EXTRA':
            return (
                <div style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', color: 'var(--off-white)'}}>
                    <p style={{fontSize: '16px', margin: '4px', fontWeight: 'bold', color: 'var(--text-white)', textAlign: 'center'}}>
                        {t(eventToken)}
                    </p>
                </div>
            );
        case 'SUBSTITUTION':
            return (
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div>
                        <span className={'icon-arrows'} style={{color: 'var(--text-black)', fontSize: '30px', padding: '0 16px'}}/>
                    </div>
                    <div>
                        <div>
                            <div style={{fontWeight: 'bold', color: 'var(--turquoise)', fontSize: '16px'}}>{t(eventToken)}</div>
                            <div style={{color: 'var(--text-black)', fontWeight: 'bold'}}>{event.player_name}</div>
                            <div style={{color: 'var(--grey-out-50-percent)'}}>{t('out')}: {event.related_player_name}</div>
                        </div>
                    </div>
                </div>
            );
        case 'YELLOW_RED':
        case 'RED_CARD':
            return (
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div>
                        <span className={'icon-card-1'} style={{color: 'var(--red)', fontSize: '24px', padding: '0 16px'}}/>
                    </div>
                    <div>
                        <div style={{fontWeight: 'bold', color: 'var(--turquoise)', fontSize: '16px'}}>{t(eventToken)}</div>
                        <div style={{color: 'var(--text-black)', fontWeight: 'bold'}}>{event.player_name}</div>
                    </div>
                </div>
            );
        case 'YELLOW_CARD':
            return (
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div>
                        <span className={'icon-card-1'} style={{color: 'var(--yellow)', fontSize: '24px', padding: '0 16px'}}/>
                    </div>
                    <div>
                        <div style={{fontWeight: 'bold', color: 'var(--turquoise)', fontSize: '16px'}}>{t(eventToken)}</div>
                        <div style={{color: 'var(--text-black)', fontWeight: 'bold'}}>{event.player_name}</div>
                    </div>
                </div>
            );
        case 'GOAL':
            return (
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div>
                        <span className={'icon-football'} style={{color: 'var(--text-black)', fontSize: '24px', padding: '0 16px'}}/>
                    </div>
                    <div>
                        <div style={{fontWeight: 'bold', color: 'var(--turquoise)', fontSize: '16px'}}>{t(eventToken)} {event.result}</div>
                        <div style={{color: 'var(--text-black)', fontWeight: 'bold'}}>{event.player_name}</div>
                    </div>
                </div>
            );
        case 'PENALTY':
            return (
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div>
                        <span className={'icon-football'} style={{color: 'var(--text-black)', fontSize: '24px', padding: '0 16px'}}/>
                    </div>
                    <div>
                        <div style={{fontWeight: 'bold', color: 'var(--turquoise)', fontSize: '16px'}}>{t(eventToken)} {event.result}</div>
                        <div style={{color: 'var(--text-black)', fontWeight: 'bold'}}>{event.player_name}</div>
                    </div>
                </div>
            );
        case 'MISSED_PENALTY':
            return (
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div>
                        <span className={'icon-football'} style={{color: 'var(--red)', fontSize: '24px', padding: '0 16px'}}/>
                    </div>
                    <div>
                        <div style={{fontWeight: 'bold', color: 'var(--turquoise)', fontSize: '16px'}}>{t(eventToken)}</div>
                        <div style={{color: 'var(--text-black)', fontWeight: 'bold'}}>{event.player_name}</div>
                    </div>
                </div>
            );
        case 'OWN_GOAL':
            return (
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div>
                        <span className={'icon-football'} style={{color: 'var(--red)', fontSize: '24px', padding: '0 16px'}}/>
                    </div>
                    <div>
                        <div style={{fontWeight: 'bold', color: 'var(--turquoise)', fontSize: '16px'}}>{t(eventToken)} {event.result}</div>
                        <div style={{color: 'var(--text-black)', fontWeight: 'bold'}}>{event.player_name}</div>
                    </div>
                </div>
            );
        default:
            return (
                <div>
                    <div style={{fontWeight: 'bold', color: 'var(--turquoise)', fontSize: '16px'}}>{t(eventToken)}</div>
                    <div style={{color: 'var(--text-black)', fontWeight: 'bold'}}>{event.player_name}</div>
                </div>
            );
    }
}

const EventRowSpacing = () => {
    return (
        <div style={{display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center'}}>
            <div style={{height: '16px', width: '1px', backgroundColor: 'var(--green-tinted-white)'}}/>
        </div>
    );
};

const MinuteIndicatorSpacing = ({minute, extraMinute}) => {
    const displayExtraMinute = extraMinute !== "";
    return (
        <div style={{display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center'}}>
            <div style={{height: '3px', width: '1px', backgroundColor: 'var(--green-tinted-white)'}}/>
            <div style={{height: '24px', width: '56px', borderRadius: '24px', border: '1px solid var(--grey-out-50-percent)', backgroundColor: 'var(--off-white)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{margin: '0', padding: '0', fontSize: '11px', fontWeight: 'bold'}}>
                    {minute}' {displayExtraMinute ? <span>{` + ${extraMinute}'`}</span> : ''}
                </p>
            </div>
            <div style={{height: '8px', width: '1px', backgroundColor: 'var(--green-tinted-white)'}}/>
        </div>
    );
};

const getEventToken = (eventType) => {
    switch (eventType) {
        case 'GOAL':
            return 'event_goal';
        case 'PENALTY':
            return 'event_penalty';
        case 'MISSED_PENALTY':
            return 'event_missed_penalty';
        case 'OWN_GOAL':
            return 'event_own_goal';
        case 'VAR':
            return 'event_var';
        case 'YELLOW_CARD':
            return 'event_yellowcard';
        case 'YELLOW_RED':
            return 'event_yellowred';
        case 'SUBSTITUTION':
            return 'event_substitution';
        case 'RED_CARD':
            return 'event_redcard';
        case 'PEN_SHOOTOUT_GOAL':
            return 'event_pen_shootout_goal';
        case 'PEN_SHOOTOUT_MISS':
            return 'event_pen_shootout_miss';
        case 'FULL_TIME':
            return 'event_full_time';
        case 'HALF_TIME':
            return 'event_half_time';
        case 'KICKOFF':
            return 'event_kickoff';
        case 'FINISHED_AFTER_EXTRA':
            return 'event_finished_after_extra';
    }
}

const MatchSummary = ({fixtureDetails}) => {
  const {fixture} = fixtureDetails;
  const homeTeamId = fixture.homeTeam.id;

  if (!fixture.fixtureTimelineEvents) {
      return <Wrapper />
  }

  return (
    <Wrapper>
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            {fixture.fixtureTimelineEvents.filter(event => event.type !== 'UNKNOWN').map((event, i) =>
                <FixtureTimelineEvent
                    key={`timeline ${i}`}
                    event={event}
                    isHomeTeam={event.team && homeTeamId===event.team.id}
                    fixture={fixture} />
            )}
        </div>
    </Wrapper>
  );
}

export default MatchSummary;