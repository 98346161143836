import React from 'react';
import { WinInfoWrapper } from '../../../couponview/components/wininfo/styles';
import { useTranslation } from "react-i18next";
import {formatStringCurrency} from "../../../common/CurrencyFormatter";

function ResultRow({
  index,
  payoutPerRow,
  maxNum,
  rows,
}) {
  const { t } = useTranslation();
  const { money } = payoutPerRow || {};
  const actualPayout = money.amount;
  const numCorrect = maxNum - index;
  return (
    <div className={`result-row ${numCorrect % 2 !== 0 ? 'bg-green' : ''}`}>
      <span>{numCorrect}</span>
      <span>{rows ? Math.round(rows*100)/100 : 'NA'} {t('st')}</span>
      <span>{formatStringCurrency(actualPayout, money.currency)}</span>
    </div>
  );
}

export default function WinInfoRound({ round}) {
  const { t } = useTranslation();
  const { operatorPrizePlan, fixtures = [] } = round || {};
  let groups = {}

  if (operatorPrizePlan) {
    groups = operatorPrizePlan.payoutGroups
  }

  console.log('GROUPS', groups);
  return groups ? (
    <WinInfoWrapper>
      <div className="black-bar">
        <span>{t('correct_rows')}</span>
        <span>{t('winners')}</span>
        <span>{t('prize')}</span>
      </div>

      {groups && groups.map((group, i) => (
        <ResultRow
          key={`${i} result row`}
          maxNum={fixtures.length}
          index={i}
          unitRate = {group.unitsPerRow}
          {...group}
        />
      ))}
    </WinInfoWrapper>
  ) : <></>;
}