import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useCouponShares from '../couponShares/useCouponShares';
import NavBar from '../navbar';
import WinInfo from './components/wininfo';
import CouponInfo from './components/couponinfo';
import { CouponViewWrapper } from './styles';
import SelectedRound from '../routedround/components/selectedfixture/selectedfixture';
import useCouponShare from '../couponShares/useCouponShare';
import useInterval from 'react-useinterval';
import useDetailRound from '../rounds/useDetailRound';
import Progress from '../common/progress';
import PinkWin from './components/pinkwin';
import CouponDetail from './components/coupondetail';
import { isFixtureAfterGameStart } from '../common/DateTimeHelper';
import {useMediaQuery} from "react-responsive/src";
import useDetailedFixture from "../fixtures/useDetailedFixture";

const ContentArea = ({ fixture }) => {
  const { fixtureDetails } = useDetailedFixture(fixture.id);

  if (fixtureDetails) {
    const isAfterGameStart = isFixtureAfterGameStart(fixture);
    return <SelectedRound fixtureDetails={fixtureDetails} isAfterGameStart={isAfterGameStart} roundFixture={fixture}/>;
  }
  return <Progress />;
}

function calcCorrectSigns(row, correctRow) {
  let numCorrect = 0;
  for (let i = 0; i < correctRow.signs.length; i++) {
    if (row.signs[i] === correctRow.signs[i]) {
      numCorrect++;
    }
  }

  return numCorrect;
}

export default function CouponView() {
  const [couponShares, sharesQuery] = useCouponShares();

  const { couponId } = useParams();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });
  const initialFixtureIndexSelected = isSmallScreen ? -1 : 0;

  const [fixtureValue, setFixtureValue] = useState(initialFixtureIndexSelected);


  const [shallowCoupon] = (couponShares || []).filter(c => c.id === couponId);

  const couponShareResponse = useCouponShare(shallowCoupon);

  const detailRoundResponse = useDetailRound(!shallowCoupon ? undefined : shallowCoupon.coupon.roundId);
  const round = detailRoundResponse.round;
  const couponShare = couponShareResponse.couponShare;

  useInterval(() => {
    couponShareResponse.reloadCouponShare();
    detailRoundResponse.reloadDetailRound();
  }, 20000);

  if (!shallowCoupon) {
    return null;
  }

  if (detailRoundResponse.status === "loading" ||
    couponShareResponse.status === "loading") {
    return <div></div>;
  }

  if (!couponShares) return null;

  const fixture = fixtureValue === undefined || !round ? undefined : round.fixtures[fixtureValue];

  const isAfterDeadline = new Date(round.openTo) < Date.now();

  const deepCoupon = couponShare.coupon.coupon;
  const detailedBets = deepCoupon.bets;
  const correctRow = couponShare.coupon.correctRow;
  const preliminaryOutcome = {};

  let maxCorrectSigns = 0;

  if (deepCoupon && detailedBets && correctRow) {
    for (const betKey in detailedBets) {
      const betRows = detailedBets[betKey].rows;
      for (const rowKey in betRows) {
        const row = betRows[rowKey];
        const correctSigns = calcCorrectSigns(row, correctRow);

        if (correctSigns > maxCorrectSigns) {
          maxCorrectSigns = correctSigns;
        }

        if (!preliminaryOutcome[correctSigns]) {
          preliminaryOutcome[correctSigns] = 1
        } else {
          preliminaryOutcome[correctSigns] = preliminaryOutcome[correctSigns] + 1;
        }
      }
    }
  }

  let betResult = { amount: '0', currency: round.operatorTurnover.currency };

  if (couponShare.operatorPreliminaryResult) {
    betResult = couponShare.operatorPreliminaryResult;
  }

  if (couponShare.operatorResult) {
    betResult = couponShare.operatorResult;
  }

  let legs = {}
  if (couponShare.bets.length > 0) {
    legs = couponShare.bets[0].legs;
  }

  const isSingleRows = couponShare.bets.length > 1;


  if (window.innerWidth < 1000) {
    return (
        <>
          <NavBar whichToUnderline="3" />
          <CouponViewWrapper>
            <div className="left-flex">

              <PinkWin winAmount={betResult} nCorrectRows={maxCorrectSigns} couponShareId={couponShare.id} />

              <div className="top-border"></div>

              <CouponDetail
                  coupon={couponShare.coupon}
                  round={round}
                  isSingleRows={isSingleRows}
                  legs={legs}
                  fixtureValue={fixtureValue}
                  setFixtureValue={setFixtureValue}
              />


              {(isAfterDeadline && <WinInfo round={round} couponShare={couponShare} />)}

              <CouponInfo round={round} couponShare={couponShare} />

            </div>
          </CouponViewWrapper>
        </>
    );
  }

  return (
    <>
      <NavBar whichToUnderline="3" />
      <CouponViewWrapper>
        <div className="left-flex">

          <PinkWin winAmount={betResult} nCorrectRows={maxCorrectSigns} couponShareId={couponShare.id} />

          <div className="top-border"></div>

          <CouponDetail
            coupon={couponShare.coupon}
            round={round}
            isSingleRows={isSingleRows}
            legs={legs}
            fixtureValue={fixtureValue}
            setFixtureValue={setFixtureValue}
          />

          {(isAfterDeadline && <WinInfo round={round} couponShare={couponShare} />)}

          <CouponInfo round={round} couponShare={couponShare} />

        </div>
        <div className="right-flex">
          <ContentArea fixture={fixture} />
        </div>
      </CouponViewWrapper>
    </>
  );
}