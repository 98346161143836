import styled from 'styled-components';

export const RoundSummary = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;

export const RoundSummaryBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 0 12px;
`;

export const RoundSummaryBlockRow = styled.div`
    color: var(--text-black);
    display: block;
`;


export const RoundHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 120px;
  background: var(--off-white);
  overflow-y: auto;

  @media screen and (max-width: 349px) {
    font-size: 12px;
  }

  @media screen and (min-width: 350px) {
    font-size: 15px;
  }

  .active-content, .settled-content {
    width: 595px;
  }

  @media screen and (min-width: 620px) {
    .active-content, .settled-content {
      width: 595px;
      margin-top: 100px;
    }
  }

  .icon-holder {
    width: 40px;
  }

  .pixels-top {
    margin: 0 8px 0 0;
  }

  @media screen and (max-width: 619px) {
    .active-content {
      width: 90%;
      margin-top: 50px;
    }

    .settled-content {
      width: 100%;
      margin-top: 50px;
    }
  }

  .color-plate {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80px;
    margin: 0px 0px 0px 0px;
    color: var(--always-black);
  }

  .icon-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 10px 20px 10px;
  }


  .orange {
    background: var(--pinkish-white);
  }

  .green {
    background: var(--green-tint-background);
  }

  .bold {
    font-weight: bold;
    color: var(--text-black);
    width: 90px;
  }

  .bold-date {
    font-weight: bold;
    color: var(--text-black);
    width: 140px;
  }

  .coupon-wrapper {
    margin-top: 18px;
  }

  .no-rounds-block {
    height: 50px;
    padding: 10px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .no-rounds-block-text {
    font-weight: bold;
    display: block;
  }

  @media screen and (min-width: 620px) {
    .status-details {
      padding: 10px 20px 10px 13px;
    }
  }
  @media screen and (max-width: 619px) {
    .status-details {
      padding: 10px 0;
    }
  }

  .status-details {
    height: 50px;
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .link {
    text-decoration: none;
  }

  .time-div {
    display: flex;
    flex-direction: column;
  }

  .date-span {
    margin-bottom: 4px;
  }

  .coupon span {
    color: var(--text-black);
  }

  .flex {
    display: flex;
    align-items: center;
    padding-left: 0px;
  }

  .center-progress {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
`;