import React, { useState } from 'react';
import { Close } from '@material-ui/icons';
import { Wrapper } from './styles';
import { IkonIcon, Button, CheckmarkIcon, ShadowDiv } from '../../../reusable-components';
import StatBottomBar from '../statbottombar';

const StatTable = ({
  coupon,
}) => {
  let range = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
  const [renderConfirmStep, setRenderConfirmStep] = useState(0);
  const myParts = 1;
  const price = 36;

  return (
    <>
      {renderConfirmStep === 1 ? (
        <ShadowDiv>
          <div className="onclick-div" onClick={() => setRenderConfirmStep(0)} />
          <div className="shadow-content">
            <div className="flex-end">
              <Close className="close-icon" onClick={() => setRenderConfirmStep(0)} />
            </div>
            {IkonIcon(32, 30)}
            <span className="bold-span">Betala</span>
            <div className="text-div">
              <span>Vill du betala {price}kr för 1 andel i spelet?</span>
              <span>Om spelet inte lämnas in eller om det avbryts får du pengarna tillbaka.</span>
              <span>Genom att köpa en andel godkänner du <span className="span-underline">Villkor för att Spela med Poolarna</span>.</span>
            </div>
            <Button onClick={() => setRenderConfirmStep(2)} className="btn-width">Betala</Button>
          </div>
        </ShadowDiv>
      ) : renderConfirmStep === 2 && (
          <ShadowDiv>
            <div className="onclick-div" onClick={() => setRenderConfirmStep(0)} />
            <div className="shadow-content less-height">
              <div className="flex-end">
                <Close className="close-icon" onClick={() => setRenderConfirmStep(0)} />
              </div>
              {IkonIcon(32, 30)}
              <span className="bold-span">Du har köpt 1 andel</span>
              <Button onClick={() => setRenderConfirmStep(0)} className="btn-width">Ok</Button>
            </div>
          </ShadowDiv>
        )}
      <Wrapper>
        <div className="wrap">
          <span className="green-span">{CheckmarkIcon} Du har {myParts} andel</span>
          <Button className="btn" onClick={() => setRenderConfirmStep(1)}>Köp 1 andel</Button>
          <div className="white-content-div">
            <span className="bold-span">Radskiss</span>
            <span>Tänk på att radskissen inte behöver stämma överens med de rader som lämnas in.</span>
          </div>
          <div className="top-flex">
            <span className="bold-span">Match</span>
            <span className="bold-span">Lutar åt</span>
          </div>
          <table>
            <tbody>
              {range.map(i => (
                <tr key={`${i} tablerow`} className={`${(i % 2) === 1 ? 'green-bg' : ''}`}>
                  <td className="left-padding">{i}</td>
                  <td>Lagnamn - Lagnman</td>
                  <td className="td-width">
                    <div className="flex">
                      <div className="border-div">1</div>
                      <div className="border-div">X</div>
                      <div className="border-div">2</div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="bottom-flex">
            <span className="bold-span">Antal rader: 4</span>
          </div>
          <StatBottomBar />
        </div>
      </Wrapper>
    </>
  );
}

export default StatTable;
