import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import NavBar from '../navbar';
import { sortByName, sortByDate } from '../services/sortService';
import useShallowRounds from '../rounds/useShallowRounds';

const PlaySolo = ({ whichTips }) => {
  const [rounds = []] = useShallowRounds();
  const filteredRounds = rounds.filter(({ name }) => sortByName(name, whichTips)).sort(sortByDate);

  const roundsWithinTwoHoursAfterDeadline = filteredRounds.filter(({ openTo }) => {
    let date = new Date(openTo);
    date.setHours(date.getHours() + 2)
    return date > Date.now();
  }).sort(function (a, b) { return sortByDate(b, a) });
  
  const [firstRound] = filteredRounds || [];
  const [firstOpenRound] = roundsWithinTwoHoursAfterDeadline || [];

  const history = useHistory();

  useEffect(() => {
    if (firstOpenRound) {
      history.push(`/${whichTips}/rounds/${firstOpenRound.id}`)
      return;
    }
    if (firstRound) {
      history.push(`/${whichTips}/rounds/${firstRound.id}`)
    }
  });

  // fallback
  return (
    <>
      <NavBar whichToUnderline={whichTips} />      
    </>
  );
};

export default PlaySolo;