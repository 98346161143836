import styled from 'styled-components';
import { TabComponentPadding } from '../../../../../reusable-components';


export const Wrapper = styled(TabComponentPadding)`
display: flex;    
flex-direction: column;
align-items: center;
margin-bottom: 100px;

.subheader{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

@media screen and (min-width: 601px) {
  .subheader{
    width: 450px;
  }
}

@media screen and (max-width: 600px) {
  .subheader{
    width: 90%;
  }
  
  .subheader h3 {
    font-size: 13px;
  }
}


`;

export const StandingsTableRowContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;  
  flex-wrap: nowrap;
  height: 30px;
  background-color: ${props => (!props.even ? 'transparent' : 'var(--green-tinted-white)')};
  font-weight: ${props => (props.inFixture ? 'bold' : 'normal')};

  @media screen and (min-width: 601px) {
    width: 450px;
    font-size: 15px;

    .team-name-wrapper {
      min-width: 140px;
    }

    .team-name-col {
      min-width: 130px;
    }
  }

  @media screen and (max-width: 600px) {
    width: 90%;
    font-size: 12px;

    .team-name-wrapper {
      min-width: 90px;
    }

    .team-name-col {
      width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .pos-indicator-wrapper {
    min-width: 20px;
  }

  .team-name-col {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .team-name-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
  }

  @media screen and (min-width: 451px) {
    .key-figure {
      min-width: 32px;
    }
  }
  
  @media screen and (max-width: 450px) {
    .key-figure {
      min-width: 16px;
    }    
  }
  
  .col-spacing {
    width: 8px;
  }
`;

export const StandingsTableRowHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 30px;
  background-color: transparent;

  .team-name-col {    
    min-width: 130px;
  }

  .pos-col {
    min-width: 20px;
  }

  @media screen and (min-width: 601px) {
    width: 450px;
    font-size: 15px;

    .team-name-col {
      min-width: 130px;
    }
  }

  @media screen and (min-width: 414px) and (max-width: 600px) {
    width: 90%;
    font-size: 12px;

    .team-name-col {
      min-width: 90px;
    }
  }

  @media screen and (max-width: 413px) {
    width: 90%;
    font-size: 10px;

    .team-name-col {
      min-width: 90px;
    }
  }
  
  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .key-figure-col {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;
    text-align: left;
  }

  @media screen and (min-width: 451px) {
    .key-figure-col {
      min-width: 32px;
    }
  }

  @media screen and (max-width: 450px) {
    .key-figure-col {
      min-width: 16px;
    }
  }
  
  .col-spacing {
    width: 8px;
  }
`;

