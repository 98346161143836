import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_SV } from "./sv/translations";
import { TRANSLATIONS_FA } from "./fa/translations";

import { RULES_EN } from "./en/rules";
import { RULES_SV } from "./sv/rules";
import { RULES_FA } from "./fa/rules";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: TRANSLATIONS_EN,      
                rules: RULES_EN                    
            },
            sv: {
                translation: TRANSLATIONS_SV,
                rules: RULES_SV
            },
            fa: {
                translation: TRANSLATIONS_FA,
                rules: RULES_FA
            },
        },
    });

i18n.changeLanguage("en")
    .then((r) => {console.log("lang set to", r)});