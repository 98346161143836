import { usePlayer } from "../player/playerContext";
import { useQuery, useQueryCache } from "react-query";
import fetchApi from "../backend/fetchApi";

const useTeams = () => {
  const [player] = usePlayer();

  const queryCache = useQueryCache();
  const syndicateTeamsUrl = player && player._links.syndicateTeams.href;
  const syndicateTeams = useQuery(['syndicateTeams', syndicateTeamsUrl], () => {
    return fetchApi(syndicateTeamsUrl).then(response => response.json()).then(r => r._embedded.syndicateTeams)
  }, {
    enabled: !!player,
    initialData: player && player._embedded.syndicateTeams,
    initialStale: false,
  })

  return [syndicateTeams.data, {
    ...syndicateTeams,
    reloadRounds: () => queryCache.invalidateQueries('syndicateTeams')
  }]
}

export default useTeams;