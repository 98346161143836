import { EightIcon, H3, PoolxLogoBlack, SixIcon, ThirteenIcon, TwelveIcon } from "../reusable-components";
import moment from 'moment';

export const getRoundName = (round, t) => {

    if (!round) {
      return "";
    }
  
    switch (round.name) {
      case 'Maxtipset':
        return t('max');
      case 'Mixtipset':
        return t('mix');
      case 'Flextipset6':
      case 'Flextipset8':
      case 'Flextipset10':
        return t('flex');
      default:
        return round.name;
    }
  
  }

  export const getBackground = (round) => {

    if (!round) {
      return '#B8CCF1';
    }
  
    switch (round.name) {
      case 'Maxtipset':
        return '#B8CCF1';
      case 'Mixtipset':
        return '#FAEEAE';
      case 'Flextipset6':
      case 'Flextipset8':
      case 'Flextipset10':
        return '#E0D8EE';
      default:
        return '#B8CCF1';
    }
  
  }
  
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  export const getDateString = (round, t) => {

    if (!round) {
      return "";
    }
  
    const start = new Date(round.openTo);
    const now = new Date(Date.now());
    let today = now;
    today.setHours(0,0,0,0);
    let startDate = start;
    startDate.setHours(0,0,0,0);
    
    let locale = t('locale');
    
    if (startDate < today) {
      return moment(round.openTo).locale(locale).format("L");
    }
    else if (startDate.toDateString() !== today.toDateString()) {
      return capitalizeFirstLetter(moment(round.openTo).locale(locale).format("dddd HH:mm"));
    }
    else {
      let dateStr = t('today');
      dateStr += ' ';
      dateStr += moment(round.openTo).locale(locale).format("HH:mm");
      return dateStr;
    }
  }

  export function getRoundPlate(round,t) {

    const roundName = getRoundName(round,t)
  
    switch (round.name) {
      
      case "Maxtipset":
        return (
          <div className='color-plate' style={{background:'#B8CCF1'}}>
            <H3>{roundName}</H3>        
            <div className='icon-holder'>
              <ThirteenIcon height="60"/>            
            </div>
          </div>
        );      
        case "Mixtipset":
        return (
          <div className='color-plate' style={{background:'#FAEEAE'}}>
            <H3>{roundName}</H3>        
            <div className='icon-holder'>
              <TwelveIcon height="60"/>            
            </div>
          </div>
        );        
        case "Flextipset6":
        return (
          <div className='color-plate' style={{background:'#E0D8EE'}}>
            <H3>{roundName}</H3>        
            <div className='icon-holder'>
              <SixIcon height="60"/>            
            </div>
          </div>
        );
      case "Flextipset8":
        return (
          <div className='color-plate' style={{background:'#E0D8EE'}}>
            <H3>{roundName}</H3>        
            <div className='icon-holder'>
              <EightIcon height="60"/>            
            </div>
          </div>
        );
      default:
        return (
          <div className='color-plate' style={{background:'#FAEEAE'}}>
            <H3>{roundName}</H3>        
            <div className='icon-holder'>
              <PoolxLogoBlack height="60"/>
            </div>
          </div>
        );
    }
  }
    export function getRoundIcon(round) {

      switch (round.name) {
        
        case "Maxtipset":
          return (
            <div className='color-plate'>              
              <div className='icon-holder'>
                <ThirteenIcon height="20"/>            
              </div>
            </div>
          );      
          case "Mixtipset":
          return (
            <div className='color-plate'>              
              <div className='icon-holder'>
                <TwelveIcon height="20"/>            
              </div>
            </div>
          );        
          case "Flextipset6":
          return (
            <div className='color-plate'>              
              <div className='icon-holder'>
                <SixIcon height="20"/>            
              </div>
            </div>
          );
        case "Flextipset8":
          return (
            <div className='color-plate'>              
              <div className='icon-holder'>
                <EightIcon height="20"/>            
              </div>
            </div>
          );
        default:
          return (
            <div className='color-plate'>              
              <div className='icon-holder'>
                <EightIcon height="20"/>            
              </div>
            </div>
          );
      }
  }