import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import NavBar from '../navbar';
import { Wrapper } from './styles';
import {
  BackgroundImage,
  H2,
  BackIcon,
  ForwardArrowIcon,
  ExpandMoreArrowIcon
} from '../reusable-components';
import image from '../images/footballfield.jpg';
import StatTable from './components/stattable';
import useTeams from '../playpoolarna/useTeams';
import TeamMember from "../playpoolarna/components/teammember";
import {StatBottomBarWrapper} from "./components/statbottombar/styles";
import {useTranslation} from "react-i18next";
import {usePlayer} from "../player/playerContext";

const RoutedTeam = () => {
  let history = useHistory();
  const [selectedGame, setSelectedGame] = useState(undefined);
  const [memberListVisible, setMemberListVisible] = useState(false);
  let [myTeams] = useTeams();
  if (!myTeams) {
    myTeams = [];
  }
  const [, , teamId] = useLocation().pathname.split('/')
  const team = myTeams.find(team => team.id === teamId) || {};
  const { name = '', syndicates } = team;
  const numberOfMembers = team.members.length || 0
  const [firstSyndicate] = syndicates || []; // I guess we need to find the one that is STATUS === ACTIVE ?
  const { nShares, stakePerShare, coupon } = firstSyndicate || {};
  const { amount } = stakePerShare || {};
  const total = amount * nShares;
  const [player, config] = usePlayer();

  const gameName = 'Poolx V26 12/6'; // we need this
  const playStop = new Date(Date.now() + 172_800_000); // we might need this aswell
  const buyStop = new Date(Date.now() + 100_000_000); // we might need this aswell

  const renderMemberList = () => {
    return team.members.map(member =>
        <TeamMember isTeamCreator={member.playerId === team.creatorPlayerId} nickname={member.nickname} />);
  }
  const { t } = useTranslation();
  if (!config.syndicateBetting) {
    return <div/>;
  }

  return (
    <>
      <NavBar whichToUnderline="1" />
      <Wrapper>
        <BackgroundImage height={250} image={image}>
          <H2 color="#fff">
            YTA FÖR NYHET/BILD
        </H2>
        </BackgroundImage>

        <div className="flex-wrapper">
          <div className="green-div">
            {selectedGame ? (
              <>
                <div className="top-div">
                  <div className="icon-div" onClick={() => {
                    setSelectedGame(undefined);
                  }}>
                    {BackIcon}
                  </div>
                </div>
                <H2 className="title-margin">{gameName.toUpperCase()}</H2>
                <span>Spelstopp: Om {playStop.getDay()} {playStop.getDay() > 1 ? 'dagar' : 'dag'}</span>
                <ul>
                  <li>
                    Andelspris: <span className="bold-span">{amount}kr</span>
                  </li>
                  <li>
                    Sista andelsköp: <span className="bold-span">Om {buyStop.getDay()} {buyStop.getDay() > 1 ? 'dagar' : 'dag'}</span>
                  </li>
                  <li>
                    Sålda andelar: <span className="bold-span">{nShares}</span>
                  </li>
                  <li>
                    Att spela för: <span className="bold-span">{total}kr</span>
                  </li>
                </ul>
                <div className="white-div">
                  <StatTable coupon={coupon} />
                </div>
              </>
            ) : (
                <>
                  <div className="top-div">
                    <div className="icon-div" onClick={() => {
                      history.push('/teams');
                    }}>
                      {BackIcon}
                    </div>

                    <div className="options-div">
                      <span>Autoköp</span>
                      <span>Lämna lag</span>
                    </div>
                  </div>
                  <H2 className="title-margin">{name.toUpperCase()}</H2>
                  <span className="members-span">{numberOfMembers} medlemmar</span>

                  <div className="game-div">
                    <span>{gameName}</span>
                    <span className="not-bold">{amount}kr</span>
                    <span onClick={() => setSelectedGame({})} className="center-icon">
                      Till spelet<span className="icon-margin">{ForwardArrowIcon}</span>
                    </span>
                  </div>

                  <div className="members-div">
                    <span className="flex">{numberOfMembers} medlemmar
                      <div onClick={() => setMemberListVisible(!memberListVisible)}>
                        {memberListVisible ? ExpandMoreArrowIcon : ForwardArrowIcon}
                      </div>
                    </span>
                  </div>
                  <StatBottomBarWrapper>
                    {memberListVisible && renderMemberList()}
                  </StatBottomBarWrapper>

                  <div className="done-games-div">
                    <span className="flex">Avgjorda spel {ExpandMoreArrowIcon}</span>
                  </div>
                </>
              )}

          </div>
          <div className="chat-div">
            <H2>KOMMENTARER</H2>
          </div>
        </div>
      </Wrapper>
    </>
  );
}

export default RoutedTeam;