import React from "react";
import { Link } from 'react-router-dom';
import NavBar from "../navbar";
import Moment from "react-moment";
import { ForwardArrowIcon, RowsIcon } from "../reusable-components";
import {RoundHistoryWrapper, RoundSummary, RoundSummaryBlock, RoundSummaryBlockRow} from "./roundhistory_styles";
import { useTranslation } from "react-i18next";
import useShallowRounds from "../rounds/useShallowRounds";
import Progress from "../common/progress";
import MediaQuery from "react-responsive/src";
import {formatStringCurrency} from "../common/CurrencyFormatter";
import {getDateString, getRoundIcon, getRoundName} from "../common/RoundHelper";

function Round({ color, round }) {
  const { openTo} = round || {};
  const openToDate = new Date(openTo);
  const { t } = useTranslation();
  const { id } = round;
  let friendlyName = getRoundName(round,t);
  let locale = t('locale');
  let link = `$/rounds/${id}`;

  return (
    <Link to={link} className={`${color} link`}>
      <div className={`${color} flex`} style={{ marginBottom: "5px"}}>        
        {getRoundIcon(round)}
        <MediaQuery minWidth={620}>
            <span className="bold-date">{getDateString(round, t)}</span>
         </MediaQuery>
         <MediaQuery maxWidth={619}>
            <span className="bold-date"><Moment format={"DD/MM"} locale={locale}>{openToDate}</Moment></span>
         </MediaQuery>
         <span className="bold">{friendlyName}</span>
        <div className="status-details">
          {roundStatusDetails(round, t)}
          {ForwardArrowIcon}
        </div>
      </div>
    </Link>
  );
}

const roundStatusDetails = (round, t) => {

    const { operatorTurnover, operatorPrizePlan } = round || {};
    const { currency } = operatorTurnover || {};

    let highestWin = 0;
    let turnoverAmount = 0;

    if (operatorTurnover) {
        turnoverAmount = operatorTurnover.amount;
    }
    if (operatorPrizePlan) {
        const { payoutGroups } = operatorPrizePlan || {};
        const [first] = payoutGroups || [];
        const { payoutPerRow } = first || {};
        if (payoutPerRow) {
            highestWin = payoutPerRow.money.amount;
        } else {
            console.error(`payout per row unavailable for first payout group of round ${round.id}`)
        }
    }

    let statusLabel = 'N/A';
    const now = new Date().getTime();
    const roundOpenTo = Date.parse(round.openTo);
    if (round.status === 'ACTIVE') {
        statusLabel = t('round_status_open');

        if (now > roundOpenTo) {
            statusLabel = t('round_active_after_deadline')
        }
    }
    if (round.status === 'VOIDED') {
        statusLabel = t('round_status_voided');
    }
    if (round.status === 'PAUSED') {
        statusLabel = t('round_status_paused');
    }

    // visible if vide enough screen and if round is either active of finished
    const turnoverBlock = () => {
        if (round.status === 'ACTIVE' || round.status === 'FINISHED') {
            return (
                <RoundSummaryBlock>
                    <RoundSummaryBlockRow style={{marginBottom: '4px'}}>{t('turnover')}:</RoundSummaryBlockRow>
                    <RoundSummaryBlockRow>
                        {formatStringCurrency(turnoverAmount, currency)}
                    </RoundSummaryBlockRow>
                </RoundSummaryBlock>
            );
        }

        return <div />;
    }

    // always visible, displays highest win in round if status is finished
    const statusBlock = () => {
        if (round.status === 'FINISHED') {
            return (
                <RoundSummaryBlock>
                    <RoundSummaryBlockRow style={{marginBottom: '4px'}}>{t('highest_win')}:</RoundSummaryBlockRow>
                    <RoundSummaryBlockRow>
                        {formatStringCurrency(highestWin, currency)}
                    </RoundSummaryBlockRow>
                </RoundSummaryBlock>
            );
        }

        return (
            <RoundSummaryBlock className={'round-summary-block'}>
                <RoundSummaryBlockRow className={'round-summary-block-row'}>{statusLabel}</RoundSummaryBlockRow>
            </RoundSummaryBlock>
        );
    }

    return (
        <RoundSummary>
            <MediaQuery minWidth={620}>
                {turnoverBlock()}
            </MediaQuery>
            {statusBlock()}
        </RoundSummary>
    )
}

const NoRounds = ({ color }) => {
  const { t } = useTranslation();

  return (
    <div className={`${color} flex`} style={{ marginBottom: '5px', paddingLeft: '8px'}}>
      {RowsIcon}
      <div className="no-rounds-block">
        <div className="no-rounds-block-text">{t('no_rounds_available')}</div>
      </div>
    </div>
  );
}

const RoundHistory = () => {
  const [rounds, {isLoading}] = useShallowRounds();

  const { t } = useTranslation();

  const activeRounds = rounds && rounds.filter(round => (round.status === 'ACTIVE' || round.status === 'PAUSED')).sort((a, b) => new Date(a.openTo) - new Date(b.openTo));
  const finishedRounds = rounds && rounds.filter(round => (round.status === 'FINISHED' || round.status === 'VOIDED')).sort((a, b) => new Date(b.openTo) - new Date(a.openTo));

  if (isLoading) {
      return (
          <>
              <NavBar whichToUnderline="4" />
              <RoundHistoryWrapper>
                  <div style={{paddingTop: '120px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <Progress />
                  </div>
              </RoundHistoryWrapper>
          </>
      )
  }

  return (
    <>

      <NavBar whichToUnderline="4" />

      <RoundHistoryWrapper>
        <div className="active-content">
          <span className="bold">{t('current_rounds')}</span>
          {rounds ? (
            <>
              <div className="coupon-wrapper">
                {activeRounds.length > 0 ? activeRounds.map((round, i) => <Round key={`activeRound ${i}`} color="green" round={round} />) : <NoRounds color={"green"} />}
              </div>

              <div className="settled-content">
                <span className="bold">{t('settled_rounds')}</span>
                <div className="coupon-wrapper">
                  {finishedRounds.length > 0 ? finishedRounds.map((round, i) => <Round key={`finishedRound ${i}`} color="orange" round={round} />) : <NoRounds color={"orange"} />}
                </div>
              </div>
            </>
          ) : (
            <div className="center-progress">
              <Progress />
            </div>
          )}
        </div>
      </RoundHistoryWrapper>
    </>
  )
}

export default RoundHistory;
