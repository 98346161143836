import styled from 'styled-components';

export const FixtureWrapper = styled.div`
  width: 100%;
  height: 70px;
  border-bottom: 1px var(--off-black) solid;  
  background: ${({ selected }) => selected ? 'var(--pinkish-white)' : ''};
  
  .draw-terms-text {
    margin-top: 8px;
    font-size: 11px;
  }

  .text-div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: 11px;
    color: var(--text-black);
    padding-top: 7px;
    padding-left: 11px;
    height: 63px;
  }

  .text-div-overline {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: 11px;
    color: var(--text-black);
    padding-top: 7px;
    padding-left: 11px;
    height: 63px;
  }

  .top-div {
    display: flex;
    font-weight: bold;
    margin-bottom: 3px;
  }

  .small-font {
    font-size: 8px;
    line-height: 9px;
    margin-top: 8px;
  }

  .m-right {
    margin-right: 5px;
  }

  .line-break {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;