import React from "react";
import {IkonIcon} from "../../../reusable-components";

const TeamMember = ({nickname, isTeamCreator, sharesPurchased = 0}) => {

    return (
        <div className="green-under-div b">
            <div className="under-flex">
                <div className="text-flex">
                    {IkonIcon(22, 24)}
                    <div className="text-div left-padding">
                        <span className="bold-span">{nickname}</span>
                        <span>{isTeamCreator ? 'Lagkapten' : 'Medlem'}</span>
                    </div>
                </div>
                <span>{sharesPurchased} {sharesPurchased === 1 ? 'andel' : 'andelar'}</span>
            </div>
        </div>
    );
}

export default TeamMember