import React from 'react';
import { StatBottomBarWrapper } from './styles';
import { IkonIcon, ExpandLessArrowIcon } from '../../../reusable-components';

export default function StatBottomBar() {
  return (
    <StatBottomBarWrapper>
      <div className="green-members">
        <div className="green-top-flex">
          <span className="bold-span">
            2 medlemmar
          </span>
          {ExpandLessArrowIcon}
        </div>
      </div>
      <div className="green-under-div b">
        <div className="under-flex">
          <div className="text-flex">
            {IkonIcon(22, 24)}
            <div className="text-div left-padding">
              <span className="bold-span">Medlem</span>
              <span>Lagkapten</span>
            </div>
          </div>
          <span>1 andel</span>
        </div>
      </div>

      {/* one more hardcoded */}
      <div className="green-under-div">
        <div className="under-flex">
          <div className="text-flex">
            {IkonIcon(22, 24)}
            <div className="text-div left-padding">
              <span className="bold-span">Medlem</span>
              <span>Lagkapten</span>
            </div>
          </div>
          <span>1 andel</span>
        </div>
      </div>
    </StatBottomBarWrapper>
  );
}