import { useMemo } from 'react';
import keyBy from "lodash/keyBy";
import useRounds from "./useRounds";
import { RoundsById } from './types';

const useRound = (id: string) => {
  const [rounds]: any = useRounds();

  const roundsById: RoundsById = useMemo(() => keyBy(rounds, 'id'), [rounds]);

  return roundsById[id];
}

export default useRound;