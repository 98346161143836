import React from "react";
import { Link } from 'react-router-dom';
import useCouponShares from "../couponShares/useCouponShares";
import NavBar from "../navbar";
import Moment from "react-moment";
import { ForwardArrowIcon, TicketIcon } from "../reusable-components";
import { useTranslation } from "react-i18next";
import Progress from "../common/progress";
import { MyCouponSharesWrapper } from "./styles";
import MediaQuery from "react-responsive/src";
import {formatStringCurrency} from "../common/CurrencyFormatter";
import {getRoundName} from "../common/RoundHelper";
import {CircularProgress} from "@material-ui/core";

function Coupon({ color, couponShare }) {
  const { stake, createdAt, result, id, coupon } = couponShare;
  const { t } = useTranslation();
  const { round } = coupon || {};
  const { openTo} = round || {};
  const { amount = 0 } = stake || {};
  const { currency = '' } = stake || {};
  const { amount: resAmount } = result || {};
  const createdAtDate = new Date(createdAt);
  const openToDate = new Date(openTo);
  const name = getRoundName(round,t);
  let locale = t('locale');

  let statusLabel = 'N/A';
  if (couponShare.status === 'CANCELED') {
    statusLabel = t('coupon_share_status_cancelled');
  }

  return (
    <Link to={`/coupon/${id}`} className={`${color} link`}>
      <div className={`${color} flex`} style={{ marginBottom: "5px" }}>
        {TicketIcon}
        <div className="coupon">
          <MediaQuery minWidth={620}>
            <td width="120px"><span className="bold">{name} <br /><Moment format={"yyyy-MM-DD"} locale={locale}>{openToDate}</Moment></span></td>
          </MediaQuery>
          <MediaQuery maxWidth={619}>
            <td width="120px"><span className="bold">{name} <br /><Moment format={"DD/MM"} locale={locale}>{openToDate}</Moment></span></td>
          </MediaQuery>
          <MediaQuery minWidth={620}>
            <div className="time-div">
              <span className="date-span"><Moment format={"yyyy-MM-DD"} locale={locale}>{createdAtDate}</Moment></span>
              <span><Moment format={"HH:mm"} locale={locale}>{createdAtDate}</Moment></span>
            </div>
          </MediaQuery>

          <div>
            <span>{t('stake')}: {formatStringCurrency(amount, currency)}</span>
          </div>

          <MediaQuery minWidth={620}>
            {result ? (
                <span>{resAmount > 0 ? `${formatStringCurrency(resAmount, currency)}` : t('no_profit')}</span>
            ) : <span>{statusLabel}</span>}
            {ForwardArrowIcon}
          </MediaQuery>

        </div>
      </div>
    </Link>
  );
}

const NoCoupons = ({ color }) => {
  const { t } = useTranslation();

  return (
    <div className={`${color} flex`} style={{ marginBottom: '5px' }}>
      {TicketIcon}
      <div className="coupon">
        <span className="bold">{t('no_coupons')}</span>
      </div>
    </div>
  );
}

const CouponShareList = () => {
  const [couponShares, sharesQuery] = useCouponShares();
  const { t } = useTranslation();

  if(sharesQuery.isLoading || sharesQuery.isIdle){
    return (
      <>
        <NavBar whichToUnderline="3" />
        <MyCouponSharesWrapper>
          <div style={{paddingTop: '120px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Progress />
          </div>
        </MyCouponSharesWrapper>
      </>
    )
  }

  const finishedCouponsFilter = (status) => {
    return status === 'SETTLED' || status === 'CANCELED';
  }

  const activeCoupons = couponShares && couponShares.filter(coupon => coupon.status === 'PENDING');
  const settledCoupons = couponShares && couponShares.filter(coupon => finishedCouponsFilter(coupon.status));

  const fetchingShares = () => {
    if (sharesQuery.isFetching) {
      return (
          <CircularProgress size={'12px'} style={{marginLeft: '6px'}} color="inherit"/>
      );
    }
    return <></>;
  }

  return (
    <>
      <NavBar whichToUnderline="3" />
      <MyCouponSharesWrapper>
        <div className="active-content">
          <span className="bold">{t('unsettled_coupons')} {fetchingShares()}</span>
          {couponShares ? (
            <>
              <div className="coupon-wrapper">
                {activeCoupons.length > 0 ? activeCoupons.map((couponShare, i) => <Coupon key={`couponShare ${i}`} color="green" couponShare={couponShare} />) : <NoCoupons color={"green"} />}
              </div>

              <div className="settled-content">
                <span className="bold">{t('settled_coupons')}</span>
                <div className="coupon-wrapper">
                  {settledCoupons.length > 0 ? settledCoupons.map((couponShare, i) => <Coupon key={`settledcouponshare ${i}`} color="orange" couponShare={couponShare} />) : <NoCoupons color={"orange"} />}
                </div>
              </div>
            </>
          ) : (
            <div className="center-progress">
              <Progress />
            </div>
          )}
        </div>
      </MyCouponSharesWrapper>
    </>
  )
}

export default CouponShareList;
