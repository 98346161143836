import styled from 'styled-components';

export const UpdateSyndicateFormWrapper = styled.div`
  .green-div {
    background: var(--green-tint-background);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 42px;
    margin-bottom: 42px;
    justify-content: space-between;
  }

  .title-margin {
    margin-bottom: 10px;
  }

  .form-margin {
    margin: 33px 0;
    width: 595px;
  }

  .button-margin {
    margin: 33px 0;
  }
`;