import styled from 'styled-components';
import { TabComponentPadding } from '../../../../../reusable-components';

export const Wrapper = styled(TabComponentPadding)`
  flex-direction: column;  
  align-items: center;
  margin-bottom: 100px;

  .stat-cols-wrapper {
    display: flex;        
    justify-content: center;
  }

  .subheader{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;    

}
  
  .head-to-head-stats-col {
    display: flex;
    flex-direction: column;
  }
  
  .latest-fixtures-col {
    display: flex;
    flex-direction: column;
    margin: 0 10px 0 10px;
  }

  @media screen and (min-width: 601px) {
    .stat-cols-wrapper {
      flex-direction: row;  
    }    

    .subheader {
      width: 400px;
    }
    
  }

  @media screen and (max-width: 600px) {
    .stat-cols-wrapper {
      flex-direction: column;
    }    

    .latest-fixtures-col {      
      margin: 0 10px 20px 10px;
    }

    .subheader {
      width:90%;
    }
  }
  
`;

export const PreviousFixtureRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;  
  width: 192px;
  background-color: ${props => (!props.even ? 'transparent' : 'var(--green-tinted-white)')}; 
  
  .season-title {
    font-size: 10px;    
    width: 95%;
    margin: 8px 0px 8px 6px;    
    display: flex;
    flex-direction: row;
    justify-content: space-between;        
  }

  .no-linebreak {
    margin: 0px 8px 0px 0px;    
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;    
  }

  .date {
    min-width:60px;
  }
  
  .team-and-score-row {    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 180px;
    padding: 0px 6px 0px 6px;    
  }

  .team-and-score-area {        
    margin: 0px 0px 10px 0px;    
  }
  
  .team-name-col {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .team-name-bold {
    font-weight: bold;
  }

  .score-col {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

