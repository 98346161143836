
import React from "react";
import { H2 } from "../../reusable-components";
import { useTranslation } from "react-i18next";
import { Wrapper } from "./styles";
import AgentDiv from "../agentdiv";
import useAgents from "../useAgents";

const AgentList = () => {
  const { t } = useTranslation();
  const agentsResponse = useAgents();

  if(agentsResponse[1].status==="Loading"){
   return (<div></div>);
  }

  return <div>
    <Wrapper>
      <div className="green-div">
        <H2 className="title-margin">{t('agents')}</H2>
        {agentsResponse[0] && agentsResponse[0].map((agent, i) => {          
          return (
            <AgentDiv key={`${i} agentdiv`} shallowAgent={agent} />
          )
        }
        )}
      </div>
    </Wrapper>
  </div>;

}

export default AgentList;