import Moment from "react-moment";
import React from "react";

const dayToken = ['day_sun', 'day_mon', 'day_tue', 'day_wed', 'day_thu', 'day_fri', 'day_sat'];

// Return a token representation of the day with an index, this token can be translated by i18next
export const getDayToken = (date) => {
    const dayIndex = date.getDay();
    return dayToken[dayIndex];
}

export const simpleDateFormat = (date, fmt) => {
    const year = date.getYear();
    const month = date.getMonth() + 1;
    const day = date.getDay();
    const min = date.getMinutes();
    const second = date.getSeconds();

}

export const isFixtureAfterGameStart = (fixture) =>{
  return new Date(fixture.startingAt) < Date.now() || fixture.status === "PLAYING" || fixture.status === "FINISHED";
}

export const fixtureTime = (fixture,t) => {

    const { draw } = fixture;
    if (draw && (draw.status === 'PUBLISHED' || draw.status === 'FINISHED')) {
        return (
            <span>{t('')}</span>
        );
    }

    if(fixture.status==="FINISHED"){
      return(        
        <span>
          {t('full_time')}
        </span>
      );
    }
  
    if(fixture.status==="PLAYING"){
      return(        
        <span>
          {t('in_running')}
        </span>
      );
    }

    let locale = t('locale');
  
    return(        
      <span>
        {t('starts')} <Moment format={"HH:mm"} locale={locale}>{fixture.startingAt}</Moment>
      </span>
    );
  }