import styled from 'styled-components';
import { TabComponentPadding } from '../../../../../reusable-components';

export const Wrapper = styled(TabComponentPadding)`
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom-margin: 40px;

  .weather-image {
    width: auto;
    height: 60px;
  }

  .weather-image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 10px 0px;
  }

  .twitter-box {
    padding: 10px 0px 10px 0px;
  }

  .flex-list {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 100px;
  }

  .weather-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (min-width: 601px) {
    .flex-list {
      width: 400px;
    }

    .weather-image-wrapper {
      width: 400px;
    }

    .subheader {
      width: 400px;
    }
  }

  @media screen and (max-width: 600px) {
    .flex-list {
      width: 90%;
    }

    .weather-image-wrapper {
      width: 90%;
    }

    .subheader {
      width: 90%;
    }
  }


  .subheader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .flex-row-odd {
    display: flex;
    flex-direction: row;
    background: var(--green-tint-background);
    justify-content: space-between;
    align-items: center;
    height: 30px;
  }

  .flex-row-even {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 30px;
  }

  .flex-item {
    display: flex;
  }

`;