import styled from 'styled-components';

export const Wrapper = styled.div`

  .flex-wrapper {
    display: flex;
  }

  .green-div {
    flex: 2;
    background: var(--green-tint-background);
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 544px;
  }

  .chat-div {
    flex: 1;
    background: rgba(247, 234, 213, 0.5);
    display: flex;
    justify-content: center;
    padding-top: 15px;
  }

  .top-div {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .icon-div {
    cursor: pointer;
    margin: 18px 0 0 25px;
  }

  .options-div {
    margin: 20px 46px 0 0;
    font-weight: bold;
    color: var(--text-black);
    font-size: 11px;
  }

  .options-div span {
    cursor: pointer;
  }

  .options-div span:first-child {
    margin-right: 10px;
  }

  .title-margin {
    margin-bottom: 7px;
  }

  .game-div, .members-div, .done-games-div {
    background: var(--off-white);
    margin: 0 20px 15px 10px;
    width: 594px;
    padding: 17px 26px 18px 19px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    align-items: center;
    font-size: 14px;
  }

  .not-bold {
    font-weight: normal;
  }

  .center-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .members-span {
    color: #060606;
    margin-bottom: 23px;
  }

  .icon-margin {
    margin-left: 6px;
  }

  @media screen and (max-width: 1000px) {
    .icon-margin.pixels-top {
      display: none;
    }
  }

  .expand-icon {
    height: 20px;
    width: 20px;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .bold-span {
    font-weight: bold;
  }

  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0 19px 0 10px;
    width: calc(100% - 29px);
    color: var(--text-black);
    font-size: 15px;
  }

  .white-div {
    width: 100%;
    background: var(--off-white);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 40px 0 25px 0;
  }

  .flex-fix {
    display: flex;
  }
`;