import React from 'react';
import { Wrapper } from './styles';
import { useTranslation } from "react-i18next";
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { H3 } from '../../../../../reusable-components';
import Moment from "react-moment";
import {getDrawText} from "../../../couponform/components/fixturecard";

const MatchInfo = ({ fixtureDetails, fixture }) => {

  const { t } = useTranslation();
  const venue = fixtureDetails.fixture.meta.venueData;
  const weather = fixtureDetails.fixture.meta.weatherReport;
  const homeTeamTwitter = fixtureDetails.fixture.homeTeam.twitterHandle.substring(1);
  const awayTeamTwitter = fixtureDetails.fixture.awayTeam.twitterHandle.substring(1);

  const startingDateTime = new Date(fixtureDetails.fixture.startingAt);
  let locale = t('locale');

  return (
    <Wrapper>

      <div className="subheader">
        <H3>{t('general_info')}</H3>
      </div>
      <div className="flex-list">
        <div key="date-1" className="flex-row-odd"><span>{t('date')}</span><span><Moment format={"yyyy-MM-DD"} locale={locale}>{startingDateTime}</Moment></span></div>
        <div key="date-2" className="flex-row-even"><span>{t('event_kickoff')}</span><span><Moment format={"HH:mm"} locale={locale}>{startingDateTime}</Moment></span></div>
        {fixtureDetails.league ?
          <div key="league" className="flex-row-odd"><span>{t('league')}</span><span>{fixtureDetails.league.name}</span></div>:
          <div></div>}
        {venue ?
          <div key="venue" className="flex-row-even"><span>{t('venue')}</span><span>{venue.name}</span></div> :
          <div></div>}
        {fixture.draw ?
        <div key="draw-info" className="flex-row-even">
            <span>{t('draw_disclaimer')}</span>
            <span>{getDrawText(fixture.draw, t)}</span>
        </div> : <div />}

      </div>

      {weather ?
        <div className={'weather-wrapper'}>
          <div className="subheader">
            <H3>{t('weather')}</H3>
          </div>
          <div className="weather-image-wrapper" >
            <img className="weather-image" src={weather.weather_icon_url} alt="weather" />
          </div>
          <div className="flex-list">
            <div key="temp" className="flex-row-odd"><span>{t('temperature')}</span><span>{Math.round(weather.temperature_celsius * 10) / 10} {'\u00b0'}C</span></div>
            <div key="wind" className="flex-row-even"><span>{t('wind')}</span><span>{weather.wind_speed}</span></div>
            <div key="moisture" className="flex-row-odd"><span>{t('moisture')}</span><span>{weather.humidity}</span></div>
          </div>

        </div> :
        <div></div>}

      {homeTeamTwitter.length > 0 ?
        <div className="twitter-box">
          <div className="subheader">
            <H3>{t('latest_tweets')} @{homeTeamTwitter}</H3>
          </div>
          <TwitterTimelineEmbed
            key={homeTeamTwitter}
            sourceType="profile"
            screenName={homeTeamTwitter}
            noHeader
            noFooter
            options={{ height: 500 }}
          /></div>
        : <div></div>

      }


      {awayTeamTwitter.length > 0 ?
        <div className="twitter-box">
          <div className="subheader">
            <H3>{t('latest_tweets')} @{awayTeamTwitter}</H3>
          </div>
          <TwitterTimelineEmbed
            key={awayTeamTwitter}
            sourceType="profile"
            screenName={awayTeamTwitter}
            noHeader
            noFooter
            options={{ height: 500 }}
          /></div> : <div></div>
      }

    </Wrapper>
  );
}

export default MatchInfo;
