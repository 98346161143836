import React, { useState } from "react";
import { useParams, useLocation } from 'react-router-dom';
import { useValidateCoupon } from '../rounds/usePurchase';
import NavBar from '../navbar';
import CouponForm from "./components/couponform";
import {NoContentWrapper, Wrapper} from "./styles";
import SelectedRound from "./components/selectedfixture/selectedfixture";
import useDetailRound from '../rounds/useDetailRound';
import useInterval from 'react-useinterval';
import { RoundInformation } from "./components/roundinformation";
import Progress from "../common/progress";
import { isFixtureAfterGameStart } from "../common/DateTimeHelper";
import {GreyedOutFill, H1, LargeArrowForward} from "../reusable-components";
import { useTranslation } from "react-i18next";
import MediaQuery, {useMediaQuery} from "react-responsive/src";
import { getRoundPlate } from "../common/RoundHelper";
import useDetailedFixture from "../fixtures/useDetailedFixture";

const ContentArea = ({ fixture }) => {
  const fixtureId = fixture ? fixture.id : '';
  const { fixtureDetails } = useDetailedFixture(fixtureId);

  if (fixtureDetails) {
    const isAfterGameStart = isFixtureAfterGameStart(fixture);
    return <SelectedRound fixtureDetails={fixtureDetails} isAfterGameStart={isAfterGameStart} roundFixture={fixture}/>;
  }
  return (
      <NoContentWrapper>
        <Progress />
      </NoContentWrapper>
  )
}

const Round = ({  
  round,
  validateCoupon,  
  proPickSuggestion
}) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });
  const initialFixtureIndexSelected = isSmallScreen ? -1 : 0;

  const [fixtureValue, onChangeFixture] = useState(initialFixtureIndexSelected);
  const [showRoundInfo, setShowRoundInfo] = useState(false);
  const { t } = useTranslation();
  const fixture = fixtureValue === undefined ? undefined : round.fixtures[fixtureValue];

  const onChangeFixtureAndClose = (params) => {
    onChangeFixture(params);
    setShowRoundInfo(false);
  }

  const leftFlexContent = () => {

    const pausedScreenWidth = isSmallScreen ? '100%': '402px';

    const pausedScreen = (
      <GreyedOutFill theme={{width: pausedScreenWidth, height: '100%'}}>
        <div style={{height: '100%', marginTop: '210px'}}>
          <h1 style={{color: 'white', fontWeight: '400', textAlign: 'center'}}>{t('round_paused_explanation')}</h1>
        </div>
      </GreyedOutFill>
    );

    return (
        <>
          {round.status === 'PAUSED' ? pausedScreen : <div/>}
          {getRoundPlate(round,t)}
          <CouponForm
              fixtureValue={fixtureValue}
              onChangeFixture={onChangeFixtureAndClose}
              round={round}
              validateCoupon={validateCoupon}
              setShowRoundInfo={setShowRoundInfo}
              showRoundInfo={showRoundInfo}
              proPickSuggestion={proPickSuggestion}
          />
      </>
    );
  }

  return (
    <div className="flex-div">
      <div className="left-flex">
        {leftFlexContent()}
      </div>
      {showRoundInfo ? (
        <MediaQuery minWidth={1001}>
          <div className="right-flex">
            <div className="top-div-icon">
              <div className="icon" onClick={() => setShowRoundInfo(false)}>
                {LargeArrowForward}
              </div>
              <H1 size={42}>{t('round_information')}</H1>
              <span />
            </div>
            <RoundInformation round={round} />
          </div>
        </MediaQuery>
      ) : (
        <div className="right-flex">
          <ContentArea fixture={fixture} />
        </div>
      )}
    </div>
  );
}

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

const RoutedRound = () => {
  const { roundId } = useParams();
  const { status, error, round, reloadDetailRound } = useDetailRound(roundId);
  const validateCoupon = useValidateCoupon();
  let queryParams = useQueryParams();

  const dif=queryParams.get("dif");
  const numFull=queryParams.get("numFull");
  const numHalf=queryParams.get("numHalf");

  const proPickSuggestion = { 
    dif:dif,
    numFull:parseInt(numFull,10),
    numHalf:parseInt(numHalf,10)
  }

  useInterval(() => {
    reloadDetailRound();
  }, 20000);

  if (status === "loading") {
    return (
      <>
        <NavBar />
        <NoContentWrapper>
          <Progress />
        </NoContentWrapper>
      </>
    );
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  if (!round) {
    return null;
  } else {
    return (
        <>
          <NavBar />
          <Wrapper>
            <Round round={round} validateCoupon={validateCoupon} proPickSuggestion={proPickSuggestion}/>
          </Wrapper>
        </>
    );
  }
}

export default RoutedRound;
