import { usePlayer } from "../player/playerContext";
import { useQuery, useQueryCache } from "react-query";
import fetchApi from "../backend/fetchApi";

const useShallowRounds = () => {
    const [player] = usePlayer();

    const queryCache = useQueryCache();
    let roundsUrl = player ? player._links.rounds.href : '';
    const key = 'shallowRounds';
    let rounds = useQuery(key, () => {
        return fetchApi(roundsUrl + '?shallow=true').then(response => response.json()).then((responseJson) => {
            return responseJson._embedded.rounds.filter((() => {
                return true;
            }));
        })
    }, {
        initialStale: true,
        enabled: !!player, // if the player is not available yet, the query won't execute
    })

    return [rounds.data, {
        ...rounds,
        reloadRounds: () => queryCache.invalidateQueries('shallowRounds'),
    }]
}

export default useShallowRounds;