import {usePlayer} from "../player/playerContext";
import fetchApi from "../backend/fetchApi";

export const useSetNicknameOfPlayer = () => {
  const [player] = usePlayer();

  return (nickname: string) => {
    return fetchApi(player._links.nickname.href, {
      method: 'POST',
      body: JSON.stringify({nickname}),
    }).then(response => response.json());
  }
}

export const useCreateSyndicateTeam = () => {
  const [player] = usePlayer();

  return (teamName: string) => {
    return fetchApi(player._links.syndicateTeams.href, {
      method: 'POST',
      body: JSON.stringify({teamName}),
    }).then(response => response.json());
  }
}


