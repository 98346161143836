import React from 'react';
import NavBar from '../navbar';
import image from '../images/footballfield.jpg';
import { AgentAdminWrapper } from './styles';
import { BackgroundImage, H2 } from '../reusable-components';
import { useTranslation } from "react-i18next";
import AgentList from './agentlist';
import {usePlayer} from "../player/playerContext";

export default function AgentAdmin() {
  const { t } = useTranslation();
    const [ player, config ] = usePlayer();
    if (!config.agentSyndicateBetting) {
        return <div/>;
    }

  return (
    <>
      <NavBar whichToUnderline="5"/>
      <AgentAdminWrapper>
        <BackgroundImage height={250} image={image}>
          <H2 color="#fff">
            {t('bet_with_syndicates')}
          </H2>
        </BackgroundImage>
        <div className="green-div">                 
          <AgentList />
        </div>
      </AgentAdminWrapper>
    </>
  );
}