import styled from 'styled-components';

export const StatBottomBarWrapper = styled.div`
  .green-members {
    background: var(--green-tint-background);
    margin-top: 36px;
    padding: 16px 25px 18px 19px;
  }

  .green-members, .green-under-div {
    font-size: 15px;
    width: 594px;
  }

  .b {
    border-bottom: 1px solid var(--off-black);
  }

  .bold-span {
    font-weight: bold;
  }

  .under-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-black);
  }

  .text-flex, .under-flex {
    display: flex;
  }

  .text-flex {
    align-items: center;
  }

  .green-top-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .green-under-div {
    background: rgba(213, 228, 218, 0.5);
    padding: 8px 21px 18px 23px;
    justify-content: space-between;
    align-items: center;
  }

  .text-div {
    display: flex;
    flex-direction: column;
  }

  .left-padding {
    padding-left: 9px;
  }
`;