import styled from 'styled-components';

export const PinkWinWrapper = styled.div`
  background: var(--pinkish-white);
  display: flex;
  padding-left: 37px;

  .h1 {
    line-height: 140px;
    margin: 0;
  }

  .title-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid var(--text-black);
    margin: 12px 44px 12px 0;
    padding-right: 37px;
  }

  .title-flex span {
    font-size: 11px;
    font-weight: bold;
  }

  .right-flex {
    display: flex;
    align-items: center;
    text-align: center;
  }

  .your-win {
    font-size: 11px;
    margin-bottom: 7px;
    display: block;
    white-space: nowrap;
  }
  
  @media screen and (max-width: 1000px) {
    .your-win-wrapper {
      margin-right: 18px;
    }
  }

  .margin-left {
    margin-left: 6px;
  }
`;