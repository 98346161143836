import styled from 'styled-components';

export const BetFieldWrapper = styled.div`
  .field {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px var(--off-black) solid;    
  }

  .bg {
    background: var(--pinkish-white);
  }

  .overline {    
  }

  .pixels-top {
    margin: 0 10px 3px 0;
  }
  
  @media screen and (max-width: 1000px) {
    .pixels-top {
      display: none !important;
    }
  }
`;