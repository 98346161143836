import React, { useState } from 'react';
import { Wrapper } from './selectedfixture_styles';
import useDetailedFixture from "../../../fixtures/useDetailedFixture";
import MatchTable from './components/matchtable';
import MatchInfo from './components/matchinfo';
import MatchStats from './components/matchstats';
import { useTranslation } from "react-i18next";
import Progress from '../../../common/progress';
import MatchSummary from './components/matchsummary';
import {useMediaQuery} from "react-responsive/src";

const SelectedRound = ({ fixtureDetails, isAfterGameStart, roundFixture }) => { // Replace extracted param for fixture id

  const [menuIndex, setMenuIndex] = useState(isAfterGameStart?3:0);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });
  const { t } = useTranslation();

  if (!fixtureDetails) {
    return (
        <Wrapper>
          <div className="green-div" style={{height: '100%'}}>
            <Progress isSmallScreen={isSmallScreen} />
          </div>
        </Wrapper>

    );
  }

  const { fixture } = fixtureDetails;

  let ComponentToRender;

  switch (menuIndex) {
    case 0:
      ComponentToRender = MatchInfo;
      break;
    case 1:
      ComponentToRender = MatchStats;
      break;
    case 2:
      ComponentToRender = MatchTable;
      break;
    case 3:
      ComponentToRender = MatchSummary;
      break;
    default:
      ComponentToRender = MatchInfo;
      break;
  }

  const renderScoreAfterFullTime = () => {
    if ((fixture.homeTeam.score !== fixture.homeTeam.eventBasedScore) ||
        (fixture.awayTeam.score !== fixture.awayTeam.eventBasedScore)) {

      if (fixture.homeTeam.eventBasedScore !== -1 && fixture.awayTeam.eventBasedScore !== -1) {
        return (
          <div style={{textAlign: 'center'}}>
            <div style={{fontSize: '12px', marginBottom: '4px'}}>{t('after_extra_time')}</div>
            <div style={{fontSize: '14px'}}>{fixture.homeTeam.eventBasedScore} - {fixture.awayTeam.eventBasedScore}</div>
          </div>
        );
      }
    }

    return <></>;
  }

  return (
    <Wrapper>
      <div className="green-div">
        <div className="title-flex">
          <div className="team-flex">
            <div className="logo-image-wrapper">
              {fixture.homeTeam.logoUrl ? <img className="logo-image" src={fixture.homeTeam.logoUrl} alt={'logo'} /> :
                <img className="logo-image" src={"https://cdn.sportmonks.com/images/soccer/team_placeholder.png"} alt={'logo'} />}
            </div>
            <div className={'team-flex-team-name'}>{fixture.homeTeam.name}</div>
          </div>
          <div className="team-mid">
            <span className="vs">{fixture.homeTeam.score} - {fixture.awayTeam.score}</span>
            {renderScoreAfterFullTime()}
          </div>
          <div className="team-flex">
            <div className="logo-image-wrapper">
              {fixture.awayTeam.logoUrl ? <img className="logo-image" src={fixture.awayTeam.logoUrl} alt={'logo'} /> :
                <img className="logo-image" src={"https://cdn.sportmonks.com/images/soccer/team_placeholder.png"} alt={'logo'} />}
            </div>
            <div className={'team-flex-team-name'}>{fixture.awayTeam.name}</div>
          </div>
        </div>
      </div>
      <div>
        <div className={'menu'}>
          <div className={'menu-item'} onClick={() => setMenuIndex(0)}>
            <span className={menuIndex === 0 ? "underline-menu" : ""}>{t('game_info')}</span>
          </div>
          <div className={'menu-item'} onClick={() => setMenuIndex(1)}>
            <span className={menuIndex === 1 ? "underline-menu" : ""}>{t('statistics')}</span>
          </div>
          <div className={'menu-item'} onClick={() => setMenuIndex(2)}>
            <span className={menuIndex === 2 ? "underline-menu" : ""}>{t('table')}</span>
          </div>
          <div className={'menu-item'} onClick={() => setMenuIndex(3)}>
            <span className={menuIndex === 3 ? "underline-menu" : ""}>{t('game_summary')}</span>
          </div>
        </div>
      </div>
      <div className="fixture-component">
        <ComponentToRender fixture={roundFixture} fixtureDetails={fixtureDetails} />
      </div>
    </Wrapper>
  );
}

export default SelectedRound;
