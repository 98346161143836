import styled from 'styled-components';
import React from "react";

export const BlackBar = styled.header`
  background: var(--always-black);
  display: flex;

  padding-left: 0;

  @media screen and (max-width: 1000px) {
    align-items: flex-start;
    height: 75px;
  }

  @media screen and (min-width: 1001px) {
    align-items: center;
    height: 40px;
  }

  nav {
    justify-content: center;
    width: 100%;
    display: flex;
  }

  navhelp {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 250px;
    height: 40px;
  }

  #mobile-menu {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 8px;
    height: 45px;
  }

  #mobile-menu #content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px 0 24px;
  }

  .menu-options {
    padding: 0px 0px 0px 0px;
  }

  .mobile-menu-item {
    width: 72px;
    height: 50px;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: var(--always-white);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  .mobile-menu-icon {
    width: 50px;
    padding: 0 0 8px 0;
    color: var(--always-white);
  }

  #icon-home {
    width: 32px;
    height: 32px;
  }

  #icon-poolx svg {
    height: 16px;
  }

  #icon-coupon {
    padding: 0 0 4px 0;
  !important;
  }

  #icon-results {
    height: 30px;
    width: 30px;

  }

  ol {
    display: flex;
    list-style: none;
    justify-content: space-between;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
  }

  li {
    margin: 0 20px;
  }

  .link {
    color: var(--always-white);
    text-decoration: none;
  }

  .underline {
    text-decoration: underline;
    color: var(--always-white);
  }
`;