import {usePlayer} from "../player/playerContext";
import {useQuery, useQueryCache} from "react-query";
import fetchApi from "../backend/fetchApi";

const useAgents = () => {
  const [player, config] = usePlayer();

  const queryCache = useQueryCache();
  const agentsUrl = player && player._links.agents && player._links.agents.href;
  const {status,error,data} = useQuery(['agents', agentsUrl], () => {

    if (!config.agentSyndicateBetting) {
      // feature is inactive, prevent HTTP request and return placeholders

      return new Promise(resolve => []);
    }

    return fetchApi(agentsUrl).then(response => response.json()).then(r => r._embedded.agents)
  }, {
    enabled: !!player,
    initialData: player && player._embedded.agents,
    initialStale: false,
  })

  const reloadAgents = () => queryCache.invalidateQueries('agents');

  let agents=data;
  return [agents,{status,error,reloadAgents}];
}

export default useAgents;