import React from 'react';
import { TeamWrapper } from './styles';
import { Link } from 'react-router-dom';
import { ArrowForwardIos } from "@material-ui/icons";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import {formatStringCurrency} from "../../common/CurrencyFormatter";

const AgentSyndicateDiv = ({
  agent,
  syndicate,
  round
}) => {

  const { t } = useTranslation();
  const { targetNShares, stakePerShare } = syndicate || {};
  const { amount, currency } = stakePerShare || {};
  const total = targetNShares * amount;
  const { openTo } = round || {};
  const openToDate = new Date(openTo);

  let locale=t('locale');

  return (
    <TeamWrapper>
      <div className="top-div">
        <span className="bold-span">{(round && round.name)}</span>
        <span className="bold-span"><Moment format={"yyyy-MM-DD"} locale={locale}>{openToDate}</Moment></span>

        <Link className="bold-span link-div" to={'/agents/' + agent.id + '/syndicates/' + syndicate.id}>{t('to_syndicate')}<ArrowForwardIos className="icon" /></Link>
      </div>

      <div className="bottom-div">
        <div className="column">
          <span className="bold-span">{syndicate.name}</span>
          <span className="bold-span">{syndicate.status}</span>
          <span className="total-span">{t('total_cost')} {formatStringCurrency(total, currency)}</span>
        </div>
        <span className="numb-members">{t('shares')}: ({syndicate.nShares}/{syndicate.targetNShares})</span>
        <span className="bold-span">{t('share_price')}: {formatStringCurrency(amount, currency)}</span>
      </div>

    </TeamWrapper>
  );
}

export default AgentSyndicateDiv;