import React from 'react';
import { FixtureWrapper } from './styles';
import {fixtureTime} from "../../../../../common/DateTimeHelper";
import {useTranslation} from "react-i18next";

const formatFraction = v => `${(parseFloat(v) * 100).toFixed(0)}%`;

export const getDrawText = (draw, t)=>{
  return t('is_draw') + " " + formatFraction(draw.outcomes[0].probability) + " " +
   formatFraction(draw.outcomes[1].probability) + " " + formatFraction(draw.outcomes[2].probability)
}

const FixtureCard = ({ index, fixture, selected, onClick }) => {
  const displayDrawText = (fixture) => {
    const draw = fixture.draw;
    return draw && (draw.status === 'PUBLISHED' || draw.status === 'FINISHED');
  }
  const { t } = useTranslation();  
  const draw = displayDrawText(fixture) && <span className="draw-terms-text">{getDrawText(fixture.draw,t)}</span>;
  return (
    <FixtureWrapper onClick={onClick} selected={selected}>
      <div className={index===0 ? "text-div-overline" : "text-div"}>
        <div>
          <div className="top-div">
            <span className="m-right">{index + 1}</span>
            <span className="line-break">
              {fixture.homeTeam.name} - {fixture.awayTeam.name}
            </span>
          </div>
          <span>
            {(draw ? draw : fixtureTime(fixture,t))}
          </span>
        </div>
        <span className="small-font">{t('row_distribution')}</span>        
      </div>
      
    </FixtureWrapper>
  );
}

export default FixtureCard;