import React, {useEffect} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation
} from "react-router-dom";
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import CouponUpload from "./rounds/CouponUpload";
import ApiInfo from "./player/ApiInfo";
import {AuthProvider} from "./auth/authContext";
import {QueryCache, ReactQueryCacheProvider} from 'react-query';
import {ApiProvider} from "./api/apiContext";
import {PlayerProvider} from "./player/playerContext";
import {SnackbarProvider} from 'notistack';
import AvailableSyndicates from "./syndicates/AvailableSyndicates";
import LandingPage from './landing/landing';
import PlaySolo from './playsolo';
import RoutedRound from './routedround';
import PlayPoolarna from './playpoolarna';
import RoutedTeam from './routedteam';
import NewSyndicateTeam from "./playpoolarna/components/newsyndicateteam";
import SelectNickname from "./playpoolarna/components/selectnickname";
import SettledRound from './couponview';
import PlayAgent from './syndicates';
import RoundHistory from './roundhistory/roundhistory';
import "./translations/i18n";
import i18n from "i18next";
import Syndicate from './syndicates/syndicate';
import AgentAdmin from './agents';
import AgentDetail from './agents/agentdetail';
import UpdateSyndicateForm from './agents/updatesyndicateform';
import AdvancedCouponView from './advancedcouponview';
import CouponShareList from './couponsharelist';
import Help from './help/help';


const initTheme = (darkMode) => {

    if (darkMode) {
        import("./theme/dark.css");
        return;
    }

    import("./theme/standard.css");
}


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#2196F3',
        },
        secondary: {
            main: '#FFC107',
        },
    },
});

const OPERATOR_MESSAGE_SCROLL_TO_TOP = 'scroll_to_top';

export const operatorAdviceScrollToTop = () => {
    // advise operator that it is desirable to scroll the page to the top
    console.log('advising operator scroll to top');

    window.parent.postMessage(OPERATOR_MESSAGE_SCROLL_TO_TOP, '*');
    setTimeout(() => {
        window.parent.postMessage(OPERATOR_MESSAGE_SCROLL_TO_TOP, '*');
    }, 200)

}

const handleRouteChange = () => {
    operatorAdviceScrollToTop();
}

// improvised component to hook into router context
const RouteSwitchListener = () => {
    let location = useLocation();
    useEffect(() => {
        handleRouteChange(location);
    }, [location])

    return <div/>
}

const MOCKOP_OPERATOR_ID = 'MOCKOP';
const PARIPLAY_OPERATOR_ID = 'PARIPLAY';

function toInternalLanguageCode(langCodeIso) {
    if (langCodeIso === 'en-US' || langCodeIso === 'EN') {
        return 'en';
    }

    if (langCodeIso === 'sv-SE' || langCodeIso === 'SV') {
        return 'sv';
    }

    if (langCodeIso === 'fa-IR' || langCodeIso === 'FA') {
        return 'fa';
    }

    // fa and sv language codes already work
    return langCodeIso;
}

function App() {
    const urlParams = new URLSearchParams(window.location.search);

    // Used by mock operator frame
    const lang = urlParams.get('lang');
    if (lang) {
        i18n.changeLanguage(lang)
    }

    // Used by pariplay
    const languageCodeIso = urlParams.get("languageCode")
    if (languageCodeIso) {
        const code = toInternalLanguageCode(languageCodeIso);
        i18n.changeLanguage(code);
    }

    // Used by others
    const language = urlParams.get("language")
    if (language) {
        i18n.changeLanguage(language);
    }

    // Fallback to english
    if (!lang && !languageCodeIso && !language) {
        i18n.changeLanguage('en');
    }

    let operatorId
    let operatorToken
    let operatorGameId
    let operatorPlayerId
    let operatorCurrency
    let operatorLanguage
    let operatorChannel
    let operatorJurisdiction
    let operatorExtraData
    let homeUrl

    const host = window.location.host;
    const subdomain = host.split('.')[0]
    if (subdomain === 'pariplay') {
        operatorId = PARIPLAY_OPERATOR_ID;
        operatorToken = urlParams.get("token");
        operatorPlayerId = urlParams.get("operatorPlayerId");
        operatorCurrency = urlParams.get('currencyCode');
        homeUrl = urlParams.get('homeUrl');
        operatorExtraData = urlParams.get("extraData");
    } else {
        operatorId = urlParams.get("operatorId");
        let compatMode = operatorId && operatorId === MOCKOP_OPERATOR_ID;
        if (compatMode) {
            operatorId = urlParams.get("operatorId");
            operatorToken = urlParams.get("operatorToken");
            operatorPlayerId = urlParams.get("operatorPlayerId");
            homeUrl = urlParams.get('homeUrl');
            operatorExtraData = urlParams.get("extraData");
        } else {
            operatorId = urlParams.get("operatorid");
            operatorToken = urlParams.get("token");
            operatorGameId = urlParams.get("gameid");
            operatorPlayerId = urlParams.get("playerid");
            operatorCurrency = urlParams.get("currency");
            operatorLanguage = urlParams.get("language");
            operatorChannel = urlParams.get("channel");
            operatorJurisdiction = urlParams.get("jurisdiction");
        }
    }

    if (!operatorGameId) {
        operatorGameId = 'poolx';
    }
    if (!operatorPlayerId) {
        operatorPlayerId = '';
    }
    if (!operatorCurrency) {
        operatorCurrency = '';
    }
    if (!operatorLanguage) {
        operatorLanguage = '';
    }
    if (!operatorChannel) {
        operatorChannel = 'online';
    }
    if (!operatorJurisdiction) {
        operatorJurisdiction = '';
    }
    if (!operatorExtraData) {
        operatorExtraData = '';
    }
    if (!homeUrl) {
        homeUrl = '';
    }

    const pTheme = urlParams.get('theme');
    const darkMode = pTheme === 'dark';
    initTheme(darkMode);

    return (
        <ThemeProvider theme={theme}>
            <ReactQueryCacheProvider queryCache={new QueryCache()}>
                <SnackbarProvider maxSnack={3}>
                    <AuthProvider
                        operatorId={operatorId}
                        operatorGameId={operatorGameId}
                        operatorPlayerId={operatorPlayerId}
                        operatorChannel={operatorChannel}
                        operatorLanguage={operatorLanguage}
                        operatorToken={operatorToken}
                        operatorCurrency={operatorCurrency}
                        operatorJurisdiction={operatorJurisdiction}
                        operatorExtraData={operatorExtraData}
                        homeUrl={homeUrl}
                    >
                        <ApiProvider>
                            <PlayerProvider>
                                <div className="wrapper">
                                    <Router>
                                        <RouteSwitchListener/>
                                        <Switch>
                                            <Route path={"/agents/:agentId/syndicates/:syndicateId"}>
                                                <UpdateSyndicateForm/>
                                            </Route>
                                            <Route path={"/syndicates"}>
                                                <AvailableSyndicates/>
                                            </Route>
                                            <Route path={"/agents/:agentId"}>
                                                <AgentDetail/>
                                            </Route>
                                            <Route path={"/agentadmin"}>
                                                <AgentAdmin/>
                                            </Route>
                                            <Route path="/:tipsId/rounds/:roundId">
                                                <RoutedRound/>
                                            </Route>
                                            <Route path="/rounds/:roundId">
                                                <RoutedRound/>
                                            </Route>
                                            <Route path="/team/:teamId">
                                                <RoutedTeam/>
                                            </Route>
                                            <Route path="/teams">
                                                <PlayPoolarna/>
                                            </Route>
                                            <Route path="/newSyndicateTeam">
                                                <NewSyndicateTeam/>
                                            </Route>
                                            <Route path="/selectNickname">
                                                <SelectNickname/>
                                            </Route>
                                            <Route path="/mixtipset">
                                                <PlaySolo whichTips="mixtipset"/>
                                            </Route>
                                            <Route path="/maxtipset">
                                                <PlaySolo whichTips="maxtipset"/>
                                            </Route>
                                            <Route path="/flextipset">
                                                <PlaySolo whichTips="flextipset"/>
                                            </Route>
                                            <Route path="/mycoupons">
                                                <CouponShareList/>
                                            </Route>
                                            <Route path="/roundhistory">
                                                <RoundHistory/>
                                            </Route>
                                            <Route path="/coupon/:couponId">
                                                <SettledRound/>
                                            </Route>
                                            <Route path="/playagent">
                                                <PlayAgent/>
                                            </Route>
                                            <Route path="/advancedCouponView/:couponShareId">
                                                <AdvancedCouponView/>
                                            </Route>
                                            <Route path="/syndicate/:syndicateId">
                                                <Syndicate/>
                                            </Route>
                                            <Route path="/help">
                                                <Help/>
                                            </Route>
                                            <Route path="/myrounds">
                                                <CouponUpload/>
                                                <ApiInfo/>
                                            </Route>
                                            <Route path={"/"}>
                                                <LandingPage/>
                                            </Route>
                                        </Switch>
                                    </Router>
                                </div>
                            </PlayerProvider>
                        </ApiProvider>
                    </AuthProvider>
                </SnackbarProvider>
            </ReactQueryCacheProvider>
        </ThemeProvider>
    );
}

export default App;
