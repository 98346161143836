import { useQuery, useQueryCache } from "react-query";
import fetchApi from "../backend/fetchApi";

const useDetailedFixture = (fixtureId) => {
    const fixtureUrl = `${process.env["REACT_APP_API_URL"]}/fixtures/${fixtureId}`;
    const {status,error,data} = useQuery(['detailedFixture', fixtureUrl], () => {
        if(!fixtureId){
            return null;
        }
        return fetchApi(fixtureUrl).then(response => response.json()).then(r => r._embedded.fixture)
    }, {

    })

    const queryCache = useQueryCache();
    const reloadDetailedFixture = () => queryCache.invalidateQueries(['detailedFixture', fixtureUrl]);

    return {status, error, fixtureDetails: data, reloadDetailedFixture};
}

export default useDetailedFixture;