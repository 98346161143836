import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--off-white);

  .green-div {
    background: var(--green-tint-background);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 243px;
    padding: 0 16px;
  }

  .green-div p {
    font-size: 11px;
    color: #060607;
  }

  .title-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    max-width: 600px;
  }

  .logo-image-wrapper {
    display: flex;
  }

  .team-flex {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
    flex-wrap: nowrap;
    white-space: pre;
    font-weight: bold;
  }

  .menu-item {
    text-align: center;
    padding: 12px 10px;
    cursor: pointer;
  }

  .menu {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  @media screen and (min-width: 601px) {
    .logo-image-wrapper {
      height: 110px;
      width: 110px;
    }

    .menu {
      font-size: 13px;
    }

    .team-mid {
      height: 130px;
    }

    .team-flex {
      width: 44%;
    }

    .vs {
      font-size: 34px;
    }
  }

  @media screen and (max-width: 600px) {
    .team-flex {
      width: 29%;
    }

    .logo-image-wrapper {
      height: 55px;
      width: 55px;
    }

    .menu {
      font-size: 12px;
    }

    .team-mid {
      height: 90px;
    }

    .team-flex-team-name {
      max-width: 100%;
      text-overflow: ellipsis;
      text-align: center;
      overflow: auto;
    }

    .vs {
      font-size: 30px;
    }
  }

  .team-mid {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70px;
    justify-content: center;
  }

  .vs {
    white-space: nowrap;
    margin: 0px 0px 20px 0px;
  }

  .logo-image {
    padding: 0px 0px 10px 0px;
    display: block;
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  .fixture-component {
    overflow-y: auto;
  }

  ul {
    display: flex;
    justify-content: center;
    padding: 0 64px;
    list-style: none;
    font-size: 13px;
  }

  ul li {
    cursor: pointer;
    padding: 0 20px;
  }

  .underline-menu {
    text-decoration: underline;
  }

  li:hover {
    text-decoration: underline;
  }
`;