import React from 'react';
import { CouponInfoWrapper } from './styles.js';
import { TicketIcon } from '../../../reusable-components.js';
import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive/src";
import {formatStringCurrency} from "../../../common/CurrencyFormatter";

export default function CouponInfo({ round, couponShare }) {
  const { t } = useTranslation();
  
  if (!couponShare || !round) {
    return <></>;
  }

  const { stake, coupon, createdAt} = couponShare || {};
  const { operatorStakePerRow, name, openTo } = round || {};
  const { totalRows = 0 } = coupon || {};
  const { amount: perRowAmount = 0 } = operatorStakePerRow || {};
  const { amount: stakeAmount = 0 } = stake || {};  

  const downloadTxtFile = (coupon) => {
    
    let outString = "";
    coupon.coupon.bets.forEach(bet => {
      bet.rows.forEach(row => {        
        row.signs.forEach(sign => {
          let text="1";
          if(sign==="TWO"){
            text="2";
          }
          if(sign==="X"){
            text="X";
          }
        outString += text + ',';
        });        
        outString = outString.substring(0, outString.length - 1);
        outString += '\r\n';
      });      
    });    
    const element = document.createElement("a");
    const file = new Blob([outString], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = 'poolx_rows_' + coupon.id + '.txt';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  const nf = Intl.NumberFormat('sv-SV', { style: 'currency', currency: 'SEK', minimumFractionDigits: 0, maximumFractionDigits: 1 });
  const intlOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const datef = Intl.DateTimeFormat('sv-SV', intlOptions);
  const couponBets = coupon.coupon.bets;
  
  let betType = t('single_rows');
  let reducedSystemName = "";
  if(couponBets.length===1 && couponBets[0].system!=null){
    let name = couponBets[0].system.type;
    if(name === "MATHEMATICAL"){
      betType = t('mathematical_system')
    }
    if(name === "REDUCED"){
      betType = t('reduced_system')
    }    
    reducedSystemName=couponBets[0].system.reducedSystemId;
  }

  const share = Math.round(couponShare.stakeUnits/couponShare.totalStakeUnits*100);

  return (
    <CouponInfoWrapper>
      <div className="orange-div">
        {TicketIcon}
        <span className="margin-top">{totalRows} {t('rows')}, {formatStringCurrency(perRowAmount, operatorStakePerRow.currency)}/{t('row')}</span>
        <div className="flex-div b-top">
          <span>{t('price_per_row')}: {formatStringCurrency(perRowAmount, operatorStakePerRow.currency)}</span>
          <span>{t('paid')}: {datef.format(new Date(createdAt))}</span>
        </div>
        <div className="flex-div">
          <span>{t('total_cost')}: {formatStringCurrency(stakeAmount, stake.currency)}</span>
          <span>{t('started')}: {datef.format(new Date(openTo))}</span>
        </div>
        <div className="flex-div">
          <span>{t('round')}: {name}</span>
        </div>
        {(share<100 && <div className="flex-div">
          <span>{t('share_of_syndicate')}: {share}%</span>          
        </div>)}
        <MediaQuery minWidth={1000}>
          <div className="button-div">
            <button onClick={()=>downloadTxtFile(coupon)}>
              {t('print')}
            </button>
          </div>
        </MediaQuery>
      </div>
    </CouponInfoWrapper>
  );
}