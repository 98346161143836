import { includes, union, without } from "lodash";
import React from "react";
import SignBox from "../../../../../coupons/signbox";
import { Field } from "react-final-form";
import {useTranslation} from "react-i18next";
import signs from "../../../../../coupons/signs";
import {FinishedDrawText, FinishedDrawTextWrapper, LegFieldWrapper, SignsFieldWrapper} from './styles';
import { SettledSignBoxWrapper } from "../../../../../reusable-components";
import { isFixtureAfterGameStart } from "../../../../../common/DateTimeHelper";

const formatFraction = v => `${(parseFloat(v) * 100).toFixed(0)}%`;

const SignsField = ({ fixture, round, index, input: { onChange, value } }) => {
    const {t} = useTranslation();
    const isAfterGameStart = isFixtureAfterGameStart(fixture);

  const LegOutcome = ({ sign, label, round, index }) => {
    const fixtureSign = fixture.signs[sign];
    const percentage = fixtureSign ? formatFraction(fixtureSign.rowsFraction) : '33%';
    let afterDeadline = false;
    let isCorrect = false;
    if(round && index>-1){
      var openTo = new Date(round.openTo);
      afterDeadline = openTo < Date.now();   
      if(round.preliminaryCorrectRow && round.preliminaryCorrectRow[index]===sign){
        isCorrect=true;
      }
    }

    if (afterDeadline) {
      return (
          <SignsFieldWrapper className={'signs-field-wrapper'}>
              <div className="margin-left">
                  <SettledSignBoxWrapper
                      greenBorder={isCorrect}
                      className={isCorrect ? 'green-box' : ''}
                      value={includes(value, sign)}
                      onChange={selected => {
                          onChange(selected ? union(value, [sign]) : without(value, sign))
                      }} >{label}</SettledSignBoxWrapper>

                  <span className={`percentage-span ${isCorrect ? 'green-span' : ''}`}>
                      {percentage}
                  </span>
              </div>
          </SignsFieldWrapper>
      );
    }

    return (
        <SignsFieldWrapper className={'signs-field-wrapper'}>
          <div className="margin-left">
            <SignBox sign={label} value={includes(value, sign)} onChange={selected => {
              onChange(selected ? union(value, [sign]) : without(value, sign))
            }} />
            <span className="percentage-span">
              {percentage}
            </span>
          </div>
        </SignsFieldWrapper>
    );
  }

  const { draw } = fixture;
  const fixtureIsDrawn = draw !== null && draw.status === 'FINISHED';

  return (
    <LegFieldWrapper>
      {((isAfterGameStart || fixtureIsDrawn) && renderScore(fixture, t, fixtureIsDrawn))}
      <div className="signs-div">
        {Object.entries(signs).map(([sign, label]) => <LegOutcome round={round} key={sign} sign={sign} label={label} index={index} />)}
      </div>
    </LegFieldWrapper>
  );
}

const renderScore = (fixture, t, fixtureIsDrawn) => {

    if (fixtureIsDrawn) {
        return (
            <FinishedDrawTextWrapper>
                <FinishedDrawText>{t('is_drawn')}</FinishedDrawText>
            </FinishedDrawTextWrapper>
        );
    }

    return <div className="black-div">{fixture.homeTeam.score}-{fixture.awayTeam.score}</div>;
}

const LegField = ({ fixture, round, index, input: { name } }) => {
    return (

            <Field
                round={round}
                name={`${name}.signs`}
                fixture={fixture}
                index={index}
                component={SignsField} />

    );

}

export default LegField;