import React, { useEffect, createContext, useState, useContext, useMemo } from 'react';
import fetchApi from "../backend/fetchApi";

const AuthContext = createContext({ authenticated: false })

const AuthProvider = ({ operatorId,
                        operatorGameId,
                        operatorPlayerId,
                        operatorChannel,
                        operatorLanguage,
                        operatorToken,
                        operatorCurrency,
                        operatorJurisdiction,
                        operatorExtraData,
                        homeUrl,
                        ...props }) => {
  const [authenticated, setAuthenticated] = useState(false)

  useEffect(() => {
    let searchParams = new URLSearchParams();
    searchParams.append("operatorId", operatorId);
    searchParams.append("operatorChannel", operatorChannel);

    sessionStorage.setItem("operatorId", operatorId);

    if (operatorPlayerId && operatorPlayerId !== '') {
      const opKey = "operatorPlayerId";
      searchParams.append(opKey, operatorPlayerId);
      sessionStorage.setItem(opKey, operatorPlayerId);
    }

    if (operatorGameId && operatorGameId !== '') {
      const ogKey = "operatorGameId";
      searchParams.append(ogKey, operatorGameId)
      sessionStorage.setItem(ogKey, operatorGameId);
    }

    if (operatorCurrency && operatorCurrency !== '') {
      const ocKey = "operatorCurrency";
      searchParams.append(ocKey, operatorCurrency)
      sessionStorage.setItem(ocKey, operatorCurrency);
    }

    if (operatorLanguage && operatorLanguage !== '') {
      const olKey = "operatorLanguage";
      searchParams.append(olKey, operatorLanguage)
      sessionStorage.setItem(olKey, operatorLanguage);
    }

    if (operatorJurisdiction && operatorJurisdiction !== '') {
      const ojKey = "operatorJurisdiction";
      searchParams.append(ojKey, operatorJurisdiction)
      sessionStorage.setItem(ojKey, operatorJurisdiction);
    }

    if (operatorExtraData && operatorExtraData !== '') {
      const edKey = "operatorExtraData";
      searchParams.append(edKey, operatorExtraData)
      sessionStorage.setItem(edKey, operatorExtraData);
    }

    if (homeUrl && homeUrl !== '') {
      const homeUrlKey = 'homeUrl';
      searchParams.append(homeUrlKey, homeUrl)
      sessionStorage.setItem(homeUrlKey, homeUrl)
    }

    const formData = new FormData();
    formData.append("grant_type", "urn:ietf:params:oauth:grant-type:token-exchange");
    formData.append("subject_token", operatorToken);

    fetchApi("/oauth/token?" + searchParams.toString(), {
      method: 'POST',
      body: formData,
    }).then(response => response.json())
      .then(json => {
        localStorage.setItem(btoa("token" + operatorId), json.access_token);
        setAuthenticated(true);
      })

  }, [operatorId, operatorPlayerId, operatorChannel, operatorToken, homeUrl, operatorCurrency, operatorExtraData, operatorGameId, operatorJurisdiction]);

  const value = useMemo(() => ({
    authenticated
  }), [authenticated])

  return <AuthContext.Provider value={value} {...props} />
}

function useAuth() {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthProvider, useAuth }
