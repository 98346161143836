import React from "react";
import useSyndicates from "./useSyndicates";
import useShallowRounds from "../rounds/useShallowRounds";
import SyndicateList from "./syndicatelist";
import {usePlayer} from "../player/playerContext";

const AvailableSyndicates = () => {
  const [syndicates] = useSyndicates();
  const [shallowRounds = []] = useShallowRounds();
  const [ player, config ] = usePlayer();
  if (!config.agentSyndicateBetting) {
    return <div/>;
  }

  if (!syndicates || ! shallowRounds) return null;
  const filteredRounds = shallowRounds.filter(({ status, openTo }) => status==="ACTIVE" && new Date(openTo)>Date.now());
  const filteredSyndicates = syndicates.filter(({ roundId }) => filteredRounds.filter(({id})=>id===roundId).length>0);
  if (!filteredRounds || !filteredSyndicates) return null;
  

  return <SyndicateList syndicates={filteredSyndicates} shallowRounds={filteredRounds}/>
}

export default AvailableSyndicates;
