import React, { useState } from 'react';
import { Wrapper } from './styles';
import { IkonIcon, Button } from '../../../../../reusable-components';
import { Close } from '@material-ui/icons';
import { useTranslation } from "react-i18next";
import Progress from '../../../../../common/progress';
import MediaQuery, {useMediaQuery} from "react-responsive/src";
import { operatorAdviceScrollToTop } from "../../../../../App";
import {formatStringCurrency} from "../../../../../common/CurrencyFormatter";

const getErrorMessage = (errorCode, phrases) => {
  if (errorCode === 102) {
    return phrases.insufficientFunds;
  }

  return '';
}

const renderSwitch = ({
  submitting,
  shouldRenderConfirm,
  submitFailed,
  toggle,
  amount,
  currency,
  phrases,
  handleClick,
  isSmallScreen,
  submitError
}) => {

  if (submitFailed) {
    const errorMessage = getErrorMessage(submitError.errorCode, phrases);
       return (
      <div>
        <p style={{ marginTop: '30px', textAlign: 'center' }}>
          {phrases.unableToCompletePurchase}, {phrases.errorCode}: {submitError.errorCode}
        </p>
        <p style={{ textAlign: 'center' }}> {errorMessage}</p>
      </div>
    );
  }

  if (submitting) {
    return <Progress isSmallScreen={isSmallScreen} />
  }

  if (shouldRenderConfirm) {
    return (
      <>
        <span>{phrases.paymentDone}</span>
        <Button width={isSmallScreen ? 200 : 350} onClick={() => toggle(false)}>Ok</Button>
      </>
    );
  }

  window.scrollTo(0,0)

  return (
    <>
      <span>{phrases.confirmPurchase} {formatStringCurrency(amount, currency)}?</span>
      <Button type="submit" width={isSmallScreen ? 200 : 350} onClick={handleClick}>{phrases.pay}</Button>
    </>
  );
}

const ConfirmPayment = ({
  amount,
  currency,
  toggle,
  submitting,
  submitFailed,
  resetValues,
  submitError
}) => {
  const [shouldRenderConfirm, setShouldRenderConfirm] = useState(false);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });
  const { t } = useTranslation();
  const phrases = {
    confirmPurchase: t('do_you_want_to_place_bet'),
    pay: t('pay'),
    paymentDone: t('your_bet_is_paid'),
    insufficientFunds: t('insufficient_funds'),
    unableToCompletePurchase: t('unable_to_complete_purchase'),
    errorCode: t('error_code'),
  };

  const handleClose = () => {
    resetValues();
    toggle(false);
  }

  const handleClick = () => {
    setShouldRenderConfirm(true);
    setTimeout(() => operatorAdviceScrollToTop(), 300);
  }

  return (
    <Wrapper>
      <div className="onclick-div" onClick={() => {}} />
      <div className="confirm-window">
        <MediaQuery minWidth={1001}>
          <div className="flex-end">
            <Close className="close-icon" onClick={handleClose} />
          </div>
        </MediaQuery>

        {IkonIcon(32, 30)}
        {renderSwitch({
          submitting,
          shouldRenderConfirm,
          submitFailed,
          toggle,
          amount,
          currency,
          phrases,
          handleClick,
          isSmallScreen,
          submitError
        })}

        <MediaQuery maxWidth={1000}>
          {!shouldRenderConfirm ? (<div className={'cancel-anchor'} onClick={() => handleClose()}>{t('cancel')}</div> ) : <div/>}
        </MediaQuery>

      </div>
    </Wrapper>
  );
}

export default ConfirmPayment;