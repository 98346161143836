import React from "react";
import keyBy from "lodash/keyBy";
import useAgentSyndicates from "./useAgentSyndicates";

const useAgentSyndicate = (agent, id) => {
  const [syndicates] = useAgentSyndicates(agent);

  const syndicatesById = React.useMemo(() => keyBy(syndicates || [], 'id'), [syndicates])

  return [syndicatesById[id]];
}

export default useAgentSyndicate;