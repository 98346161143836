import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;  
  flex-direction: column;  

  .flex-columns{
    background: var(--off-white);
    display: flex;  
    cursor: pointer;
    flex-direction: row;
  }

  @media screen and (min-width: 1001px) {
      .flex-columns-child {
        width: 50%;
      }
  }


  @media screen and (max-width: 1000px) {
    .flex-columns-child {
      width: 100%;
      max-width: 60%;
      overflow: auto;
    }
  }
  
  
  .top-border {
    border-bottom: 1px solid #060707;
    height: 1px;
  }

  .half-width {
    width: 50%;    
  }

  .right-form-div {
    width: 50%;    
  }

  .icon {
    height: 12px;
    width: 12px;
  }

  .min-height {
    height: 568px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .form{
  }
`;