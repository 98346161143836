import PopoverButton from "../common/PopoverButton";
import {getCurrentToken} from "../backend/fetchApi";
import React from "react";
import {usePlayer} from "./playerContext";

const ApiInfo = () => {
  const [player] = usePlayer();

  if (!player) {
    return null;
  }

  return <PopoverButton label={"Api Info"}>
    <div>Token</div>
    <div>{getCurrentToken()}</div>
    <div><a href={process.env["REACT_APP_DOCS_URL"]} target="_blank" rel="noopener noreferrer">Documentation</a> </div>
  </PopoverButton>
}

export default ApiInfo;