
import React from "react";
import { H2 } from "../../reusable-components";
import { useTranslation } from "react-i18next";
import { Wrapper } from "./styles";
import AgentSyndicateDiv from "../agentsyndicatediv";
import NewSyndicateForm from "../newsyndicateform";

const AgentSyndicateList = ({ agent, syndicates, shallowrounds }) => {

  const { t } = useTranslation();

  return (
    <Wrapper>
      <div className="green-div">        
        <NewSyndicateForm agent={agent} shallowrounds={shallowrounds}/>

        <H2 className="title-margin">{t('syndicates')}</H2>        
        {(agent && syndicates && shallowrounds &&
          syndicates.map((syndicate, i) => {
            const round = shallowrounds.filter(r => r.id === syndicate.roundId)[0];
            if(!round || (round && round.status!=="ACTIVE")){
              return <div key={`${i} agentsyndicatediv`} />;
            }
            return (
              <AgentSyndicateDiv key={`${i} agentsyndicatediv`} syndicate={syndicate} round={round} agent={agent} />
            )
          }
          ))
        }
    </div>
    </Wrapper>
  );

}

export default AgentSyndicateList;