import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .wrap {
    width: 400px;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .flex {
    display: flex;
    font-size: 11px;
    color: var(--text-black);
  }

  table {
    width: 400px;
    font-size: 15px;
    border-collapse: collapse;
  }

  .bold-span {
    font-weight: bold;
  }

  .border-div {
    border: solid 1px var(--off-black);
    padding: 3px 6px;
  }

  .top-flex {
    display: flex;
    justify-content: space-between;
    margin: 31px 0 20px 0;
    width: 100%;
    font-size: 15px;
  }

  .green-bg {
    background: var(--green-tint-background);
  }

  .td-width {
    width: 80px;
    padding-right: 6px;
  }

  .bottom-flex {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    font-size: 15px;
    width: 100%;
  }

  tr {
    height: 30px;
  }

  .left-padding {
    padding-left: 9px;
  }

  .icon {
    height: 18px;
    width: 18px;
  }

  .text-div {
    display: flex;
    flex-direction: column;
  }

  .btn {
    margin-top: 14px;
  }

  .white-content-div {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    font-size: 15px;
  }

  .green-span {
    color: var(--accent-green);
    display: flex;
    align-items: center;
    font-weight: bold;
  }
`;
