import styled from 'styled-components';

export const Wrapper = styled.div`
  
  @media screen and (min-width: 601px) {
    #pay-button {
      width: 340px;
    }    
  }

  @media screen and (max-width: 600px) {
    #pay-button {
      width: 90%;
      margin: 0 auto;
      display: block;
    }
  }
  
  @media screen and (max-width: 1000px) {
    #start-date-wrapper {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
  
  .black-text {
    color: var(--always-black) !important;
  }
  
  .round-back-div {    
    display: flex;
    padding: 0 0 0 10px;  
    color: var(--always-black);
  }
  
  .form-margin{    
    margin: 10px 10px 10px 10px;
    padding: 22px 24px 21px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;    
  }

  .form-center{        
    display: flex;
    flex-direction: column;    
  }

  .stats, .explain {
    display: flex;
    flex-direction: column;
  }

  .stats {
    flex: 2;
    padding: 9px 0 5px 0;
  }

  .explain {
    flex: 1;
    border-left: 1px solid #060707;
  }

  .icon-flex {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .clear-bets-anchor {
    text-decoration: underline;
    text-align: center;
    width: 100%;
    display: block;
    cursor: pointer;
    margin-top: 16px;
    font-size: 14px;
  }

  @media screen and (max-width: 600px) {
    .icon-flex {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .icon-flex span {
      font-size: 14px;
      margin-top: 4px;
    }
    
    .explain .stats {
      justify-content: flex-start;
    }
  }

  @media screen and (min-width: 601px) {
    .icon-flex span {
      font-size: 11px;
      margin-left: 10px;
    }
    
    .explain {
      padding-left: 12px;
      margin: 12px 0 12px 22px;
      justify-content: space-evenly;
    }

    .stats {
      justify-content: space-between;
    }
    
    .top-screen {
      position: absolute;
      top: 250px;
    }
  }
  .icon-flex span {
    font-family: akurat, sans-serif;
  }

  .button-margin {        
    margin: 12px 10px 12px 10px;        
  }

  @media screen and (min-width: 1001px) {
    .underline {
      border-bottom: 1px solid #060707;
    }
  }


  @media screen and (max-width: 1000px) {
    #start-date-wrapper {
      border-bottom: 1px solid #060707;
    }
    
    #turnover-wrapper {
      margin-top: 4px;
    }
  }

  .smaller-font {
    font-size: 18px;
  }

  .small-span {
    font-size: 11px;
    margin-bottom: 5px;
  }

  .pink-div {
    background-color: var(--off-white);
    padding: 10px 10px 10px 24px;
  }

  .caption {
    font-weight: bold;
    font-size: 12px;
    margin-top: 5px;
  }

  .sum-ul {
    list-style: none;
    font-size: 11px;
    padding: 0;
    margin-bottom: 19px;
  }

  .sum-ul li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 4px;
  }

  .m-span {
    margin-left: 7px;
  }

  .date-flex {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;    
  }

  .cursor {
    cursor: pointer;
  }

  .bold {
    font-weight: bold;
  }

  .close-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 10px;
    margin-bottom: 26px;
    cursor: pointer;
  }

  .input-flex {
    display: flex;
    justify-content: space-between;
    width: 225px;
    cursor: pointer;
  }
`;