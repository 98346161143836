import React from 'react';
import { BackIcon, H2, H3 } from '../../../reusable-components';
import { RoundInformationWrapper } from './styles';
import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive/src";

const PrizeGroups = {

    Maxtipset: {
        13: 60,
        12: 20,
        11: 20
    },

    Mixtipset: {
        12: 60,
        11: 20,
        10: 20,
    },

    Flextipset10: {
        10: 100
    },

    Flextipset8: {
        8: 100
    },

    Flextipset6: {
        6: 100
    }

};


export function RoundInformation({ round, setShowRoundInfo }) {

    const { t } = useTranslation();

    const prizes = PrizeGroups[round.name];

    return (
        <RoundInformationWrapper>
            <MediaQuery maxWidth={1000}>
                <div className="go-back">
                    <div className="go-back-button" id={'go-back-button'} onClick={() => setShowRoundInfo(false)}>
                        <div>{BackIcon}</div>
                        <div className={'bold'}>{t('go_back')}</div>
                    </div>
                </div>
            </MediaQuery>

            <div className="header">
                <H2>{round.name}</H2>
            </div>
            <div className="subheader">
                <H3>{t('prize_groups')}</H3>
            </div>
            <div className="flex-list">
                <div key="prize-1" className="flex-row-header flex-row-odd"><span>{t('correct_rows')}</span><span>Share of prize pool</span></div>
                {Object.keys(prizes).sort(k => k * -1).map((price, index) => {
                    const className = index % 2 > 0 ? "flex-row-odd" : "flex-row-even";
                    return <div key={`prize-${index}`} className={className}><span>{price}</span><span>{prizes[price]}%</span></div>
                }
                )}
            </div>
        </RoundInformationWrapper>
    );
}