import styled from 'styled-components';

export const FinishedDrawTextWrapper = styled.div`
  height: 100%;
  display: flex;
  min-height: 70px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
`;

export const FinishedDrawText = styled.div`
    display: block;
    color: red;
`;

export const LegFieldWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: flex-start;
  padding: 0 14px 0 0;

  .signs-div {
    display: flex;
  }

  .black-div {
    background: var(--off-black);
    color: var(--text-white);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 8px 4px 9px;
    font-size: 13px;
    line-height: 13px;
    margin-top: 18px;
    height: 17px;
  }
  @media screen and (max-width: 1000px) {
    .black-div {
      min-width: 22px;
    }
  }

  @media screen and (min-width: 1001px) {
    .black-div {
      min-width: 27px;
    }
  }

  .percentage-span {
    font-size: 8px;
    line-height: 9px;
    color: var(--text-black);
  }

  .margin-left {
    margin-left: 6px;
    text-align: center;
  }
`;

export const SignsFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 70px;
`;