import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Input} from "@material-ui/core";
import {usePurchaseFromFile} from "./usePurchase";

const CouponUpload = () => {
  const purchaseFromFile = usePurchaseFromFile();
  // State to store uploaded file
  const [status, setStatus] = React.useState("")
  const [inProgress, setInProgress] = React.useState(false);

  // Handles file upload event and updates state
  function handleUpload(event) {
    setStatus("");
    setInProgress(true)
    purchaseFromFile(event.target.files[0]).then(() => {
      setStatus("OK")
      setInProgress(false);
    }).catch(error => {
      setStatus("error creating coupon");
      setInProgress(false);
    })
  }

  return (
    <div id="upload-box">
      Upload coupon:
      <Input type="file" onChange={handleUpload} />
      {inProgress && <CircularProgress/>}
      <span>{status}</span>
    </div>
  );
}

export default CouponUpload;