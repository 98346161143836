import styled from 'styled-components';

export const SyndicateCouponWrapper = styled.div`
  padding-top: 42px;

  .top-div {
    background: var(--pinkish-white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 170px;

  }

  .icon {
    transform: rotate(-180deg);
  }

  .sort-div {
    background: var(--off-black);
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 19px;
  }

  .span {
    color: var(--text-white);
    font-weight: bold;
  }

  .settings-div, .showing-div {
    width: 185px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--green-tint-background);
    font-size: 11px;
    font-weight: bold;
  }

  .settings-div {
    padding: 0 12px;
  }

  .showing-div {
    padding: 0 15px;
    margin: 0 18px 0 34px;
  }

  .showing-margin {
    margin: 0 12px;
  }

  /* Grid */

  .grid-div {
    display: flex;
    background: var(--off-white);
  }

  .match-div, .results-div, .systems-div {
    padding-top: 11px;
    border-right: 1px solid var(--off-black);
  }

  .rows-div {
    padding-top: 11px;
  }

  .match-div {
    width: 400px;
    font-size: 15px;
  }

  .text-div {
    font-size: 11px;
    font-weight: bold;
  }

  .match-margin {
    margin: 0 19px;
  }

  .result-margin {
    margin: 0 12px;
  }

  .gray {
    color: rgba(33, 31, 32, 0.5);
  }

  .match-iteration-div {
    height: 36px;
    display: flex;
    align-items: center;
  }

  .green-bg {
    background: var(--green-tint-background);
  }

  .results-div {
    width: 80px;
  }

  .black-div {
    background: var(--off-black);
    color: var(--text-white);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 8px 4px 9px;
    font-size: 13px;
    line-height: 13px;
    margin-top: 18px;
    height: 17px;
  }

  @media screen and (max-width: 1000px) {
    .black-div {
      min-width: 22px;
    }
  }

  @media screen and (min-width: 1001px) {
    .black-div {
      min-width: 27px;
    }
  }

  .sign {
    font-size: 12px;
    font-weight: bold;
  }

  .system-width {
    width: 100px;
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
  }

  .rows-width {
    padding: 0 12px;
  }

  .rows-margin {
    margin: 0 12px 0 20px;
  }

  .result-span {
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
  }
`;