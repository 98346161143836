import {usePlayer} from "../player/playerContext";
import {useQuery, useQueryCache} from "react-query";
import fetchApi from "../backend/fetchApi";

// This lists all available syndicates, should be replaced by more flexible search later
const useSyndicates = () => {
  const [player] = usePlayer();

  const queryCache = useQueryCache();
  const syndicatesUrl = player && player._links.syndicates.href;
  const syndicates = useQuery(['syndicates', syndicatesUrl], () => {
    return fetchApi(syndicatesUrl).then(response => response.json()).then(r => r._embedded.syndicates)
  }, {
    enabled: !!player,
  })

  return [syndicates.data && syndicates.data.map(syndicate => ({
    ...syndicate,
    purchase: () => fetchApi(player._links.syndicateShares.href, {
      method: 'POST',
      body: JSON.stringify({
        stake: syndicate.stakePerShare,
        syndicateId: syndicate.id,
      }),
    }).then(response => {
        if (response.ok) {
          
          setTimeout(() => {
            queryCache.invalidateQueries('couponShares')
            queryCache.invalidateQueries('syndicates')            
          }, 2000);
        } else {
          
        }
        // Allow for eventual consistency before reload
      }),
  })), {
    ...syndicates,
    reloadSyndicates: () => queryCache.invalidateQueries('syndicates')
  }]
}

export default useSyndicates;