export const formatStringCurrency = (amount, currencyCode) => {
    if (!amount) {
        amount = 0;
    }
    if (!currencyCode) {
        currencyCode = 'NA';
    }
    if (currencyCode === "SEK") {
        amount = floorOfNumberFormatted(amount, 1,0);
        return `${amount} kr`;
    }

    if (currencyCode === "USD") {
        amount = floorOfNumberFormatted(amount, 10,2);
        return `$ ${amount}`;
    }

    if (currencyCode === "GBP") {
        amount = floorOfNumberFormatted(amount, 10, 2);
        return `£ ${amount}`;
    }

    if (currencyCode === "EUR") {
        amount = floorOfNumberFormatted(amount, 10, 2);
        return `€ ${amount}`;
    }

    return `${amount} ${currencyCode}`;
}

function roundOfNumberFormatted(number, precision, decimalPlaces) {
    number = Number(number) * precision
    number = Math.round(number) / precision

    number = Number(number).toFixed(decimalPlaces);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

// precision is the minimum unit for the decimal part of the output number
// e.g. precision = 10 means that the floor calc will preserve one decimal figure
// meaning that both 1.71 and 1.79 become 1.70
// precision = 100 would mean that 1.71 and 1.79 stays 1.71 and 1.79 respectively
function floorOfNumberFormatted(number, precision, decimalPlaces) {
    number = Number(number) * precision
    number = Math.floor(number) / precision

    number = Number(number).toFixed(decimalPlaces);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}