import React, { forwardRef, useState } from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { reduce } from "lodash";
import { useHistory } from "react-router-dom";
import { betTotal } from "./components/betfield";
import isEqual from 'lodash/isEqual';
import { FORM_ERROR } from "final-form";
import { Wrapper } from './styles';
import {
  H2,
  Button,
  HalfCircleIcon,
  CircleIcon,
  CashIcon,
  CogIcon,  
  ShadowDiv,
  ExpandMoreArrowIcon, RobotIcon, InfoIcon,
} from '../../../reusable-components';
import ConfirmPayment from './components/confirmpayment';
import FormBody from './components/formbody';
import { useSimplePurchase } from '../../../rounds/usePurchase';
import SystemField from '../../../coupons/SystemField';
import { Close } from '@material-ui/icons';
import WinInfoRound from '../wininforound';
import { sortByDate, sortByName } from '../../../services/sortService';
import { useTranslation } from "react-i18next";
import {getSuggestion} from './components/propick';
import ProPick from './components/propick';
import MediaQuery, { useMediaQuery } from "react-responsive/src";
import { RoundInformation } from '../roundinformation';
import {operatorAdviceScrollToTop} from "../../../App";
import {usePlayer} from "../../../player/playerContext";
import {formatStringCurrency} from "../../../common/CurrencyFormatter";
import { getBackground, getDateString } from '../../../common/RoundHelper';

function getWholeAndHalfPutsFromArray(arr = []) {
  let wholePuts = 0;
  let halfPuts = 0;

  for (let i = 0; i < arr.length; i++) {
    const { signs } = arr[i];
    const { length } = signs || [];

    if (length === 3) {
      wholePuts += 1;
    } else if (length === 2) {
      halfPuts += 1;
    }
  }

  return {
    wholePuts,
    halfPuts
  };
}


const couponTotal = (round, coupon) =>
  reduce((coupon.bets || []).map(b => betTotal(round.reducedSystems, b)), (x, y) => x + y, 0) * Math.round(100*coupon.stakePerRow.amount)/100

const getSigns = (proPickRows, index) => {
  if (proPickRows.length === 0) {
    return [];
  }
  return proPickRows[index];
}
const newBet = (fixtures = [], proPickRows) => ({
  system: {
    type: 'MATHEMATICAL',
  },

  legs: fixtures.map((fixture, index) => ({
    signs: getSigns(proPickRows, index)
  }))
}
);

export const initialCouponValues = (round, proPickRows) => ({
  bets: [newBet(round.fixtures, proPickRows)],
  stakePerRow: round.operatorStakePerRow,
  roundId: round.id,
});

const newBetSyndicate = (fixtures = [], syndicate) => ({
  system: {
    type: 'MATHEMATICAL',
  },
  legs: fixtures.map((fixture, i) => ({
    signs: (syndicate.pendingRoundCoupon.bets[0] ? syndicate.pendingRoundCoupon.bets[0].legs[i] : [])
  }))
}
);
export const initialCouponValuesSyndicate = (round, syndicate) => ({

  bets: [newBetSyndicate(round.fixtures, syndicate)],
  stakePerRow: round.operatorStakePerRow,
  roundId: round.id,
});

export const CouponPendingField = ({ syndicate, round, fixtureValue,
  onChangeFixture, callback }) => {
  const initialValues = initialCouponValuesSyndicate(round, syndicate);

  const { t } = useTranslation();
  const { openTo } = round || {};
  const startDate = new Date(openTo);

  const isAfterDeadline = startDate < Date.now();

  return (
    <Wrapper>
      <Form className="form-margin"
        initialValuesEqual={isEqual}
        initialValues={initialValues}
        onSubmit={callback}
        mutators={{
          ...arrayMutators
        }}
        render={({ submitFailed, handleSubmit, form, submitting, values, validating, valid }) => {
          const { bets } = values || {};
          const [bet] = bets || [];
          const { legs } = bet || {};
          const { wholePuts, halfPuts } = getWholeAndHalfPutsFromArray(legs);
          const totalStake = couponTotal(round, values);

          return (
            <form onSubmit={event => {
              handleSubmit(event).then(err => {
                if (err !== FORM_ERROR || !err.hasOwnProperty(FORM_ERROR)) {
                  form.reset(initialValues);
                }
              })
            }}>
              <div className="form-center">

                <FormBody round={round} fixtureValue={fixtureValue} onChangeFixture={onChangeFixture} />

                {!isAfterDeadline && (

                  <div className="pink-div">
                    <span className="caption">{t('overview')}</span>
                    <ul className="sum-ul">
                      <li>
                        {CircleIcon}
                        <span className="m-span">{t('full_cover')}: {wholePuts} </span>
                      </li>
                      <li>
                        {HalfCircleIcon}
                        <span className="m-span">{t('half_cover')}: {halfPuts} </span>
                      </li>
                      <li>
                        <span className="caption">Total stake: {totalStake}</span>
                      </li>
                      <li>
                        <span className="caption">Stake per share: {Math.round(100 * totalStake / syndicate.targetNShares) / 100}</span>
                      </li>
                    </ul>
                  </div>
                )}
                <Button disabled={totalStake / syndicate.targetNShares < 1} className="button-margin" >{t('submit_syndicate')}</Button>
              </div>

            </form>
          )
        }}
      />


      <div>


      </div>
    </Wrapper>
  );
}

const CouponForm = ({
  round,  
  fixtureValue,
  onChangeFixture,
  validateCoupon,  
  setShowRoundInfo,
  showRoundInfo,
  proPickSuggestion  
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const purchase = useSimplePurchase();
  const [showRSystem, setShowRSystem] = useState(false);
  const [showProPick, setShowProPick] = useState(false);
  const [incomingRowsCalculated, setIncomingRowsCalculated] = useState(false);
  const [proPickRows, setProPickRows] = useState([]);
  const { t } = useTranslation();  
  const isMobileScreen = useMediaQuery({ query: '(max-width: 1000px)' })

  const [player, config] = usePlayer();
  const rSystemsActive = config.rSystemBetting;

  const handlePayClick = () => {
    setShowConfirm(true);
    setTimeout(() => operatorAdviceScrollToTop(), 300);
  }

  const handleShowRSystemClick = () => {
    setShowRSystem(true);
    setTimeout(() => operatorAdviceScrollToTop(), 300);
  }

  if(proPickSuggestion && !incomingRowsCalculated &&
    (proPickSuggestion.numFull>0 || proPickSuggestion.numHalf>0)){
      setIncomingRowsCalculated(true);
      getSuggestion(proPickSuggestion, round.fixtures, setProPickRows);
  }


  const initialValues = initialCouponValues(round, proPickRows);
  const clearedForm = initialCouponValues(round, []);

  let errorResponse = {};

  const onSubmit = async (coupon) => {
    let res = await purchase(coupon);
    const data = await res.json();
    setProPickRows([]);
    if (res.status === 200) {
      return res;
    } else {
      errorResponse = data
      return { [FORM_ERROR]: errorResponse };
    }
  }

  const {openTo, operatorStakePerRow } = round || {};  

  const turnoverAmount = round.operatorTurnover ? round.operatorTurnover.amount : 'NA';
  const turnoverCurrency = round.operatorTurnover ? round.operatorTurnover.currency : 'NA';
  const startDate = new Date(openTo);

  const jackpotkey = 'jackpot'.concat(round.operatorTurnover.currency);
  const jackpotEntry = round ? round.promotionalValues.filter((item) => item.key===jackpotkey):[];
  let jackpot = null;
  if(jackpotEntry.length===1){
    jackpot = jackpotEntry[0].value;
  }

  
  let roundStatus = t('round_status_open')

  const isAfterDeadline = startDate < Date.now();
  if (isAfterDeadline) {
    validateCoupon = null;
  }

  if (round.status === "FINISHED") {
    roundStatus = t('round_status_finished');
  } else if (round.status === "ACTIVE") {
    roundStatus = t('round_status_open');
    if (isAfterDeadline) {
      roundStatus = t('round_active_after_deadline');
    }
  } else if (round.status === "VOIDED") {
    roundStatus = t('round_status_voided');
  } else if (round.status === "PAUSED") {
    roundStatus = t('round_status_paused');
  } else {
    roundStatus = "N/A";
  }

  const renderProPick = (bet) => {
    return <ProPick setProPickRows={setProPickRows} onShowProPick={x => setShowProPick(x)} legs={bet.legs} fixtures={round.fixtures} operatorStakePerRow={round.operatorStakePerRow}/>
  }

  return (
    <Wrapper>
      <Form
        initialValuesEqual={isEqual}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={ (values) => {

          if (!values) {
            return;
          }

          const { bets } = values;
          const { legs } = bets[0];
          let valid = true;
          for (let i = 0; i < legs.length; i ++) {
            const signs = legs[i].signs;
            if (signs.length === 0) {
              valid = false;
              break;
            }
          }

          if (!valid) {
            return FORM_ERROR;
          }
        }}
        mutators={{
          ...arrayMutators
        }}
        render={({
                   submitFailed,
                   handleSubmit,
                   form,
                   submitting,
                   values,
                   validating,
                   valid,
                   submitError }) => {
          const { bets } = values || {};
          const [bet] = bets || [];
          const { legs } = bet || {};
          const { wholePuts, halfPuts } = getWholeAndHalfPutsFromArray(legs);

          let reducedWhole = "";
          let reducedHalf = "";
          let systemName = "";

          if (bet.system.type === "REDUCED") {
            const parts = bet.system.reducedSystemId.split("_");
            reducedWhole = '(' + parts[1] + ')';
            reducedHalf = '(' + parts[2] + ')';
            systemName = t('reduced_system') + ' ' + bet.system.reducedSystemId;
          }

          if (showRoundInfo && isMobileScreen) {
            return (
              <RoundInformation round={round} setShowRoundInfo={setShowRoundInfo} />
            )
          };

          return (
            <form onSubmit={event => {
              handleSubmit(event).then(err => {
                if (!err.hasOwnProperty(FORM_ERROR)) {
                  form.reset(initialValues);
                }
              })
            }}>
              <div className="round-back-div" style={{background:getBackground(round)}}>
                <div className="stats">
                  <div className="date-flex underline" id={"start-date-wrapper"}>
                    <H2 className="black-text">{getDateString(round,t)}</H2>
                  </div>
                  {jackpot &&
                    <div id={"turnover-wrapper"} className="underline">
                      <span className="small-span">{t('jackpot')}</span>
                      <H2 className="black-text">{formatStringCurrency(jackpot, turnoverCurrency)}</H2>
                    </div>
                   }
                  <div id={"turnover-wrapper"} className="underline">
                    <span className="small-span">{t('turnover')}</span>
                    <H2 className="black-text">{formatStringCurrency(turnoverAmount, turnoverCurrency)}</H2>
                  </div>

                  <MediaQuery minWidth={1000}>
                    <div>
                      <div>
                        <span className="small-span">
                          <div>Status</div>
                        </span>
                        <H2 className="black-text">{roundStatus}</H2>
                      </div>                          
                    </div>
                  </MediaQuery>
                </div>
                {isAfterDeadline ? <div></div> :
                  <div className="explain">
                    <div className="icon-flex" onClick={() => setShowRoundInfo(!showRoundInfo)}>
                      <InfoIcon height={isMobileScreen ? "20" : "30"} width={isMobileScreen ? "20" : "30"} />
                      <span>{t('round_information_short')}</span>
                    </div>
                    <div className="icon-flex" onClick={() => setShowProPick(true)}>
                      <RobotIcon height={isMobileScreen ? "20" : "30"} width={isMobileScreen ? "20" : "30"} />
                      <span>{t('pro_pick')}</span>
                    </div>

                    {showProPick && (
                      <div>
                        <MediaQuery maxWidth={1000}>
                          {renderProPick(bet)}
                        </MediaQuery>
                        <MediaQuery minWidth={1001}>
                          <ShadowDiv>
                            {renderProPick(bet)}
                          </ShadowDiv>
                        </MediaQuery>

                      </div>
                    )}

                    <MediaQuery minWidth={601}>
                      {rSystemsActive && (<div>
                          <div className="icon-flex cursor" onClick={() => handleShowRSystemClick()}>
                            <CogIcon height={isMobileScreen ? "20 " : "30"} width={isMobileScreen ? "20 " : "30"}/>
                            <span>{t('system')}</span>
                          </div>

                          {showRSystem && (
                              <ShadowDiv>
                                <div className="shadow-content start dyn-height top-screen">
                                  <div className="close-flex">
                                    <span />
                                    <span className="bold">{t('reduce')}</span>
                                    <Close onClick={() => setShowRSystem(false)} />
                                  </div>
                                  {round.reducedSystems.length > 0 ? <Field name={`bets[0].system`} component={SystemField} reducedSystems={round.reducedSystems}
                                                                            onCloseDialog={() => setShowRSystem(false)} /> : <span>{t('no_reduced_systems_available')}</span>}
                                </div>
                              </ShadowDiv>
                          )}
                        </div>)}
                    </MediaQuery>

                  </div>
                }
              </div>



              {showConfirm && (
                <ConfirmPayment
                  amount={couponTotal(round, values)}
                  currency={turnoverCurrency}
                  toggle={setShowConfirm}
                  submitting={submitting}
                  submitFailed={submitFailed}
                  resetValues={() => form.reset(clearedForm)}
                  submitError={submitError}
                />
              )}
              <FormBody
                round={round}
                fixtureValue={fixtureValue}
                onChangeFixture={onChangeFixture} />
              {!isAfterDeadline && (

                <div className="pink-div">
                  <span className="caption">{t('overview')}</span>

                  <ul className="sum-ul">
                    <li>
                      {CircleIcon}
                      <span className="m-span">{t('full_cover')}: {wholePuts} {reducedWhole}</span>
                    </li>
                    <li>
                      {HalfCircleIcon}
                      <span className="m-span">{t('half_cover')}: {halfPuts} {reducedHalf}</span>
                    </li>
                    <li>
                      {CashIcon}
                      <span className="m-span">{t('row_price')}: {formatStringCurrency(operatorStakePerRow.amount, operatorStakePerRow.currency)}</span>
                    </li>
                    <li>

                    </li>
                    <li>
                      <span className="caption">{systemName}</span>
                    </li>
                  </ul>
                  <Button
                    disabled={submitting || validating || !valid}
                    id={'pay-button'}
                    onClick={() => handlePayClick()}
                    type="button">
                    {t('pay')} {formatStringCurrency(couponTotal(round, values),turnoverCurrency)}
                  </Button>

                  <a onClick={() => form.reset(clearedForm)} className={'clear-bets-anchor'}>{t('clear_bets')}</a>

                </div>
              )}
              {(isAfterDeadline && (round.operatorPrizePlan)) && (

                <WinInfoRound round={round}></WinInfoRound>

              )}
            </form>
          )
        }}
      />
    </Wrapper>
  );
}

export default CouponForm;