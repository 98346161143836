import React, {useState} from 'react';

import usePlayerBalance from './usePlayerBalance';
import {CircularProgress} from "@material-ui/core";

const PlayerBalance = () => {
    const [balance, loading] = usePlayerBalance();



    const renderBalance = () => {
        if (loading) {
            return <></>;
        }

        return <div>
            {`${balance.amount} ${balance.currency}`}
        </div>
    }

    const containerStyle = {
        backgroundColor: 'var(--navbar-background)',
        'color': 'var(--navbar-text)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 'bold',
        height: '100%',
    };

    return (
        <div style={containerStyle}>
            <div style={{display: 'block', maxHeight: '30px'}}>
                {renderBalance()}
            </div>
        </div>
    );
}

export default PlayerBalance;