import React from 'react';
import LegField from "../legfield";
import { Field } from "react-final-form";
import { BetFieldWrapper } from './styles';
import { reduce } from "lodash";
import find from "lodash/find";
import { ForwardArrowIcon } from '../../../../../reusable-components';

export const betTotal = (reducedSystems, bet) => {
  switch (bet.system.type) {
    case 'MATHEMATICAL':
      return reduce((bet.legs || []).map(l => Math.max(l.signs.length, 1)), (x, y) => x * y, 1);

    case 'REDUCED':
      const rs = find(reducedSystems, r => r.id === bet.system.reducedSystemId);

      if (!rs) {
        return 0;
      }

      return rs.nRows;

    default:
      return 0;
  }
}

const BetField = ({
  round,
  onChangeFixture,
  reducedSystems,
  fixtures,
  fixtureValue,
  input: { name, value },
}) => {
  return (
    <BetFieldWrapper>
      {fixtures.map((fixture, index) => (
        <div key={fixture.id} className={`field ${fixtureValue === index ? 'bg' : ''} ${index === 0 ? 'overline' : ''}`} onClick={() => {
          onChangeFixture(index);
        }}>
          <Field
            index={index}
            round={round}
            name={`${name}.legs[${index}]`}
            fixture={fixture}
            component={LegField}
          />
          {ForwardArrowIcon}
        </div>
      ))}
    </BetFieldWrapper>
  )
}

export default BetField;