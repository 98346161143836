import React, {useState} from 'react';
import allSigns from "../../../coupons/signs";
import {SettledSignBoxWrapper, ForwardArrowIcon, ShadowDiv} from '../../../reusable-components';

import { useTranslation } from "react-i18next";
import { fixtureTime, isFixtureAfterGameStart } from '../../../common/DateTimeHelper';
import { CouponDetailWrapper } from './styles';
import FixtureDetailsMobile from "../../../fixturedetailsmobile";
import MediaQuery, {useMediaQuery} from "react-responsive/src";
import {
  FinishedDrawText,
  FinishedDrawTextWrapper
} from "../../../routedround/components/couponform/components/legfield/styles";

const formatFraction = (v,isSingleRows) => {
  if(!isSingleRows){
    return ``;
  }

  return `${(parseFloat(v) * 100).toFixed(0)}%`;
}

const getHeightFromFraction = rowsFraction => {
  if (rowsFraction === 1.0) {
    return 22;
  } else if (rowsFraction >= 0.8) {
    return 20;
  } else if (rowsFraction >= 0.7) {
    return 15;
  } else if (rowsFraction >= 0.5) {
    return 11;
  } else if (rowsFraction >= 0.25) {
    return 6;
  } else if (rowsFraction >= 0.15) {
    return 3;
  } else {
    return 0;
  }
}

const CouponFixture = ({
  roundFixture,
  couponFixture,
  index,
  leg,
  isSingleRows,
  setFixtureValue,
  isSelected,
  correctSign
}) => {
  const { signs = [] } = leg || {};
  
  const isAfterGameStart = isFixtureAfterGameStart(roundFixture);
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });
  const [fixtureDetailsModalOpen, setFixtureDetailsModalOpen] = useState(false);
  const [selectedFixtureId, setSelectedFixtureId] = useState(null);

  const handleFixtureClick = (index, fixtureId) => {
    setFixtureValue(index);
    setSelectedFixtureId(fixtureId)
    setFixtureDetailsModalOpen(true);
  }

  const closeF = () => {
    setFixtureDetailsModalOpen(false);
  }

  const { draw } = roundFixture;
  const fixtureIsDrawn = draw !== null && draw.status === 'FINISHED';

  return (
      <div>
        <MediaQuery maxWidth={1000}>
          {fixtureDetailsModalOpen && (<FixtureDetailsMobile fixtureId={selectedFixtureId} closeF={closeF} roundFixture={roundFixture}/>)}
        </MediaQuery>
        <div className={`fixture ${isSelected ? 'bg' : ''}`} onClick={() => handleFixtureClick(index, roundFixture.id)}>
          <div className="text-div">
        <span className="match-title line-break">
          <span className="padding">{index + 1}</span>{roundFixture ? `${roundFixture.homeTeam.name} - ${roundFixture.awayTeam.name}` : 'N / A'}
          {fixtureIsDrawn && (<FinishedDrawText>{t('is_drawn')}</FinishedDrawText>)}
        </span>
            {fixtureTime(roundFixture,t)}
          </div>
          <div className="right-flex">
            {
              (isAfterGameStart && <div className="black-div">{roundFixture ? `${roundFixture.homeTeam.score} - ${roundFixture.awayTeam.score}` : 'N / A'}</div>)
            }
            <div className="signs-div">
              {Object.entries(allSigns).map(([sign, label], i) => {
                const isGreenBorder = sign === correctSign;
                const { rowsFraction } = couponFixture.signs[sign]
                let height = getHeightFromFraction(rowsFraction);
                let isInSystem = !isSingleRows & (leg.signs[0]===sign || leg.signs.length>1 & leg.signs[1]===sign || leg.signs.length>2 & leg.signs[2]===sign)
                if (isInSystem) {
                  height = 27;
                }

                return (
                    <div key={`singbox ${i}`} className={`margin-left`}>
                      <SettledSignBoxWrapper
                          height={height}
                          className={isGreenBorder ? 'green-box' : ''}
                          selected={signs.includes(sign)}
                          greenBorder={isGreenBorder}
                      >
                        {label}
                      </SettledSignBoxWrapper>
                      {couponFixture && <span className={`percentage-span ${isGreenBorder ? 'green-span' : ''}`}>{formatFraction(rowsFraction, isSingleRows)}</span>}
                    </div>
                );
              })}
            </div>
            <span className="icon-margin">
              {ForwardArrowIcon}
            </span>
          </div>
        </div>
      </div>
  );
}

const CouponDetail = ({
  coupon,
  round,
  isSingleRows,
  legs,
  fixtureValue,
  setFixtureValue
}) => {

  const neverSelectAny = (fixtureValue === -1);


  return (
    <CouponDetailWrapper>
      <div style={{ display: 'flex', flexDirection: 'row',  width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {round.fixtures &&  round.fixtures.map((fixture, index) => (
            <CouponFixture
              key={index}
              index={index}
              roundFixture={fixture}
              couponFixture={coupon.fixtures[index]}
              isSingleRows={isSingleRows}
              leg={legs[index]}
              setFixtureValue={setFixtureValue}
              isSelected={neverSelectAny ? false : fixtureValue === index}
              correctSign={(round.preliminaryCorrectRow && round.preliminaryCorrectRow[index])}
            />
          ))}
        </div>        
      </div>
    </CouponDetailWrapper>
  );
}

export default CouponDetail;