import React from 'react';
import ReducedSystemSelector from "../routedround/components/couponform/components/ReducedSystemSelector/index";

const SystemField = ({ reducedSystems, onCloseDialog, input: { onChange } }) => {
  const reset = () => onChange({ type: 'MATHEMATICAL' });

  const onDialogChange = (id) =>{
    onChange({ type: 'REDUCED', reducedSystemId: id });
    onCloseDialog();
  }


  return <ReducedSystemSelector reducedSystems={reducedSystems} onReset={reset} onChange={id => onDialogChange(id)} />;

}

export default SystemField;