import styled from 'styled-components';

export const Wrapper = styled.div`
  .green-div {
    background: var(--green-tint-background);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 42px;
  }

  .title-margin {
    margin-bottom: 10px;
  }

  .button-margin {
    margin: 33px 0;
  }
`;

export const SyndicateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  padding-bottom: 120px;
  background: var(--off-white);

  .active-content, .settled-content {
    width: 595px;
  }


  @media screen and (max-width: 600px) {
    .active-content {
      margin-top: 50px;
    }
  }

  @media screen and (min-width: 601px) {
    .active-content {
      margin-top: 100px;
    }
  }

  .settled-content {
    margin-top: 50px;
  }

  .orange {
    background: var(--pinkish-white);
  }

  .green {
    background: var(--green-tint-background);
  }

  .bold {
    font-weight: bold;
  }

  .coupon-wrapper {
    margin-top: 18px;
  }

  .coupon {
    height: 75px;
    padding: 10px 20px 10px 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .link {
    text-decoration: none;
  }

  .time-div {
    display: flex;
    flex-direction: column;
  }

  .date-span {
    margin-bottom: 4px;
  }

  .coupon span {
    color: var(--text-black);
  }

  .flex {
    display: flex;
    align-items: center;
    padding-left: 13px;
  }

  .center-progress {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
`;