export const TRANSLATIONS_FA = {
    max: "برگه ",
    mix: "برگه ",
    flex: "برگه روزانه",
    // These three are temporary as some text in the site depends on the path
    mixtipset: "برگه روزانه",
    maxtipset: "برگه 13",
    flextipset: "برگه روزانه",
    //

    my_bets: "شرطهای من",
    my_coupons: "تیکتهای من",
    rounds: "مرحله ها",
    bet_with_friends: "شرط با دوستان",
    pick_your_bet: "شرط خود را انتخاب کنید",
    friends: "دوستان",
    turnover: "گردش مالی",
    starting_soon: "به زودی شروع می شود",
    single_rows: "ردیفهای تکی",
    pro_pick: "انتخاب سیستمی",
    often: "تیم های قوی",
    a_lot: "تیم های ضعیف",
    system: "سیستم",
    row_distribution: "توزیع ردیف",
    overview: "بررسی",
    full_cover: "همه حالتها",
    half_cover: "2 حالت",
    row_price: "قیمت واحد",
    pay: "پرداخت",
    more: "بیشتر",
    unsettled_coupons: "تیکت های تصفیه نشده",
    settled_coupons: "تیکت های تصفیه شده",
    stake: "مبلغ",
    coupon_share_status_cancelled: "Cancelled",
    your_winnings_right_now: "برد های شما در حال حاضر",
    correct_rows_now: "انتخابهای درست تا الان",
    starts: "شروع میشود",
    correct_rows: "ردیفهای درست",
    prize: "برد",
    your_rows: "ردیف های شما",
    profit: "برد",
    st: "",
    total: "نتیجه",
    rows: "ردیف ها",
    row: "ردیف",
    price_per_row: "قیمت برای ردیف",
    total_cost: "هزینه کل",
    paid: "پرداخت شده",
    settled: "تسویه شده",
    channel_internet: "خرید برگه از اینترنت",
    print: "چاپ",
    share: "اشتراک گذاری",
    your_teams: "تیم های شما",
    create_a_new_team: "Create a new team",
    clear_bets: 'پاک کردن شرطها',


    under_construction: "در دست ساخت",
    news_or_image_placeholder: "PLACEHOLDER FOR NEWS / IMAGE",
    reduce: "كم کردن",
    no_reduced_systems_available: "No reduced systems available",
    round_history: "نتیجه های قبلی",
    maximum_profit: "حداکثر سود",
    no_profit: "بدون سود",
    no_coupons: "بدون تیکت",
    started: "شروع شده",
    round: "مرحله",
    winners: "برنده ها",
    your_bet_is_paid: "شرط شما پرداخت شد",
    do_you_want_to_place_bet: "ایا میخواهی شرط ثبت کنی",
    new_round_will_open_soon: "برگه جدید بزودی باز خواهد شد",
    highest_win: "بیشترین برد",
    insufficient_funds: "بودجه کافی نیست",
    cancel: "باطل کردن",
    unable_to_complete_purchase: 'Unable to complete purchase',
    error_code: 'Error code',

    day_sun: "یکشنبه",
    day_mon: "دوشنبه",
    day_tue: "سه شنبه",
    day_wed: "چهار شنبه",
    day_thu: "پنج شنبه",
    day_fri: "جمعه",
    day_sat: "شنبه",

    event_goal: 'گل',
    event_penalty: 'پنالتی',
    event_missed_penalty: 'پنالتی از دست رفته',
    event_own_goal: 'گل به خودی',
    event_var: 'سیستم کمک داور ویدیویی',
    event_yellowcard: 'کارت زرد',
    event_yellowred: 'کارت زرد دوم',
    event_substitution: 'بازیکنان ذخیره',
    event_redcard: 'کارت قرمز',
    event_pen_shootout_goal: 'گل از پنالتی',
    event_pen_shootout_miss: 'پنالتی از دست رفته',
    event_full_time: 'وقت قانونی',
    event_half_time: 'نیمه اول',
    event_kickoff: 'شروع بازی',
    event_finished_after_extra: 'Extra Time Ended',

    out: 'اوت',

    news: "اخبار",
    table: "جدول رده بندی",
    game_info: "اطلاعات بازی",
    statistics: "امارها",
    lineup: "ترکیب",
    football: "فوتبال",

    position: "Pos",
    team_name: "تیم",
    table_abbreviation_num_games: "بازیها",
    table_abbreviation_wins: "برد",
    table_abbreviation_draws: "تساوی",
    table_abbreviation_losses: "باخت",
    table_abbreviation_points: "امتیاز",

    in_running: "در حال انجام",
    full_time: "وقت قانونی",
    round_status_finished : "تمام شده",
    half_time: 'نیمه',
    kickoff: 'شروع بازی',

    current_rounds: "مرحله های در حال انجام",
    settled_rounds: "مرحله های تصویه شده",
    no_rounds_available: "هیچ مرحله ای در دسترس نیست",

    row_statistics: 'آمار بیشتر',
    poolx_game: 'Poolx game',
    showing: 'نمایش دادن',
    settings: 'تنظیمات',
    match: 'مسابقه',
    result: 'نتیجه',
    bet_type: "نوع شرط",
    mathematical_system: "Mathematical system",
    reduced_system: "Reduced system",
    no_system: "بدون سیستم",
    select: "انتخاب",
    is_draw: "کنسل شده و نتیجه قرعه کشی خواهد شد",
    is_drawn: "قرعه کشی شده",
    num_correct_win: 'تعداد درست/برد',
    more_info: 'اطلاعات بیشتر',

    home: 'خانه',
    coupons: 'تیکت',
    results: 'نتایج',
    go_back: 'برگشت',

    to_syndicate: "To syndicate",
    shares: "Shares",
    share_price: "Share price",
    sold_shares: "Sold shares",
    agent_admin: "Agent admin",
    agents: "Agents",
    to_agent: "To agent",
    create_new_syndicate: "Create new syndicate",
    name: "Name",
    target_number_of_shares: "Target number of shares",
    max_number_of_shares: "Max number of shares",
    submit_syndicate: "Submit syndicate",
    update_syndicate: "Update syndicate",
    show_sv_percent: 'Show SW. players %',
    show_coupon_percent: 'Show coupon %',
    number_of_rows: 'Number of rows',
    share_of_syndicate: 'Share of syndicate',
    syndicate_created: 'Syndicate created',
    syndicate_submitted: 'Syndicate submitted',
    syndicate_updated: 'Syndicate updated',


    finished: 'پایان یافته',
    started_games: 'شروع شده',
    potential: 'بیشترین امکان برد',
    sort_by_finished: 'مرتب سازی بر اساس پایان یافته',
    game_summary: 'خلاصه بازی',
    date: 'تاریخ',
    league: 'لیگ',
    venue: 'محل بازی',
    latest_tweets: 'جدیدترین توییت ها از',
    weather: 'آب و هوا',
    temperature: 'درجه حرارت',
    wind: 'باد',
    moisture: 'رطوبت',
    general_info: 'اطلاعات کلی',
    locale: 'fa-FA',
    show: 'نمایش',
    show_round: 'نمایش مرحله',
    play_on: 'بازیکردن',
    today: 'امروز',
    latest_games: 'آخرین بازیها',
    head_to_head: 'رو در رو',
    round_information_short: 'اطلاعات',
    round_information: 'اطلاعات مرحله',
    prize_groups: 'گروه های جایزه',
    round_status_open : "تصحیحنشده",
    round_status_voided : "Cancelled",
    round_status_paused : "Paused",
    round_active_after_deadline: 'Closed',
    round_paused_explanation: 'The round is temporarily paused for betting',
    jackpot : "Estimated Jackpot",
    after_extra_time: "After Extra Time"
}