import React from 'react';
import { H2, BackIcon, H3} from '../reusable-components';
import { RoundInformationWrapper } from './help_styles';
import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive/src";
import NavBar from '../navbar';
import { useHistory } from "react-router-dom";
import {BelowMenuContentWrapper} from "../common/BelowMenuContentWrapper";

const Help = () => {

    const history = useHistory();
    const goBack = () => {
          history.goBack()
        }
    const { t } = useTranslation(['translation','rules']);

    return (
        <>
        <NavBar whichToUnderline="6" />
            <BelowMenuContentWrapper>
                <RoundInformationWrapper>
                    <MediaQuery maxWidth={1000}>
                        <div className="go-back">
                            <div className="go-back-button" id={'go-back-button'} onClick={() => goBack()}>
                                <div>{BackIcon}</div>
                                <div className={'bold'}>{t('go_back')}</div>
                            </div>
                        </div>
                    </MediaQuery>

                    <H2>{t('rules:faq.title')}</H2>
                    <div className='flex-start'>
                        <H3>{t('rules:faq.q1.question')}</H3>
                        <p>{t('rules:faq.q1.answer')}</p>

                        <H3>{t('rules:faq.q2.question')}</H3>
                        <p>{t('rules:faq.q2.answer')}</p>

                        <H3>{t('rules:faq.q3.question')}</H3>
                        <p>{t('rules:faq.q3.answer')}</p>

                        <H3>{t('rules:faq.q4.question')}</H3>
                        <p>{t('rules:faq.q4.answer')}</p>

                        <H3>{t('rules:faq.q5.question')}</H3>
                        <p>{t('rules:faq.q5.answer')}</p>

                        <H3>{t('rules:faq.q6.question')}</H3>
                        <p>{t('rules:faq.q6.answer')}</p>
                    </div>
                    <H2>{t('rules:terms.title')}</H2>
                    <div className='flex-start'>
                        <H3>{t('rules:terms.p1.title')}</H3>
                        <br/>
                        <p>1.1</p>
                        <p>{t('rules:terms.p1.p11')}</p>
                        <p>1.2</p>
                        <p>{t('rules:terms.p1.p12')}</p>
                        <p>1.3</p>
                        <p>{t('rules:terms.p1.p13')}</p>
                        <p>1.4</p>
                        <p>{t('rules:terms.p1.p14')}</p>
                        <p>1.5</p>
                        <p>{t('rules:terms.p1.p15')}</p>
                        <p>1.6</p>
                        <p>{t('rules:terms.p1.p16')}</p>
                        <p>1.7</p>
                        <p>{t('rules:terms.p1.p17')}</p>
                        <p>1.8</p>
                        <p>{t('rules:terms.p1.p18')}</p>
                        <p>1.9</p>
                        <p>{t('rules:terms.p1.p19')}</p>
                        <p>1.10</p>
                        <p>{t('rules:terms.p1.p110')}</p>
                        <p>1.11</p>
                        <p>{t('rules:terms.p1.p111')}</p>
                        <p>1.12</p>
                        <p>{t('rules:terms.p1.p112')}</p>
                        <p>1.13</p>
                        <p>{t('rules:terms.p1.p113')}</p>
                        <p>1.14</p>
                        <p>{t('rules:terms.p1.p114')}</p>
                        <p>1.15</p>
                        <p>{t('rules:terms.p1.p115')}</p>
                        <p>1.16</p>
                        <p>{t('rules:terms.p1.p116a')}</p>
                        <p>{t('rules:terms.p1.p116b')}</p>
                        <p>{t('rules:terms.p1.p116c')}</p>
                        <p>{t('rules:terms.p1.p116d')}</p>
                        <p>{t('rules:terms.p1.p116e')}</p>
                        <p>1.17</p>
                        <p>{t('rules:terms.p1.p117')}</p>
                        <p>1.18</p>
                        <p>{t('rules:terms.p1.p118')}</p>
                        <p>1.19</p>
                        <p>{t('rules:terms.p1.p119')}</p>
                        <p>1.20</p>
                        <p>{t('rules:terms.p1.p120')}</p>
                        <p>1.21</p>
                        <p>{t('rules:terms.p1.p121')}</p>
                        <p>1.22</p>
                        <p>{t('rules:terms.p1.p122')}</p>
                        <br/>
                        <H3>{t('rules:terms.p2.title')}</H3>
                        <br/>
                        <p>2.1</p>
                        <p>{t('rules:terms.p2.p21')}</p>
                        <p>2.2</p>
                        <p>{t('rules:terms.p2.p22')}</p>
                        <p>2.3</p>
                        <p>{t('rules:terms.p2.p23')}</p>
                        <p>2.4</p>
                        <p>{t('rules:terms.p2.p24')}</p>
                        <p>2.5</p>
                        <p>{t('rules:terms.p2.p25')}</p>
                        <p>2.5.1 {t('rules:terms.p2.p251')}</p>
                        <p>2.5.2 {t('rules:terms.p2.p252')}</p>
                        <p>2.5.3 {t('rules:terms.p2.p253')}</p>
                        <p>2.6</p>
                        <p>{t('rules:terms.p2.p26')}</p>
                        <p>2.7</p>
                        <p>{t('rules:terms.p2.p27')}</p>
                        <p>{t('rules:terms.p2.amendment')}</p>
                    </div>

                </RoundInformationWrapper>
            </BelowMenuContentWrapper>

        </>
    );
}

export default Help;