import React, {useState} from 'react';
import { H2, BackgroundImage, Button } from '../../../reusable-components';
import NavBar from "../../../navbar";
import {Wrapper} from "../../styles";
import image from "../../../images/footballfield.jpg";
import {useHistory} from "react-router-dom";
import {useCreateSyndicateTeam} from "../../../syndicateteam/useSyndicateTeams";
import {useSnackbar} from "notistack";
import {usePlayer} from "../../../player/playerContext";
import {useTranslation} from "react-i18next";

const NewSyndicateTeam = () => {
    let history = useHistory();
    let createSyndicateTeam = useCreateSyndicateTeam();
    let snackbar = useSnackbar();
    const [teamName, setTeamName] = useState("");
    const [player, config] = usePlayer();
    const redirect = (to) => {
        history.push(to);
    }

    if (!config.syndicateBetting) {
        return <div/>;
    }

    const createTeamAndProceed = () => {
        createSyndicateTeam(teamName).then(() => {
            redirect('teams')
        }, (rejection) => {
            snackbar.enqueueSnackbar('nope');
        }).catch((err) => snackbar.enqueueSnackbar('nope 2'))
    }
    return (
        <>
            <NavBar whichToUnderline="1" />
            <Wrapper>
                <BackgroundImage height={250} image={image}>
                    <H2 color="#fff">
                        YTA FÖR NYHET/BILD
                    </H2>
                </BackgroundImage>
                <div className="green-div" style={{ minHeight: '300px' }}>
                    <H2 className="title-margin">
                        HITTA PÅ ETT LAGNAMN
                    </H2>
                    <div>
                        <form>
                            <div style={{ display: 'flex', margin: '36px 0', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', minHeight: '150px' }}>
                                <input onChange={(e) => setTeamName(e.target.value)} type="text" style={{ border: "1px solid var(--off-black)", textAlign: "center", height: "24px", width: "100%", fontSize: "14px" }} placeholder="Skriv lagets namn här"/>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', maxWidth: '70%', minHeight: '100px' }}>
                                    <Button type="button" onClick={() => createTeamAndProceed()}>Skapa lag</Button>
                                    <Button type="button" onClick={() => redirect("/teams")} border="1px solid black" textColor="var(--text-black)" color="var(--green-tinted-white)">Avbryt</Button>
                                </div>
                            </div>
                        </form>
                        <p style={{textAlign: 'center'}}>
                            Genom att skapa ett lag godkänner du<br/>
                            <u>Villkor för Spela med Poolarna</u>
                        </p>
                    </div>
                </div>
            </Wrapper>
        </>

    );
}

export default NewSyndicateTeam;