import { React, useState } from 'react';
import { Button, H2 } from '../../reusable-components';
import useAgentActions from '../useAgentActions';
import useForm from '../useForm';
import { Wrapper } from './styles';
import { useTranslation } from "react-i18next";
import ConfirmDialog from '../confirmdialog';

const NewSyndicateForm = ({ agent, shallowrounds }) => {

  const { createSyndicate } = useAgentActions(agent)
  const { t } = useTranslation();
  const [response, onResponse] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);

  const callback = () => {    
    inputs.stakePerShare = { currency: "SEK", amount: "1" };
    inputs.maxNShares = parseInt(inputs.targetNShares);
    inputs.targetNShares = parseInt(inputs.targetNShares);
    if (inputs.roundId) {
      createSyndicate(inputs).then(resp => {
        onResponse(resp);
        setShowConfirm(true);
      });
    }
  }
  const { inputs, handleInputChange, handleSubmit } = useForm({ name: "", roundId: "", stakePerShareAmount: "", targetNShares: '', maxNShares: '' }, callback);

  let activeRounds = shallowrounds.filter(({ openTo }) => new Date(openTo) > Date.now())
  const moment = require('moment');
  let locale = t('locale');

  return (
    <div>
      {(showConfirm && <ConfirmDialog text={response} toggle={setShowConfirm} />)}
      <Wrapper>
        <div className="green-div">
          <H2 className="title-margin">{t('create_new_syndicate')}</H2>
          <div className="">
            <div className="">
              <div className="">
                <form onSubmit={handleSubmit} autoComplete="off">
                  <div className="field">
                    <label className="">{t('name')}</label>
                    <div className="control">
                      <input className="input" type="text" name="name" onChange={handleInputChange} value={inputs.name} required />
                    </div>
                  </div>
                  <div className="field">
                    <label className="">{t('round')}</label>
                    <div className="control">
                      <select className="input" type="text" name="roundId" onChange={handleInputChange} required >
                        <option key={`rounddefault`} value='1'>{t('round')}</option>);
                      {activeRounds.map((round, i) => {
                          const openToFormated = moment(round.openTo).locale(locale).format("YYYY-MM-DD")
                          return (<option key={`${i} round`} value={round.id}>{round.name} {openToFormated}</option>);
                        })}
                      </select>

                    </div>
                  </div>                  
                  <div className="field">
                    <label className="">{t('target_number_of_shares')}</label>
                    <div className="control">
                      <input className="input" type="number" name="targetNShares" onChange={handleInputChange} value={inputs.targetNShares} />
                    </div>
                  </div>
                  <Button className="button-margin" type="submit">{t('create_new_syndicate')}</Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  )
}

export default NewSyndicateForm;