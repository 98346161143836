import React, { useState } from 'react';
import { ReducedSystemSelectorWrapper } from './styles';
import { ExpandMoreArrowIcon, ExpandLessArrowIcon, Button } from '../../../../../reusable-components';
import { useTranslation } from "react-i18next";

const ReducedSystemSelector = ({ reducedSystems, onChange, onReset }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedSystem, setSelectedSystem] = useState('');
  const { t } = useTranslation();

  return (
    <ReducedSystemSelectorWrapper>
      <div onClick={() => {
        setSelectedSystem('')
        onReset();
      }} className="no-system cursor">
        <span className="bold">{t('no_system')}</span>
      </div>
      <div className="grid-wrapper">

        <div className="flex">
          <span className="bold">R-system</span>
          <span onClick={() => setIsOpen(!isOpen)} className="cursor">{isOpen ? ExpandLessArrowIcon : ExpandMoreArrowIcon}</span>
        </div>
        {isOpen && (
          <div className="grid">
            {reducedSystems.map(({ name }, i) => <div onClick={() => setSelectedSystem(name)} className={`system cursor ${selectedSystem === name ? 'selected' : ''}`} key={`rs ${i}`}>{name}</div>)}
          </div>
        )}
      </div>
        {selectedSystem !== '' && <Button type="button" onClick={() => onChange(selectedSystem)}>{t('select')}</Button>}
    </ReducedSystemSelectorWrapper>
  );
}

export default ReducedSystemSelector;