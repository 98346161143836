import { Round } from "../rounds/types";

export function sortByName(name: string, whichTips: string): boolean {
  const lowerdName: string = name.toLowerCase();
  const lowerdNamewhichTips: string = whichTips.toLowerCase();

  if (lowerdName.includes('flextipset')) {
    return lowerdNamewhichTips === 'flextipset';
  }

  return lowerdName === lowerdNamewhichTips;
}

export function sortByDate(r1: Round, r2: Round): number {
  const date1 = new Date(r1.openTo);
  const date2 = new Date(r2.openTo);

  if (date1 > date2) {
    return -1;
  } else if (date1 < date2) {
    return 1;
  }

  return 0;
}
