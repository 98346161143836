import React from 'react';
import { Wrapper, StandingsTableRowContent, StandingsTableRowHeader } from './styles';
import { useTranslation } from "react-i18next";
import { H3 } from '../../../../../reusable-components';




const StandingsTableRow = ({ standing, even, inFixture }) => {
    return (
        <StandingsTableRowContent even={even} inFixture={inFixture}>
            <div className={'col-spacing'} />

            <div className={'pos-indicator-wrapper'}>
                {standing.position}
            </div>

            <div className={'col-spacing'} />
            <div className={'team-name-wrapper'}>
            <div className={'team-name-col'}>
                    {standing.team_name}
                </div>
            </div>

            <div style={
                {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                }
            }>
                <div className={'col-spacing'} />
                <div className={'key-figure'}>
                    {standing.overall.games_played}
                </div>

                <div className={'col-spacing'} />
                <div className={'key-figure'}>
                    {standing.overall.games_won}
                </div>

                <div className={'col-spacing'} />
                <div className={'key-figure'}>
                    {standing.overall.games_draw}
                </div>

                <div className={'col-spacing'} />
                <div className={'key-figure'}>
                    {standing.overall.games_lost}
                </div>

                <div className={'col-spacing'} />
                <div className={'key-figure'}>
                    {standing.goal_difference}
                </div>

                <div className={'col-spacing'} />
                <div className={'key-figure'}>
                    {standing.points}
                </div>
            </div>

        </StandingsTableRowContent>
    );
}

const MatchTable = ({ fixtureDetails }) => {
    const { t } = useTranslation();
    const { standings } = fixtureDetails;

    if (!standings) {
        return <Wrapper />
    }


    return (
        <Wrapper>
            <div className="subheader">
                <H3>{fixtureDetails.league.name}</H3>
            </div>
            {renderHeader(t)}
            {renderRows(standings, fixtureDetails.fixture)}
        </Wrapper>
    );
}

const renderHeader = (t) => {
    return (
        <StandingsTableRowHeader>


            <div className={'col-spacing'} />

            <div className={'pos-col'}>

            </div>
            <div className={'col-spacing'} />

            <div className={'team-name-col'}>
                <div className={'text'}>{t('team_name')}</div>
            </div>
            
            <div style={
                {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                }
            }>
                <div className={'col-spacing'} />
                <div className={'key-figure-col'}>{t('table_abbreviation_num_games')}</div>

                <div className={'col-spacing'} />
                <div className={'key-figure-col'}>{t('table_abbreviation_wins')}</div>

                <div className={'col-spacing'} />
                <div className={'key-figure-col'}>{t('table_abbreviation_draws')}</div>

                <div className={'col-spacing'} />
                <div className={'key-figure-col'}>{t('table_abbreviation_losses')}</div>

                <div className={'col-spacing'} />
                <div className={'key-figure-col'}>+/-</div>


                <div className={'col-spacing'} />
                <div className={'key-figure-col'}>{t('table_abbreviation_points')}</div>
            </div>
        </StandingsTableRowHeader>
    );
}

const renderRows = (standings, fixture) => {
    const result = [];
    for (let i = 0; i < standings.length; i++) {
        const even = (i % 2 === 0);
        const inFixture = standings[i].team_name === fixture.homeTeam.name ||
            standings[i].team_name === fixture.awayTeam.name;

        result[i] = <StandingsTableRow even={even} standing={standings[i]} inFixture={inFixture} />
    }

    return result;
}

export default MatchTable;