import React, {useState} from 'react';
import { H2, BackgroundImage, Button } from '../../../reusable-components';
import NavBar from "../../../navbar";
import {Wrapper} from "../../styles";
import image from "../../../images/footballfield.jpg";
import {useHistory} from "react-router-dom";
import {useSetNicknameOfPlayer} from "../../../syndicateteam/useSyndicateTeams";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {usePlayer} from "../../../player/playerContext";

const SelectNickname = () => {
    let history = useHistory();
    let postNickname = useSetNicknameOfPlayer();
    let snackbar = useSnackbar();
    const [nickname, setNickname] = useState("");
    const [player, config] = usePlayer();
    const redirect = (to) => {
        history.push(to);
    }

    const setNicknameAndProceed = () => {
        postNickname(nickname).then(() => {
            redirect('newSyndicateTeam')
        }, (rejection) => {
            snackbar.enqueueSnackbar(rejection);
        }).catch((err) => snackbar.enqueueSnackbar(err))
    }

    if (!config.syndicateBetting) {
        return <div/>;
    }

    return (
        <>
            <NavBar whichToUnderline="1" />
            <Wrapper>
                <BackgroundImage height={250} image={image}>
                    <H2 color="#fff">
                        YTA FÖR NYHET/BILD
                    </H2>
                </BackgroundImage>
                <div className="green-div" style={{ minHeight: '300px' }}>
                    <H2 className="title-margin">
                        VÄLJ ETT SMEKNAMN
                    </H2>
                    <p style={{textAlign: 'center'}}>För att vara med i ett lag så behöver du ett smeknamn</p>
                    <div>
                        <form>
                            <div style={{ display: 'flex', margin: '18px 0 36px 0', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', minHeight: '150px', minWidth: '100%'}}>

                                <input onChange={(e) => setNickname(e.target.value)} type="text" style={{ border: "1px solid var(--off-black)", textAlign: "center", height: "24px", width: "100%", fontSize: "14px" }} placeholder="Vad vill du bli kallad?"/>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', maxWidth: '70%', minHeight: '100px' }}>
                                    <Button type="button" onClick={() => setNicknameAndProceed()}>Fortsätt</Button>
                                </div>
                            </div>
                        </form>
                        <p style={{textAlign: 'center'}}>
                            Du kan även ändra ditt smeknamn i <u>länk</u>
                        </p>
                    </div>
                </div>
            </Wrapper>
        </>

    );
}

export default SelectNickname;