import {usePlayer} from "../player/playerContext";
import {QueryResult, useQuery, useQueryCache} from "react-query";
import fetchApi from "../backend/fetchApi";
import * as types from './types';
import {Round} from "./types";
const useRounds = () => {
  const [player] = usePlayer();
  const queryCache = useQueryCache();
  const roundsUrl = player && player._links.rounds.href;
  const rounds = useQuery<types.Round[], unknown>(['rounds', roundsUrl], () => {
    return fetchApi(roundsUrl).then(response => response.json()).then(r => r._embedded.rounds)
  }, {
    enabled: !!player,
    initialData: player && player._embedded.rounds,
    initialStale: false,
  })

  return [rounds.data, {
    ...rounds,
    reloadRounds: () => queryCache.invalidateQueries('rounds')
  }] as [Round[] | undefined, QueryResult<Round[]> & {
    reloadRounds: () => Promise<any>
  }]
}

export default useRounds;