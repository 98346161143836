import styled from 'styled-components';
import React from "react";

export const ModalWrapper = styled.div`
  position: absolute;
  z-index: 1000;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  margin-top: 112px;
  background: var(--off-white);

  #go-back-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 0 0 16px;
    font-weight: 600;
    cursor: pointer;
  }

  #go-back-wrapper {
    background: var(--green-tint-background);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    height: 35px;
    padding: 0 16px;
  }

  #go-back-text {
    padding: 0 8px;

  }
`;