import { useQueryCache } from "react-query";
import fetchApi from "../backend/fetchApi";
import { useTranslation } from "react-i18next";

const useAgentActions = (agent) => {
  const queryCache = useQueryCache();
  const { t } = useTranslation();

  return {
    createSyndicate: syndicate => {
      const syndicatesUrl = agent && agent._links.syndicates.href;

      if (!syndicatesUrl) return;

      return fetchApi(syndicatesUrl, {
        method: 'POST',
        body: JSON.stringify(syndicate)
      }).then(response => {
        if (response.ok) {
          queryCache.invalidateQueries('syndicates');
          return t('syndicate_created');
        } else {
          return response.statusText;
        }
      })
    },
  }
}

export default useAgentActions;