import styled from 'styled-components';

export const WinInfoWrapper = styled.div`

  .black-bar {
    background: var(--off-black);
    color: var(--text-white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 38px 0 10px;
    height: 35px;
  }

  .black-bar span {
    font-size: 11px;
    font-weight: bold;
  }

  .result-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 14px 0 10px;
    height: 30px;
    font-size: 11px;
    background: var(--off-white);
  }

  .bg-green {
    background: var(--green-tint-background);
  }
`;