import styled from 'styled-components';

export const MyCouponSharesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 120px;
  background: var(--off-white);
  overflow-y: auto;

  @media screen and (min-width: 620px) {
    .active-content, .settled-content {
      width: 595px;
      margin-top: 100px;
    }
  }

  @media screen and (max-width: 619px) {
    .active-content {
      width: 90%;
      margin-top: 50px;
    }

    .settled-content {
      width: 100%;
      margin-top: 50px;
    }
  }

  .orange {
    background: var(--pinkish-white);
  }

  .green {
    background: var(--green-tint-background);
  }

  .bold {
    font-weight: bold;
  }

  @media screen and (max-width: 349px) {
    font-size: 12px;
  }


  @media screen and (min-width: 350px) {
    font-size: 15px;
  }

  .coupon-wrapper {
    margin-top: 18px;
  }

  .coupon {
    height: 50px;
    padding: 10px 20px 10px 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .link {
    text-decoration: none;
  }

  .time-div {
    display: flex;
    flex-direction: column;
  }

  .date-span {
    margin-bottom: 4px;
  }

  .coupon span {
    color: var(--text-black);
  }

  .flex {
    display: flex;
    align-items: center;
    padding-left: 13px;
  }

  .center-progress {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
`;