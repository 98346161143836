import React from "react";
import { SignBoxWrapper } from '../../reusable-components';

const SignBox = ({ sign, value, onChange }) => {
  return (
    <SignBoxWrapper selected={value} onClick={e => {
      e.stopPropagation();
      onChange(!value);
    }}>
      {sign}
    </SignBoxWrapper>
  );
}

export default SignBox;
