import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectedRound from "../routedround/components/selectedfixture/selectedfixture";
import {BackIcon} from "../reusable-components";
import { ModalWrapper } from "./styles";
import { operatorAdviceScrollToTop } from "../App";
import useDetailedFixture from "../fixtures/useDetailedFixture";
import Progress from "../common/progress";
import {CircularProgress} from "@material-ui/core";

const FixtureDetailsMobile = ({fixtureId, closeF, roundFixture}) => {
  const { t } = useTranslation();
    operatorAdviceScrollToTop();
    const { fixtureDetails } = useDetailedFixture(fixtureId);

    const fixtureLoading = () => {
        if (fixtureDetails) {
            return <></>
        }
        return <CircularProgress color={'black'} size={'14px'} />
    }
    return (
        <ModalWrapper>
            <div id={'go-back-wrapper'}>
                <div id={'go-back-button'} onClick={() => closeF()}>
                    <div>{BackIcon}</div>
                    <div id={'go-back-text'}>{t('go_back')}</div>
                    {fixtureLoading()}
                </div>
            </div>

            <SelectedRound fixtureDetails={fixtureDetails} isAfterGameStart={false} roundFixture={roundFixture}/>
        </ModalWrapper>
  );
}


export default FixtureDetailsMobile;