import React from 'react';
import NavBar from '../navbar';
import image from '../images/footballfield.jpg';
import { PlayAgentWrapper } from './styles';
import { BackgroundImage, H2} from '../reusable-components';
import { useTranslation } from "react-i18next";
import AvailableSyndicates from './AvailableSyndicates';

export default function PlayAgent() {
  const { t } = useTranslation();

  return (
    <>
      <NavBar />
      <PlayAgentWrapper>
        <BackgroundImage height={250} image={image}>
          <H2 color="#fff">
            {t('bet_with_syndicates')}
          </H2>
        </BackgroundImage>
        <div className="green-div">          
          <AvailableSyndicates />          
        </div>
      </PlayAgentWrapper>
    </>
  );
}