import { useQuery, useQueryCache } from "react-query";
import fetchApi from "../backend/fetchApi";

const useCouponShare = (shallowCouponShare) => {
    
    const queryCache = useQueryCache();
  
    const { _links } = shallowCouponShare || {};
    const { self } = _links || {};
    const { href: couponShareUrl } = self || {};
   
    const {status,error,data} = useQuery(['couponShare', couponShareUrl], () => {        
        if(!shallowCouponShare)
        {
            return null;
        }
        return fetchApi(couponShareUrl).then(response => response.json())
    }, {
        
    })
    
    const reloadCouponShare = () => queryCache.invalidateQueries(['couponShare', couponShareUrl]);

    const couponShare=data;
    return {status,error,couponShare, reloadCouponShare};
}

export default useCouponShare;
