import React from 'react';
import { GreenDiv } from './landing_styles';
import { H2, PoolxLogoBlack, BackgroundImage, H3, Button, RobotIcon} from '../reusable-components';
import image from '../images/stadium.jpg';
import NavBar from '../navbar';
import { useTranslation } from 'react-i18next';
import useShallowRounds from '../rounds/useShallowRounds';
import { Link } from 'react-router-dom';
import 'moment/locale/sv';
import 'moment/locale/en-gb';
import 'moment/locale/fa';
import MediaQuery from "react-responsive/src";
import {formatStringCurrency} from "../common/CurrencyFormatter";
import {getDateString, getRoundName, getRoundPlate} from "../common/RoundHelper";
import {getProPickText} from '../routedround/components/couponform/components/propick';
import useDetailRound from '../rounds/useDetailRound';
import {BelowMenuContentWrapper} from "../common/BelowMenuContentWrapper";
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import Progress from "../common/progress";

function getRobotPlate(round, t) {

  const roundName = getRoundName(round,t)

  switch (round.name) {
    
    case "Maxtipset":
      return (
        <div className='color-plate' style={{background:'#B8CCF1'}}>                    
          <H3>{roundName}</H3>
          <div className='icon-holder'>
            <RobotIcon height="40"/>            
        </div>
        </div>
      );      
    case "Mixtipset":
    return (
      <div className='color-plate' style={{background:'#FAEEAE'}}>        
        <H3>{roundName}</H3>
        <div className='icon-holder'>
          <RobotIcon height="40"/>            
        </div>
      </div>
    );             
    case "Flextipset6":
    case "Flextipset8":
      return (
        <div className='color-plate' style={{background:'#E0D8EE'}}>          
          <H3>{roundName}</H3>
          <div className='icon-holder'>
            <RobotIcon height="40"/>            
          </div>
        </div>
      );
    default:
      return (
        <div className='color-plate' style={{background:'#FAEEAE'}}>          
          <div className='icon-holder'>
            <RobotIcon height="60"/>            
          </div>
        </div>
      );
  }
}

function RoundBox({ round, t }) {
  const link = `/rounds/${round.id}`;

  const detailRoundResponse = useDetailRound(round.id);

  const turnoverAmount = round.operatorTurnover ? round.operatorTurnover.amount : 'NA';  
  const turnoverCurrency = round.operatorTurnover ? round.operatorTurnover.currency : 'NA';
  const dateString = getDateString(round, t);

  const jackpotkey = 'jackpot'.concat(round.operatorTurnover.currency);

  const jackpotEntry = detailRoundResponse.round ? detailRoundResponse.round.promotionalValues.filter((item) => item.key===jackpotkey):[];

  let jackpot = null;
  if(jackpotEntry.length===1){
    jackpot = jackpotEntry[0].value;
  }

  return (
    <div className='round-box'>
      {getRoundPlate(round,t)}
      <div className='round-box-white'>
        <div className='round-box-details'>
          {jackpot && 
            <div className='round-box-detail'>
              <span className='detail-title'>{t('jackpot')}</span>
              <H2>{formatStringCurrency(jackpot, turnoverCurrency)}</H2>
            </div>
          }
          <div className='round-box-detail'>
            <span className='detail-title'>{t('turnover')}</span>
            <H2>{formatStringCurrency(turnoverAmount, turnoverCurrency)}</H2>
          </div>
          <div className='round-box-detail'>
            <span className='detail-title'>{t('starts')}</span>
            <H2>{dateString}</H2>
          </div>
        </div>
        <Link to={link} >
          <Button>{t('show_round')}</Button>        
        </Link>
      </div>
    </div>
  );
}

function getProPickSuggestions(round){

  switch (round.name) {
    case "Maxtipset":      
      return [
        {rows:72,dif:'Easy',numFull:2,numHalf:3},
        {rows:108,dif:'Easy',numFull:3,numHalf:2},
        {rows:192,dif:'Easy',numFull:1,numHalf:6},
      ];  
      case "Mixtipset":      
      return [
        {rows:72,dif:'Easy',numFull:2,numHalf:3},
        {rows:108,dif:'Easy',numFull:3,numHalf:2},
        {rows:192,dif:'Easy',numFull:1,numHalf:6},
      ];  
    default:
      return [
        {rows:36,dif:'Easy',numFull:2,numHalf:2},
        {rows:72,dif:'Easy',numFull:2,numHalf:3},
      ];
  }

}

function ProPickBox({ sugg, round, t }) {
  const dateString = getDateString(round, t);
  const detailResponse = useDetailRound(round.id);

  const link = `/rounds/${round.id}?rows=${sugg.rows}&dif=${sugg.dif}&numFull=${sugg.numFull}&numHalf=${sugg.numHalf}`;

  return (    
    <div className='pro-pick-box'>
      {getRobotPlate(round, t)}
      <div className='pro-pick-box-white'>
        <div className='round-box-details'>
          <div className='round-box-detail'>            
          <span className='detail-title'>{dateString}</span>
            <H2>{detailResponse.round && getProPickText(sugg.rows, detailResponse.round.operatorStakePerRow)}</H2>
          </div>          
        </div>
        <div className='propick-box-detail'>
        <Link to={link}>
          <Button>{t('bet')}</Button>
          </Link>
        </div>
        <span className='detail-title'>{sugg.rows} {t('rows')}</span>
      </div>
    </div>    
  );
}

const getHomeUrl = () => {
  const key = 'homeUrl';
  const homeUrl = sessionStorage.getItem(key);
  if (homeUrl) {
    return homeUrl;
  }

  // If home url not found in session storage, attempt to get it using URL param, these values should be set for
  // Pariplay when initializing the auth context from the received launch URL
  let searchParams = new URLSearchParams();
  return searchParams.get(key);
}

const LandingPage = () => {

  const { t } = useTranslation();

  const [rounds, de] = useShallowRounds();

  const urlParams = new URLSearchParams(window.location.search);
  let twitterTheme = urlParams.get('theme') === 'dark' ? 'dark' : 'light';
  const twitterHandle = "PoolXBet";

  const activeRounds = rounds && rounds.filter(round => round.status === 'ACTIVE' && new Date(round.openTo) > new Date(Date.now()))
      .sort((a, b) => new Date(a.openTo) - new Date(b.openTo));

  let firstMax = [];
  let firstMix = [];
  let firstFlex = [];
  if (activeRounds) {
    firstMax = activeRounds.filter(round => round.name === 'Maxtipset');
    firstMix = activeRounds.filter(round => round.name === 'Mixtipset');
    firstFlex = activeRounds.filter(round => round.name.indexOf("Flex") > -1);
  }

  let topRounds = []

  if (firstMax.length > 0) {
    topRounds.push(firstMax[0]);
  }

  if (firstMix.length > 0) {
    topRounds.push(firstMix[0]);
  }

  if (firstFlex.length > 0) {
    topRounds.push(firstFlex[0]);
  }

  let topRound = undefined;
  let link = undefined;

  if (topRounds.length > 0) {
    let sortedTopRounds = Array.from(topRounds);
    sortedTopRounds = sortedTopRounds.sort((a, b) => {
      return new Date(a.openTo) - new Date(b.openTo);
    });
    topRound = sortedTopRounds[0];
    link = `/rounds/${topRound.id}`;
  }

  const homeUrl = getHomeUrl();
  const renderTopRounds = (topRounds) => {

    if (de.isLoading || de.isIdle) {
      return (
          <>
            <Progress />
          </>
      );
    }

    return topRounds.map((round, i) => {
        if (!round) {
          return <div />;
        }
        return (
            <RoundBox key={`roundBox ${i}`} round={round} t={t} />
        );
    })
  }

  return (
    <>
      <NavBar />
      <BelowMenuContentWrapper>
        <GreenDiv>
          <div className="logo-image">
            <MediaQuery minWidth={427}>
              <center>{PoolxLogoBlack(100)}</center>
            </MediaQuery>
            <MediaQuery maxWidth={426}>
              <center>{PoolxLogoBlack(50)}</center>
            </MediaQuery>
          </div>

          <BackgroundImage image={image}>

          <MediaQuery minWidth={1000}>
                {twitterHandle.length > 0 ?
                  <div className="twitter-box-left">                
                    </div>
                  : <div></div>

                }
            </MediaQuery>

            {topRound && (<div className="image-text">
              <H2 color="#fff">
                {t('play_on')} {topRound && getRoundName(topRound, t)}
              </H2>

              <H3 color="#fff" className="starts">
                {topRound && getDateString(topRound, t)}
              </H3>

              <Link to={link}>
                <Button>{t('show')}</Button>
              </Link>
            </div>)
            }

          <MediaQuery minWidth={1000}>
            {twitterHandle.length > 0 ?
              <div className="twitter-box-right">
                
                <TwitterTimelineEmbed
                  key={twitterHandle}
                  sourceType="profile"
                  screenName={twitterHandle}
                  theme={twitterTheme}
                  noHeader
                  noFooter
                  options={{ height: 300 }}
                /></div>
              : <div></div>

            }
            </MediaQuery>
          </BackgroundImage>

          <div className="landing-header">
            <H2>{t('current_rounds')}</H2>
          </div>

          <div className="square-grid">
            {renderTopRounds(topRounds)}
          </div>          

          <div className="landing-header">
            <H2>{t('pro_pick_bets')}</H2>
          </div>


          <div className="square-grid">
            {topRounds.map((round, i) => {
              if (!round) {
                return <div></div>;
              }
              const suggestions = getProPickSuggestions(round);
              return suggestions.map((sugg,j)=>{
                return (
                    <ProPickBox key={`proPickBox ${i}${j}`} round={round} t={t} sugg={sugg}/>
                );
              })
            })
            }
          </div>

          <MediaQuery maxWidth={999}>
          {twitterHandle.length > 0 ?
              <div className="twitter-box-mobile">
                
                <TwitterTimelineEmbed
                  key={twitterHandle}
                  sourceType="profile"
                  screenName={twitterHandle}
                  noHeader
                  noFooter
                  options={{ height: 300 }}
                /></div>
              : <div></div>

            }

          </MediaQuery>

          {homeUrl && homeUrl !== '' ?
              <a href={homeUrl} style={{margin: '16px 0'}}>
                <Button type="button">Go to Lobby</Button>
              </a>
              :
              <div />
          }

        </GreenDiv>


      </BelowMenuContentWrapper>


    </>
  );
};

export default LandingPage;
