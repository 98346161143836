import React from 'react';
import { SyndicateCouponWrapper } from './styles';
import { SignBoxWrapper } from '../../reusable-components';
import { useTranslation } from "react-i18next";



const SyndicateCoupon = ({
  syndicate,
  round
}) => {
  
  const { t } = useTranslation();

  return (

    <SyndicateCouponWrapper>
      <div className="grid-div">
        <div className="match-div">
          <div className="text-div">
            <span className="match-margin">#</span>
            <span className="match-title">Match</span>
          </div>
          {round.fixtures.map((fixture, i) => {

            return (
              <div className={`match-iteration-div ${(((i+1) % 2) > 0) ? 'green-bg' : ''}`} key={`teamName ${i}`}>
                <span className="match-margin">{i + 1}</span>
                <span>{fixture.homeTeam.name} - {fixture.awayTeam.name}</span>
              </div>
            );
          })}
        </div>

        <div className="systems-div">
          <div className="text-div">
            <span className="result-margin">System</span>
          </div>
          {syndicate.pendingRoundCoupon.bets[0].legs.map((arr, i) => {
            let isSecond = ((i) % 2) === 0;
            return (
              <div className={`match-iteration-div system-width ${isSecond ? 'green-bg' : ''}`} key={`sign ${i}`}>
                <SignBoxWrapper selected={arr.signs.indexOf('ONE') > -1}>1</SignBoxWrapper>
                <SignBoxWrapper selected={arr.signs.indexOf('X') > -1}>X</SignBoxWrapper>
                <SignBoxWrapper selected={arr.signs.indexOf('TWO') > -1}>2</SignBoxWrapper>
              </div>
            );
          })}
        </div>
      </div>

    </SyndicateCouponWrapper>

  );
}


export default SyndicateCoupon;