import React, { useState } from 'react';
import { Wrapper } from './styles';
import { Close } from '@material-ui/icons';
import { useTranslation } from "react-i18next";
import { IkonIcon, Button } from '../../reusable-components';

function renderSwitch({  
  shouldRenderConfirm,  
  toggle,
  syndicate,
  phrases,
  handleClick
}) {

  const onConfirmClick = (syndicate)=>{
    syndicate.purchase();
    handleClick();
  }

  if (shouldRenderConfirm) {
    return (
      <>
        <span>{phrases.paymentDone}</span>
        <Button width={350} onClick={() => toggle(false)}>Ok</Button>
      </>
    );
  }

  return (
    <>
      <span>{phrases.confirmPurchase} {syndicate.stakePerShare.amount} {syndicate.stakePerShare.currency}?</span>
      <Button type="submit" width={350} onClick={()=>onConfirmClick(syndicate)}>{phrases.pay}</Button>
    </>
  );
}

const SyndicateConfirm = ({
  syndicate,
  toggle
  
}) => {
  const [shouldRenderConfirm, setShouldRenderConfirm] = useState(false);
  const { t } = useTranslation();
  const phrases = {
    confirmPurchase: t('do_you_want_to_place_bet'),
    pay: t('pay'),
    paymentDone: t('your_bet_is_paid'),
    insufficientFunds: t('insufficient_funds'),
  };

  const handleClose = () => {    
    toggle(false);
  }

  const handleClick = () => {    
    setShouldRenderConfirm(true);
  }

  return (
    <Wrapper>
      <div className="onclick-div" onClick={handleClose} />
      <div className="confirm-window">
        <div className="flex-end">
          <Close className="close-icon" onClick={handleClose} />
        </div>
        {IkonIcon(32, 30)}
        {renderSwitch({          
          shouldRenderConfirm,          
          toggle,
          syndicate,
          phrases,
          handleClick
        })}
      </div>
    </Wrapper>
  );
}

export default SyndicateConfirm;