import React from 'react';
import { Link } from 'react-router-dom';
import { BlackBar } from './styles';
import { PoolxLogoWhite, PoolxLogoNavbar, TicketIconWhite, RowsIconWhite, HouseIconWhite, InfoIconWhite } from '../reusable-components';
import { useTranslation } from 'react-i18next';
import useAgents from '../agents/useAgents';
import MediaQuery from "react-responsive";
import {usePlayer} from "../player/playerContext";
import PlayerBalance from '../playerbalance/PlayerBalance';
const CLASS_NAME = 'link';
const CLASS_NAME_UNDERLINE = `${CLASS_NAME} underline`;

const NavBar = ({ whichToUnderline }) => {
  const { t } = useTranslation();
  const [agents] = useAgents();
  const [player, config] = usePlayer();
  const syndicateBetting = config && config.syndicateBetting;
  const agentSyndicateBetting = config && config.agentSyndicateBetting;

  return (
    <BlackBar>
      <MediaQuery maxWidth={1000}>
        <div id={'mobile-menu'}>
          <div id={'content'}>
            <Link className={'mobile-menu-item'} to="/">
              <div className={'mobile-menu-icon'} id={'icon-home'} >
                {HouseIconWhite}
              </div>
              <div id={'icon-poolx'}>
                {PoolxLogoWhite}
              </div>
            </Link>

            <Link className={'mobile-menu-item'} to="/roundhistory">
              <div className={'mobile-menu-icon'} id={'icon-results'}>
                {RowsIconWhite}
              </div>
              <div>{t('rounds')}</div>
            </Link>

            <Link className={'mobile-menu-item'} to="/mycoupons">
              <div className={'mobile-menu-icon'} id={'icon-coupon'}>
                {TicketIconWhite}
              </div>
              <div>{t('my_bets')}</div>
            </Link>

            <Link className={'mobile-menu-item'} to="/help">
              <div className={'mobile-menu-icon'} id={'icon-help'}>
                {InfoIconWhite}
              </div>
              <div>{t('help')}</div>
            </Link>
          </div>
          <PlayerBalance/>
        </div>
      </MediaQuery>

      <MediaQuery minWidth={1001} >
        <Link to="/" id={'desktop-poolx-logo'}>
          {PoolxLogoNavbar}
        </Link>
        <nav>
          <ol></ol>
          <ol></ol>
          <ol></ol>
          <ol></ol>
          <ol className="menu-options">
            <li>
              <Link id="round-history" className={whichToUnderline === '4' ? CLASS_NAME_UNDERLINE : CLASS_NAME} to="/roundhistory">{t('rounds')}</Link>
            </li>
            <li>
              <Link id="my-coupons" className={whichToUnderline === '3' ? CLASS_NAME_UNDERLINE : CLASS_NAME} to="/mycoupons">{t('my_coupons')}</Link>
            </li>
            {syndicateBetting ?? (
                <li>
                  <Link id="bet-with-friends" className={whichToUnderline === '1' ? CLASS_NAME_UNDERLINE : CLASS_NAME} to="/teams">{t('bet_with_friends')}</Link>
                </li>
            )}
            {agentSyndicateBetting ?? (
                <li>
                  <Link id="bet-with-syndicates" className={whichToUnderline === '2' ? CLASS_NAME_UNDERLINE : CLASS_NAME} to="/playagent">{t('bet_with_syndicates')}</Link>
                </li>
            )}

            {(agents && agents.length>0 && <li>
              <Link id="agent-admin" className={whichToUnderline === '5' ? CLASS_NAME_UNDERLINE : CLASS_NAME} to="/agentadmin">{t('agent_admin')}</Link>
            </li>)}
          </ol>          
        </nav>        

        <navhelp>
          <PlayerBalance />
          <ol className="menu-options">
            <li>
              <Link id="help" className={whichToUnderline === '6' ? CLASS_NAME_UNDERLINE : CLASS_NAME} to="/help">{t('help')}</Link>
            </li>
          </ol>
        </navhelp>
      </MediaQuery>


    </BlackBar >
  );
}


export default NavBar;